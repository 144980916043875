import Cookies from 'js-cookie';
import {
  POST_LOGIN_REQUEST,
  POST_LOGIN_SUCCESS,
  POST_LOGIN_ERROR,
  POST_LOGIN_LOGOUT,
} from '../types/auth';

const initialState = {
  err: {},
  isFetching: false,
  data: [],
};

const authReducer = (state = initialState, action = {}) => {
  const { type = '', payload = {} } = action;
  switch (type) {
    case POST_LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        err: null,
      };
    case POST_LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: payload,
        err: null,
      };
    case POST_LOGIN_ERROR:
      const { errors = '' } = payload;
      return {
        ...state,
        isFetching: false,
        err: errors,
      };
    case POST_LOGIN_LOGOUT:
      Cookies.remove('token')
      return {
        ...state,
        data: [],
      };
    default: {
      return state;
    }
  }
};

export default authReducer;
