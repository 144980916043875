import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/";

const Breadcrumbs = ({ crumbs }) => {
  return (
    <div className="breadcrumbs">
      {crumbs.map((x, k) => {
        let crumb;
        x.type === "link" &&
          (crumb = (
            <NavLink className="breadcrumbs__link" to={x.path}>
              {x.label}
            </NavLink>
          ));
        x.type === "separator" &&
          (crumb = (
            <span className="breadcrumbs__separator">
              <FontAwesomeIcon icon={faChevronRight} size="xs" />
            </span>
          ));
        x.type === "current" &&
          (crumb = <span className="breadcrumbs__current">{x.label}</span>);
        return <div key={k} className="breadcrumbs__item">{crumb}</div>;
      })}
    </div>
  );
};

export default Breadcrumbs;
