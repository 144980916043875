import React from 'react';

const importAll = (r) => r.keys().map(r);
const files = importAll(
    require.context('../../assets/img/socios', false, /.*$/)
);

export const Partners = () => (
    <section className='socios-estrategicos'>
        <div className='socios-estrategicos__container container'>
            <h2 className='color--secundario section__title h1 text-upper text-wide'>
                Socios estratégicos
            </h2>
            {/* <p className="color--secundario section__subtitle">
        Conoce a nuestros socios
      </p> */}
            <div className='socios-estrategicos__grid'>
                {files.map((x, i) => (
                    <div key={i} className='socios-estrategicos__item'>
                        <img src={x.default} alt={`Socio ${i + 1}`} />
                    </div>
                ))}
            </div>
        </div>
    </section>
);

export default Partners;
