import { doAuthentication } from '../../utils/api-client';
const queryString = require('query-string');

export const GetSale = (payload = {}) => {
  const { query = {} } = payload;
  const filters = queryString.stringify(query);
  return doAuthentication({
    method: 'GET',
    url: `/sale?${filters}`,
  });
};
