import { doAuthentication } from '../../utils/api-client';

export const Login = (payload = {}) => {
  return doAuthentication({
    method: 'POST',
    url: `/login`,
    data: payload,
  });
};

export const Register = (payload = {}) => {
  return doAuthentication({
    method: 'POST',
    url: `/register`,
    data: payload,
  });
};

export const ConfirmEmail = (payload = {}) => {
  return doAuthentication({
    method: 'POST',
    url: `/active/email`,
    data: payload,
  });
};

export const RememberPassword = (payload = {}) => {
  return doAuthentication({
    method: 'POST',
    url: `/remember-password`,
    data: payload,
  });
};

export const ChangePassword = (payload = {}) => {
  return doAuthentication({
    method: 'POST',
    url: `/change-password`,
    data: payload,
  });
};

export const Profile = (payload = {}) => {
  return doAuthentication({
    method: 'GET',
    url: `/profile`,
    data: payload,
  });
};

export const UpdateProfile = (payload = {}) => {
  return doAuthentication({
    method: 'PUT',
    url: `/profile`,
    data: payload,
  });
};

export const ValidateUserExist = (payload = {}) => {
  return doAuthentication({
    method: 'POST',
    url: `/validate/user`,
    data: payload,
  });
};
