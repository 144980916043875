import React from "react";
import PopUp from "../../components/Popup";

const Privacy = ({ open, setOpen }) => (
  <PopUp
    open={open}
    onOpen={() => setOpen(true)}
    onClose={() => setOpen(false)}
    content={(close) => (
      <div className="popup__content slideUp">
        <div className="content">
          <h3>
            POL&Iacute;TICAS DE PRIVACIDAD Y PROTECCI&Oacute;N DE DATOS CLUB
            MUNDO EXPERTO EASY
          </h3>
          <p>
            <strong>DECLARACI&Oacute;N ESPECIAL.</strong>
          </p>
          <p>
            Easy Retail S.A., RUT 76.568.660-1 (en adelante &ldquo;Club Mundo
            Experto Easy&rdquo; o &ldquo;Mundo Experto&rdquo; o
            &ldquo;Easy&rdquo;), domiciliada para estos efectos en Av. Kennedy
            9001, piso 4&deg;, Comuna de Las Condes, Regi&oacute;n
            Metropolitana, administradora del Programa Club Mundo Experto Easy,
            en adelante el &ldquo;Programa&rdquo;, ha elaborado esta
            Pol&iacute;tica de Privacidad y Uso de Informaci&oacute;n Personal
            con el prop&oacute;sito que los Socios de Mundo Experto, en adelante
            el &ldquo;Socio&rdquo;, tengan conocimiento sobre la
            informaci&oacute;n personal que Club Mundo Experto Easy re&uacute;ne
            respecto de los mismos, as&iacute; como la manera que se utiliza y
            el prop&oacute;sito de su tratamiento. Por lo que el socio acepta
            expresamente la presente pol&iacute;tica.
          </p>
          <p>
            Se deja constancia que Club Mundo Experto Easy se reserva el derecho
            de modificar esta pol&iacute;tica en cualquier momento, lo que se
            obliga a informar a los Socios con, al menos, 10 d&iacute;as de
            anticipaci&oacute;n a la fecha considerada para el inicio de la
            vigencia de la modificaci&oacute;n, por aquellos canales que
            garanticen la debida y oportuna informaci&oacute;n. Un texto
            actualizado de la presente pol&iacute;tica se mantendr&aacute;́
            publicada en el sitio web de Club Mundo Experto Easy
            (www.mundoexperto.cl).
          </p>
          <p>
            La presente Pol&iacute;tica se entiende formar parte integrante de
            los T&eacute;rminos y Condiciones de Mundo Experto para todos los
            efectos a que haya lugar. Los t&eacute;rminos que no se encuentren
            expresamente definidos en el presente documento, tendr&aacute;n el
            significado que a ellos se asigna en los T&eacute;rminos y
            Condiciones de Club Mundo Experto Easy.
          </p>
          <p>
            <strong>I.- INTRODUCCI&Oacute;N</strong>
          </p>
          <p>
            Cuando ingreses, sin estar registrado a la p&aacute;gina web
            www.mundoexperto.cl, queremos que tengas una experiencia
            &uacute;nica que cumpla con la calidad y satisfacci&oacute;n que
            est&aacute;s buscando. Cada vez que nos visites tendremos la
            oportunidad de conocer tus necesidades y preferencias de
            navegaci&oacute;n. Esa informaci&oacute;n estad&iacute;stica es de
            gran relevancia para nosotros porque nos ayudar&aacute; a mejorar
            constantemente nuestro servicio para entregarte una grata
            experiencia de navegaci&oacute;n.
          </p>
          <p>
            Esta informaci&oacute;n no nos permite identificar a un cliente
            determinado, s&oacute;lo tiene relaci&oacute;n con datos sobre un
            determinado producto, grupo, p&aacute;gina o categor&iacute;a de
            productos y/o servicios.
          </p>
          <p>
            <strong>
              II.- DE LA INFORMACIÓN DE LOS SOCIOS DEL PROGRAMA Y SU USO O
              DISPOSICIÓN.
            </strong>
          </p>
          <p>
            Club Mundo Experto Easy declara al Socio, que el Programa permite
            otorgar a los Socios diversos beneficios, sobre la base de la
            informaci&oacute;n que estos &uacute;ltimos proporcionan en el
            Programa. Por lo tanto, es en base al conocimiento de la
            informaci&oacute;n personal de los Socios, que Mundo Experto puede,
            entre otros beneficios, enviar detalles de ofertas, promociones y
            productos a los Socios, permitiendo que se puedan mejorar sus
            servicios respecto de los Socios o procurar responder mejor a las
            necesidades de estos.
          </p>
          <p>
            Por ello es importante que el Socio tenga presente que cuando acepta
            su incorporaci&oacute;n a Club Mundo Experto Easy, declara que los
            antecedentes o datos de car&aacute;cter personal que ha
            proporcionado a Easy en virtud del Programa, en adelante la
            &ldquo;Informaci&oacute;n&rdquo;, son sustancialmente correctos y
            corresponden a la realidad, debiendo comunicar cualquier
            modificaci&oacute;n de los mismos tan pronto como éstas se
            produjeren. Para Cencosud, la anterior declaraci&oacute;n es un
            elemento determinante para permitir la incorporaci&oacute;n del
            Socio al Programa, siendo el Socio personalmente responsable de las
            consecuencias que pudiere ocasionar su incumplimiento.
          </p>
          <p>
            En consideraci&oacute;n a lo anterior, el Socio consiente
            expresamente que, como efecto de su incorporaci&oacute;n al
            Programa, la Informaci&oacute;n que se acumule, recolecte, reciba o
            recoja de los Socios ,podr&aacute;́ ser:
          </p>
          <p>
            1.- La Informaci&oacute;n podr&aacute; ser objeto de: (i)
            Almacenamiento, entendiendo por tal el archivo de la
            informaci&oacute;n en lugares especialmente destinados al efecto;
            (ii) Procesamiento, entendiendo por tal los mecanismos y procesos
            que permitan brindar al Socio los servicios ofrecidos; (iii)
            Tratamiento, entendiendo por tal cualquier operaci&oacute;n o
            complejo de operaciones o procedimientos t&eacute;cnicos, de
            car&aacute;cter automatizado o no, que permitan recolectar,
            almacenar, grabar, organizar, elaborar, agrupar, seleccionar,
            extraer, confrontar, interconectar, disociar o cancelar la
            informaci&oacute;n; y/o (iv) Disposici&oacute;n, entendiendo por tal
            comunicar, ceder, transferir, transmitir o cancelar datos de
            car&aacute;cter personal o utilizarlos en cualquier forma. Lo
            anterior salvo que el Socio, respecto del tratamiento y
            disposici&oacute;n, expresamente instruya lo contrario mediante
            comunicaci&oacute;n escrita, despachada mediante carta certificada
            al domicilio de Club Mundo Experto Easy.
          </p>
          <p>
            2.- Respetando las disposiciones legales que regulen la materia, la
            Informaci&oacute;n podr&aacute; almacenarse, procesarse y tratarse
            en cualquier pa&iacute;s del mundo. Sin perjuicio de lo anterior,
            Easy ser&aacute; siempre responsable del debido cuidado de la
            Informaci&oacute;n debiendo tomar medidas de seguridad razonables
            para resguardar la Informaci&oacute;n contra su manipulaci&oacute;n,
            p&eacute;rdida, destrucci&oacute;n, divulgaci&oacute;n y acceso no
            autorizado.
          </p>
          <p>
            3.- La Informaci&oacute;n almacenada podr&aacute; ser usada para los
            fines que, a continuaci&oacute;n, se expresan: (i) la
            mantenci&oacute;n de las relaciones contractuales y/o comerciales
            derivadas del Programa entre Easy y el Socio; (ii) la
            confecci&oacute;n y/o mejora de los productos y/o servicios que Easy
            pudiera prestar a los Socios, as&iacute; como el dise&ntilde;o de
            nuevos productos y/o servicios para éstos; (iii) el despacho por
            cualquier v&iacute;a de todo tipo de informaci&oacute;n, incluyendo,
            pero no limitada a, antecedentes t&eacute;cnicos, publicidad y
            promoci&oacute;n de productos y/o servicios, Club Mundo Experto
            Easy, Tiendas Easy o de terceros, formularios y encuestas; y (iv) la
            realizaci&oacute;n de estudios individuales y/o colectivos de
            cualquier tipo como, por ejemplo, estudios en materia
            demogr&aacute;fica, hist&oacute;rica, comportamiento de los Socios e
            investigaciones de mercado; cuidando siempre de la reserva y
            confidencialidad de los datos personales de la manera exigida por la
            ley.
          </p>
          <p>
            4.- El Socio consiente expresamente que, en el desarrollo del
            Programa, Cencosud podr&aacute; tener acceso o solicitar al Socio o
            a terceros, informaci&oacute;n que pudiere ser m&aacute;s
            espec&iacute;fica respecto del Socio, en adelante la
            &ldquo;Informaci&oacute;n Adicional&rdquo;, que revele preferencias,
            gustos, h&aacute;bitos, comportamientos, bienes, obligaciones y en
            general toda aquella que permita un mayor conocimiento del Socio, la
            cual podr&aacute; almacenarse, procesarse, tratarse y disponerse en
            los mismos t&eacute;rminos indicados en los n&uacute;meros
            precedentes.
          </p>
          <p>
            5.- Easy podr&aacute; disponer y comunicar al p&uacute;blico todo o
            parte de la Informaci&oacute;n o de la Informaci&oacute;n Adicional,
            caso en el cual su destinatarios deber&aacute; ser una persona
            relacionada a la propiedad o gesti&oacute;n de Easy Retail S.A., ser
            una filial o matriz, o ser una persona que esté bajo el control
            com&uacute;n de Cencosud S.A. o Easy Retail S.A..Club Mundo Experto
            Easy procurará que el destinatario de la referida informaci&oacute;n
            garantice que &eacute;sta ser&aacute;́ tratada bajo adecuados
            par&aacute;metros de reserva y que, en el evento que el Socio se
            oponga al uso de la misma, ser&aacute;́ prontamente eliminada de los
            registros respectivos. Por &uacute;ltimo, tanto Easy como los
            citados destinatarios s&oacute;lo podr&aacute;n usar la
            Informaci&oacute;n o la Informaci&oacute;n Adicional con los
            prop&oacute;sitos indicados en el n&uacute;mero 3 precedente, caso
            este &uacute;ltimo en que las condiciones de esta pol&iacute;tica
            aplicables al Programa deber&aacute;n ser cumplidas por dichos
            destinatarios.
          </p>
          <p>
            6.- Las restricciones contempladas en los numerales anteriores no
            ser&aacute;n aplicables si Easy, en conformidad con la
            legislaci&oacute;n vigente, comparte informaci&oacute;n para
            investigar, prevenir o tomar acciones sobre actividades ilegales,
            delitos, sospechas de fraude, lavado de activos, terrorismo y
            situaciones que involucran riesgos potenciales a la seguridad de
            cualquier persona, o si es requerida por autoridades
            jurisdiccionales o administrativas en el ejercicio de las
            atribuciones que les son propias.
          </p>
          <p>
            7.- Tampoco ser&aacute;n aplicables las restricciones anteriores
            cuando se trate de &ldquo;informaci&oacute;n
            estad&iacute;stica&rdquo;, entendiendo por &eacute;sta aquella
            informaci&oacute;n de la cual se han eliminado aquellos campos o
            se&ntilde;as que permiten identificar a un Socio determinado y, por
            tanto, s&oacute;lo dice relaci&oacute;n con datos sobre un
            determinado producto, grupo o categor&iacute;a de productos y/o
            servicios, o sobre un grupo de Socios no identificados o
            identificables.
          </p>
          <p>
            <strong>III.- GOOGLE ANALYTICS EN MUNDOEXPERTO.CL </strong>
          </p>
          <p>
            Google Analytics es una herramienta para el an&aacute;lisis web que
            mediante la utilizaci&oacute;n de cookies permite entregar
            informaci&oacute;n estad&iacute;stica, an&oacute;nima, asociada a
            los visitantes de{" "}
            <a href="http://www.mundoexperto.cl">www.mundoexperto.cl</a>.
            Adicionalmente mundoexperto.cl utiliza esta herramienta para fines
            publicitarios, mostrando anuncios en los sitios web asociados a la
            red de contenido de Google bas&aacute;ndose en las visitas previas a
            nuestro sitio, lo que se conoce como Remarketing.
          </p>
          <p>
            Para evitar la recopilaci&oacute;n de datos desde Google Analytics
            existen las siguientes alternativas:
          </p>
          <p>
            Deshabilitar el almacenamiento de cookies en el explorador de
            internet correspondiente.
          </p>
          <p>
            Descargar un complemento al explorador de internet en el siguiente
            link: https://tools.google.com/dlpage/gaoptout/.
          </p>
          <p>
            <strong>IV.- DERECHO DEL SOCIO</strong>
          </p>
          <p>
            Sin perjuicio de los beneficios que ofrece el Programa al Socio,
            Easy Retail S.A. reconoce y acepta que los Socios tienen el
            leg&iacute;timo derecho a solicitar la exclusi&oacute;n de la
            Informaci&oacute;n y de la Informaci&oacute;n Adicional. En el marco
            antes indicado, Easy ofrece al Socio las siguientes alternativas de
            exclusi&oacute;n: (i) No recibir a trav&eacute;s del Programa
            informaci&oacute;n sobre ofertas y promociones de Mundo Experto;
            (ii) No ser contactado con fines de estudios de mercado; (iii) No
            ser incluido como destinatario en el env&iacute;o de ofertas
            especiales, publicidad y otra informaci&oacute;n y comunicaciones
            por correo electr&oacute;nico y/o tel&eacute;fono m&oacute;vil y/o;
            (iv) Restringir la disposici&oacute;n de su informaci&oacute;n
            personal a Easy.
          </p>
          <p>
            El Socio dispondr&aacute; siempre de los derechos de
            informaci&oacute;n, rectificaci&oacute;n y cancelaci&oacute;n de los
            datos personales conforme a la Ley N&ordm; 19.268 sobre
            protecci&oacute;n de datos de car&aacute;cter personal. El Socio
            podrá hacer efectivo el derecho antes referido enviando un correo a
            datospersonales@cencosud.cl
          </p>
          <p>
            Las anteriores opciones s&oacute;lo ser&aacute;n aplicables para
            aquella informaci&oacute;n o datos que el Socio haya facilitado a
            Mundo Experto. Mundo Expero no puede garantizar la privacidad de la
            informaci&oacute;n personal de los Socios del Programa si estos
            suministran o difundan informaci&oacute;n en reportajes de prensa,
            publicaciones, zonas de chateo, boletines u otras similares. Si el
            Socio revela dicha informaci&oacute;n ser&aacute;́ bajo su exclusiva
            responsabilidad.
          </p>
          <p>
            <strong>Felipe Longo</strong> <br />
            <strong>Gerente Easy Chile</strong>
            <br />
            <strong>EASY RETAIL S.A.</strong>
          </p>
        </div>
        <footer className="popup__footer">
          <div className="separador"></div>
          <div className="btn__grupo justify-end-xs flex-xs">
            <span
              className="btn btn--secundario btn--borde btn--redondo"
              onClick={close}
            >
              Entendido
            </span>
          </div>
        </footer>
      </div>
    )}
  />
);

export default Privacy;
