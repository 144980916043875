import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import { Link } from "react-router-dom";

import { confirmAccountEmail } from "../../actions/auth";
import { CodeResponse } from "../../utils/helpers";

export default function RegisterConfirm() {
  const dispatch = useDispatch();
  const { code = "" } = useParams();

  const accountConfirm = useSelector((state) => state.accountConfirm);
  const { isFetching = true, err = {} } = accountConfirm || {};
  const { code: codeErr = "" } = err || {};

  useEffect(() => {
    dispatch(
      confirmAccountEmail({
        code,
      })
    );
  }, [dispatch, code]);

  return (
    <>
      {!code && <Redirect to="/" />}
      {code && (
        <section className="registro">
          <div className="registro__bg">
            <img
              src="assets/img/registro-bg.jpg"
              alt="Inscripción"
            />
          </div>
          <div className="container">
            <div className="responses">
              <div className="responses__item">
                <h2 className="color--secundario text-upper text-wide">
                  Éxito
                </h2>
                {isFetching && (
                  <div className="preloader">
                    <div className="preloader__child"></div>
                  </div>
                )}
                {!isFetching && !codeErr && (
                  <p>Su cuenta ha sido confirmada exitosamente</p>
                )}
                {!isFetching && codeErr && <p>{CodeResponse(codeErr)}</p>}
                <Link
                  to="/login"
                  className="btn btn--small btn--primario btn--borde"
                >
                  Ingresar
                </Link>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
