import React from "react";
import { useSelector } from "react-redux";
import Breadcrumbs from "../../components/Breadcrumbs";
import History from "./History";

import Sidebar from "./Sidebar";
import Stats from "./Stats";


import {
	formatNumber,
	sumTotalSalesWithoutMonthCurrent,
} from "../../utils/helpers";

export default function Dashboard(props) {
	const profile = useSelector((state) => state.profile);
	const { data = {} } = profile || {};
	const { firstname = "", lastname = "" } = data || {};

	const sale = useSelector((state) => state.sale);
	const { data: dataSale = {} } = sale || {};
	const { segment_current: segmentCurrent = "" } = dataSale || {};

  console.log(sale)

	const getSegment = (segment) => {
		switch (segment) {
			case "R":
				return <div className="color--plata text-upper text-wide">Experto Plata</div>
			case "P":
				return <div className="color--oro text-upper text-wide">Experto Oro</div>
			case "I": default:
				return <div className="color--bronce text-upper text-wide">Experto Bronce</div>
		}
	}

	return (
		<div className="dashboard__container">
			<Sidebar />
			<div className="dashboard__body">
				<div className="dashboard__content container">
					{props.component ? (
						<props.component />
					) : (
						<>
							<Breadcrumbs
								crumbs={[
									{
										type: "link",
										path: "/mi-cuenta",
										label: "Mi cuenta",
									},
								]}
							/>
							<h1>
								Hola
								<br />
								<span className="h4 text-upper text-wide">
									{`${firstname} ${lastname}`}
								</span>
							</h1>
							<h4>
								Este mes eres un socio {getSegment(segmentCurrent)}
							</h4>
							<p>
								Porque en los últimos 6 meses acumulaste compras por {formatNumber(sumTotalSalesWithoutMonthCurrent(dataSale))}
							</p>
							<Stats />
							<History type="preferente" />
						</>
					)}
				</div>
			</div>
		</div>
	);
}
