import {
  GET_TOPIC_CATEGORIES_REQUEST,
  GET_TOPIC_CATEGORIES_SUCCESS,
  GET_TOPIC_CATEGORIES_ERROR,
  GET_TOPICS_REQUEST,
  GET_TOPICS_SUCCESS,
  GET_TOPICS_ERROR,
  GET_TOPIC_REQUEST,
  GET_TOPIC_SUCCESS,
  GET_TOPIC_ERROR,
} from '../types/topics';

import {
  GetCategoriesTopic,
  GetTopics,
} from '../requests/topics';

const getCategoriesTopicsRequest = data => ({
  type: GET_TOPIC_CATEGORIES_REQUEST,
  payload: data,
});

const getCategoriesTopicsSuccess = data => ({
  type: GET_TOPIC_CATEGORIES_SUCCESS,
  payload: data,
});

const getCategoriesTopicsError = data => ({
  type: GET_TOPIC_CATEGORIES_ERROR,
  payload: data,
});

const getCategoriesTopics = (payload = {}) => dispatch => {
  dispatch(getCategoriesTopicsRequest(payload));
  GetCategoriesTopic(payload)
    .then(({ data = {} }) => {
      dispatch(getCategoriesTopicsSuccess(data));
    })
    .catch((error = {}) => {
      const { response = {} } = error;
      const { data = [] } = response;
      dispatch(getCategoriesTopicsError(data))
    });
};


const getTopicsRequest = data => ({
  type: GET_TOPICS_REQUEST,
  payload: data,
});

const getTopicsSuccess = data => ({
  type: GET_TOPICS_SUCCESS,
  payload: data,
});

const getTopicsError = data => ({
  type: GET_TOPICS_ERROR,
  payload: data,
});

const getTopics = (payload = {}) => dispatch => {
  dispatch(getTopicsRequest(payload));
  GetTopics(payload)
    .then(({ data = {} }) => {
      dispatch(getTopicsSuccess(data));
    })
    .catch((error = {}) => {
      const { response = {} } = error;
      const { data = [] } = response;
      dispatch(getTopicsError(data))
    });
};

const getTopicRequest = data => ({
  type: GET_TOPIC_REQUEST,
  payload: data,
});

const getTopicSuccess = data => ({
  type: GET_TOPIC_SUCCESS,
  payload: data,
});

const getTopicError = data => ({
  type: GET_TOPIC_ERROR,
  payload: data,
});

const getTopic = (payload = {}) => dispatch => {
  dispatch(getTopicRequest(payload));
  GetTopics(payload)
    .then(({ data = {} }) => {
      dispatch(getTopicSuccess(data));
    })
    .catch((error = {}) => {
      const { response = {} } = error;
      const { data = [] } = response;
      dispatch(getTopicError(data))
    });
};
export {
  getTopic,
  getTopics,
  getCategoriesTopics,
};
