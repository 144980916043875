import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faSearch,
} from "@fortawesome/free-solid-svg-icons/";

import { getFAQ, getSections } from "../../actions/faq";
import Seo from "../SEO";
import Banner from "../../components/Banner";

const FAQ = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();

  const [faqID, setSectionFAQ] = useState(null);
  const [wordSearch, setSearch] = useState(null);

  useEffect(() => {
    dispatch(getSections());
  }, [dispatch]);

  useEffect(() => {
    let filters = {};
    if (faqID) {
      filters = { section_id: faqID };
    }
    if (wordSearch) {
      filters = { search: wordSearch };
    }
    dispatch(getFAQ({ query: filters }));
  }, [dispatch, faqID, wordSearch]);

  const scrollTO = (id) => {
    let el = document.querySelector(`#hack-${id}`);
    el.scrollIntoView({ behavior: "smooth" });
  };

  const searchFAQ = ({ search = "" }) => {
    setSearch(search);
  };

  const faqSections = useSelector((state) => state.faqSections);
  const faq = useSelector((state) => state.faq);

  const { data: dataSections = {} } = faqSections || {};
  const { sections = [] } = dataSections || {};

  const { data = {}, isFetching = true } = faq || {};
  const { questions = [] } = data || {};

  return (
    <div className="page">
      <Seo
        title="Preguntas Frecuentes"
        description="Encuentra la solución a todas tus dudas de Mundo Experto, enterate de los beneficios y como participar"
        url="/preguntas-frecuentes"
      />
      <Banner
        title="Preguntas frecuentes"
        bg="assets/img/temas-interes-bg.jpg"
        breadcrumbs={[
          {
            type: "link",
            path: "/",
            label: "Inicio",
          },
          { type: "separator" },
          { type: "current", label: "Preguntas Frecuentes" },
        ]}
      />
      <div className="interior">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-lg-3">
              <aside className="sidebar">
                <form
                  className="sidebar__buscador"
                  onSubmit={handleSubmit(searchFAQ)}
                >
                  <input
                    id="search"
                    name="search"
                    placeholder="Buscar..."
                    ref={register}
                  />
                  <button className="sidebar__buscador__button">
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                </form>
                <div className="sidebar__preguntas">
                  {sections.map(({ id = 0, name = "" }) => (
                    <a
                      role="button"
                      key={id}
                      className="sidebar__preguntas__item"
                      href="#0"
                      onClick={() => setSectionFAQ(id)}
                    >
                      <div className="sidebar__preguntas__content">
                        <h3 className="sidebar__preguntas__title">{name}</h3>
                      </div>
                    </a>
                  ))}
                </div>
              </aside>
            </div>
            <div className="col-xs-12 col-lg-9">
              {isFetching && (
                <div className="preloader__container">
                  <div className="preloader">
                    <div className="preloader__child"></div>
                  </div>
                </div>
              )}
              {!isFetching && questions && (
                <Accordion
                  onChange={(x) => {
                    scrollTO(x[0]);
                  }}
                  id="preguntas-frecuentes"
                >
                  {questions.map(({ id = 0, question = "", answer = "" }) => (
                    <div key={id}>
                      <div className="accordion__hack" id={`hack-${id}`}></div>
                      <AccordionItem
                        uuid={id}
                        activeClassName="accordion__item accordion__item--active"
                        id={id}
                      >
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            {question}
                            <AccordionItemState>
                              {({ expanded }) =>
                                expanded ? (
                                  <FontAwesomeIcon icon={faChevronUp} />
                                ) : (
                                  <FontAwesomeIcon icon={faChevronDown} />
                                )
                              }
                            </AccordionItemState>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel
                          dangerouslySetInnerHTML={{ __html: answer }}
                        ></AccordionItemPanel>
                      </AccordionItem>
                    </div>
                  ))}
                </Accordion>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
