import React from 'react';

const Discounts = () => (
    <section className='descuento'>
        <div className='descuento__bg'>
            <img src='assets/img/descuentos-bg.jpg' alt='Descuentos experto' />
        </div>
        <div className='descuento__container container'>
            <div className='descuento__content'>
                <div className='descuento__content__bg'>
                    <img src='assets/img/descuentos-content-bg.jpg' alt='' />
                </div>
                <div className='descuento__info'>
                    <div className='descuento__info__content'>
                        <div className='descuento__info__icon'>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='86.851'
                                height='101.063'
                                viewBox='0 0 86.851 101.063'
                            >
                                <path
                                    d='M238.536,182.774c-2.51,0-4.3,1.06-4.3,2.622v21.253c0,1.339,1.785,2.678,4.3,2.678,2.4,0,4.351-1.339,4.351-2.678V185.4C242.887,183.834,240.935,182.774,238.536,182.774Z'
                                    transform='translate(-195.11 -146.696)'
                                    fill='#ff9227'
                                />
                                <path
                                    d='M237.4,116.361c-2.566,0-4.574,1.841-4.574,3.961a4.581,4.581,0,0,0,9.093,0A4.3,4.3,0,0,0,237.4,116.361Z'
                                    transform='translate(-193.976 -93.393)'
                                    fill='#ff9227'
                                />
                                <path
                                    d='M79.426,0A43.4,43.4,0,0,0,66.283,84.775l9.876,14.557a3.948,3.948,0,0,0,6.534,0l9.876-14.557A43.4,43.4,0,0,0,79.426,0Zm9.6,77.593a3.948,3.948,0,0,0-2.2,1.586l-7.4,10.9-7.4-10.9a3.948,3.948,0,0,0-2.2-1.586,35.53,35.53,0,1,1,19.2,0Z'
                                    transform='translate(-36 0)'
                                    fill='#ff9227'
                                />
                            </svg>
                        </div>
                        <h3 className='descuento__title text-upper text-wide color--blanco'>
                            Extiende tu descuento
                        </h3>
                        <p className='descuento__desc color--blanco'>
                            Podrás extender el beneficio de socio{' '}
                            <strong>Experto bronce</strong> convirtiendote en
                            socio <strong>Experto Plata</strong>. Para ello
                            debes realizar compras por <strong>$500.000</strong>{' '}
                            en un periodo de seis meses. Así también, puedes
                            optar a un descuento mayor siendo socio{' '}
                            <strong>Oro.</strong> Para ello, tienes que acumular{' '}
                            <strong>$1.500.000</strong> en compras durante el
                            mismo periodo de tiempo.
                        </p>
                    </div>
                </div>
            </div>
            <footer className='descuento__footer'>
                <span className='descuento__footer__btn btn btn--primario btn--redondo btn--xsmall'>
                    Descuento
                </span>
                <p className='descuento__footer__desc'>
                    Recuerda siempre mostrar tu rut en caja al momento de
                    realizar tus compras, para obtener tus beneficios. También
                    podrás obtener los descuentos a través de Easy.cl
                </p>
            </footer>
        </div>
    </section>
);

export default Discounts;
