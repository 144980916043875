import React from "react";
import { Link } from "react-router-dom";
import PopUp from "../../components/Popup";

const RutPopup = ({ open, setOpen }) => {
  return (
    <PopUp
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      content={(close) => (
        <div className="popup__content slideUp">
          <div className="content">
            <h3>Este RUT ya existe en nuestro sistema</h3>
            <p>
              Si tienes cuenta y no la recuerdas, prueba recuperar tu
              contraseña, si fue un error de tipeo, ignora este mensaje.
            </p>
          </div>
          <footer className="popup__footer">
            <div className="separador"></div>
            <div className="btn__grupo justify-end-xs flex-xs">
              <span
                className="btn btn--secundario btn--borde btn--redondo"
                onClick={close}
              >
                Cerrar
              </span>
              <Link
                to="/recordar-contrasena"
                className="btn btn--primario btn--redondo"
                onClick={() => {
                  setOpen(false);
                  document.querySelector("html").style.overflow = "";
                }}
              >
                Recuperar Contraseña
              </Link>
            </div>
          </footer>
        </div>
      )}
    />
  );
}

export default RutPopup;
