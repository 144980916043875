import React from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import Seo from "../SEO";
import Carousel from "./Carousel";
import Content from "./Content";
import Fechas from "../../assets/json/jugadores-expertos.json";

const ExpertPlayer = () => {
  const title = "Jugador Experto";
  const url = "jugador-experto";
  return (
    <div className="page">
      <Seo
        title={title}
        description=""
        url={url}
        og={{
          title: { title },
          description: "",
          url: url,
          image: "",
        }}
      />
      <div className="players">
        <div className="players__banner">
          <h1>{title}</h1>
          <div className="players__banner__bg">
            <img
              src="assets/img/expert--bg.jpg"
              alt={title}
            />
          </div>
          <div className="players__banner__container container">
            <div className="players__carousel__container">
              <div className="players__carousel__logos">
                <img
                  src="assets/img/campeonato-plan-vital-logo.png"
                  alt="Campeonato Plan Vital"
                />
                <img
                  src="assets/img/easy-logo.png"
                  alt="Easy"
                />
              </div>
              <Carousel
                items={Fechas[0].items.filter((x) => x.date === Fechas[0].date)}
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="separador"></div>
          <Breadcrumbs
            crumbs={[
              {
                type: "link",
                path: "/",
                label: "Inicio",
              },
              {
                type: "separator",
              },
              {
                type: "current",
                label: title,
              },
            ]}
          />
        </div>
        <div className="players__section">
          <Content dateNow={Fechas[0].date} items={Fechas[0].items} />
        </div>
      </div>
    </div>
  );
};

export default ExpertPlayer;
