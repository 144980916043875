import {
  POST_ACTIVE_EMAIL_REQUEST,
  POST_ACTIVE_EMAIL_SUCCESS,
  POST_ACTIVE_EMAIL_ERROR,
} from '../types/auth';

const initialState = {
  err: {},
  isFetching: false,
  data: {},
};

const confirmReducer = (state = initialState, action = {}) => {
  const { type = '', payload = {} } = action;
  switch (type) {
    case POST_ACTIVE_EMAIL_REQUEST:
      return {
        ...state,
        isFetching: true,
        err: null,
      };
    case POST_ACTIVE_EMAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: payload,
        err: null,
      };
    case POST_ACTIVE_EMAIL_ERROR:
      const { errors = '' } = payload;
      return {
        ...state,
        isFetching: false,
        err: errors,
      };
    default: {
      return state;
    }
  }
};

export default confirmReducer;
