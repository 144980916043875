import React from "react";
import { Link } from "react-router-dom";

const Error404 = () => (
  <div className="error404">
    <div className="error404__bg">
      <img
        src="assets/img/login-bg.jpg"
        alt="Error 404"
      />
    </div>
    <div className="container">
      <div className="error404__content">
        <h1 className="text-upper text-wide">!oh no¡</h1>
        <h2 className="text-upper text-wide h4">
          La pagina que buscas no existe
        </h2>
        <p className="text-wide">
          Puede que la URL haya caducado o la hayas tipeado mal.
        </p>
        <div className="separador"></div>
        <Link to="/" className="btn btn--secundario">
          Ir al inicio
        </Link>
      </div>
    </div>
  </div>
);
export default Error404;
