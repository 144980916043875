export const GET_TOPIC_CATEGORIES_REQUEST = 'GET_TOPIC_CATEGORIES_REQUEST';
export const GET_TOPIC_CATEGORIES_SUCCESS = 'GET_TOPIC_CATEGORIES_SUCCESS';
export const GET_TOPIC_CATEGORIES_ERROR = 'GET_TOPIC_CATEGORIES_ERROR';

export const GET_TOPICS_REQUEST = 'GET_TOPICS_REQUEST';
export const GET_TOPICS_SUCCESS = 'GET_TOPICS_SUCCESS';
export const GET_TOPICS_ERROR = 'GET_TOPICS_ERROR';

export const GET_TOPIC_REQUEST = 'GET_TOPIC_REQUEST';
export const GET_TOPIC_SUCCESS = 'GET_TOPIC_SUCCESS';
export const GET_TOPIC_ERROR = 'GET_TOPIC_ERROR';
