import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";

import { getSale } from "../../actions/sale";

import {
  formatDate,
  formatNumber,
  sumTotalSales,
  getUserDateType,
} from "../../utils/helpers";
import StatusBar from "./StatusBar";

export default function Stats() {
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.profile);
  const { data = {} } = profile || {};
  const { data: userData = [] } = data || {};
  const dni = getUserDateType(userData, "dni");

  const [updateCat, setValidateDate] = useState("");

  const handleUpdateCat = () => {
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    const date = new Date();
  
    let monthIndex = date.getMonth();
    if (date.getUTCDate() > 7) {
      monthIndex = (monthIndex + 1) % 12;
    }
  
    const validDate = `7 de ${months[monthIndex]}`;
    setValidateDate(validDate);
  };

  useEffect(() => {
    if (dni !== "") {
      dispatch(
        getSale({
          query: {
            dni,
          },
        })
      );
    }
    handleUpdateCat();
  }, [dispatch, dni]);

  const sale = useSelector((state) => state.sale);
  const { data: dataSale = {}, isFetching: isFetchingSale = true } = sale || {};
  const { month_current: monthCurrent = 0, points_balance = 0, updated = '' } = dataSale || {};

  return (
    <>
      <div className="separador"></div>
      <div className="dashboard__stats">
        <div className="dashboard__stats__bg">
          <img
            src="assets/img/dashboard-stats-bg.jpg"
            alt="Estadísticas"
          />
        </div>
        <div className="dashboard__stats__container">
          {isFetchingSale && (
            <div className="dashboard__stats__item">
              <div className="preloader">
                <div className="preloader__child"></div>
              </div>
            </div>
          )}
          {!isFetchingSale && (
            <>
              <div className="dashboard__stats__item">
                {/* <div className="dashboard__stats__icon color--primario">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34.405"
                    viewBox="0 0 34.405 34.423"
                  >
                    <path
                      d="M32.811,182.042V160.825a.573.573,0,0,0-.573-.573H26.5a.573.573,0,0,0-.573.573v21.216H24.783V168.853a.573.573,0,0,0-.573-.573H18.475a.573.573,0,0,0-.573.573v13.189H16.755V163.119a.573.573,0,0,0-.573-.573H10.447a.573.573,0,0,0-.573.573v18.923H8.727V171.147a.573.573,0,0,0-.573-.573H2.42a.573.573,0,0,0-.573.573v10.895H.126v1.147h34.4v-1.147Zm-25.23,0H2.993V171.72H7.58Zm8.028,0H11.021V163.693h4.587Zm8.028,0H19.049V169.427h4.587Zm8.028,0H27.076V161.4h4.587Z"
                      transform="translate(-0.126 -148.766)"
                    />
                    <path
                      d="M33.405,15.6a2.867,2.867,0,0,0,3.973-3.973l4.059-4.059a2.838,2.838,0,0,0,1.572.479,2.878,2.878,0,0,0,.5-.044,2.847,2.847,0,0,0,1.455-.727l3.4,2.615a2.867,2.867,0,1,0,5.064-.559l4.057-4.057a2.876,2.876,0,1,0-.811-.811L52.623,8.521a2.867,2.867,0,0,0-3.608.364c-.011.011-.017.024-.028.034L45.635,6.342a2.867,2.867,0,1,0-5.01.416l-4.057,4.057A2.867,2.867,0,1,0,33.405,15.6ZM59.07,1.165a1.72,1.72,0,1,1-1.72,1.72A1.72,1.72,0,0,1,59.07,1.165ZM49.825,9.7a1.72,1.72,0,1,1,2.433,2.433,1.763,1.763,0,0,1-2.432,0A1.72,1.72,0,0,1,49.825,9.7Zm-8.22-5.5h0a1.709,1.709,0,0,1,1.11-.708,1.794,1.794,0,0,1,.3-.026,1.72,1.72,0,1,1-1.412.734Zm-7.836,7.8h0A1.72,1.72,0,1,1,36.2,14.423a1.763,1.763,0,0,1-2.432,0A1.72,1.72,0,0,1,33.769,11.991Z"
                      transform="translate(-29.826 0)"
                    />
                  </svg>
                </div> */}
                <div className="dashboard__stats__label">
                  Total Compras <br /> del Mes actual Son
                </div>
                <div className="dashboard__stats__value">
                  {formatNumber(monthCurrent)}
                </div>
                <div className="dashboard__stats__desc">
                  Actualizado <br />
                  <span>{formatDate(updated, "DD-MM-YYYY hh:mm:ss")}</span>
                </div>
              </div>
              {/* <div className="dashboard__stats__item">
                <div className="dashboard__stats__icon">
                  <i className="fas fa-icon"></i>
                </div>
                <div className="dashboard__stats__value">
                  {formatNumber(1500000 - sumTotalSales(dataSale))}
                </div>
                <div className="dashboard__stats__label">
                  Compras restantes para ser{" "}
                  <span className="bg--primario color--blanco"> Preferente </span>
                </div>
              </div> */}
              <div className="dashboard__stats__item">
                {/* <div className="dashboard__stats__icon dashboard__stats__icon--img">
                  <img
                    src={require("../../assets/puntos.png").default}
                    alt="Puntos Cencosud"
                  />
                </div> */}
                <div className="dashboard__stats__label">
                  Tus puntos Cencosud <br />
                  acumulados son
                </div>
                <div className="dashboard__stats__value">
                  {formatNumber(points_balance, false)}
                </div>
                <div className="dashboard__stats__desc">
                  Actualizado <br />
                  <span>{formatDate(updated, "DD-MM-YYYY hh:mm:ss")}</span>
                </div>
              </div>
            </>
          )}
          {/* <div className="dashboard__stats__item dashboard__stats__item--h">
            <div className="dashboard__stats__icon dashboard__stats__icon--big"></div>
            <div className="dashboard__stats__info">
              <div className="dashboard__stats__title text-wide text-upper">
                {dataSale >= 1500000 ? "Preferente" : "Experto"}
              </div>
              <div className="dashboard__stats__label">
                Acumulas mas de {dataSale >= 1500000 ? "$1.500.000" : "$300.000"}{" "}
                En los últimos 6 meses
              </div>
              <div className="separador--small"></div>
              <Link
                to={dataSale >= 1500000 ? "/preferente" : "/experto"}
                className="btn btn--terciario btn--xsmall btn--invertido btn--redondo"
              >
                Ver Beneficios
              </Link>
            </div>
          </div> */}
        </div>
      </div>
      <p>
        Actualizaremos nuevamente tu categoría el día{" "}
        <strong>{updateCat}</strong>
      </p>
      <div className="separador"></div>
      <p>Y así va tu avance de compras para el próximo cálculo de categoría.</p>
      <StatusBar value={sumTotalSales(dataSale)} />
      <p>
        Recuerda que logrando la categoría <strong>Oro</strong> tendrás
        siempre el mejor precio.
      </p>
      <div className="separador"></div>
    </>
  );
}
