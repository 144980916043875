import React from "react";
import Page from "../../components/Page";
import Seo from "../SEO";
import Category from "../../components/Category";
import Categories from "../../assets/json/categorias.json";
import { Link } from "react-router-dom";
// import bg from "../../assets/img/beneficios--bg.jpg";

const Benefits = () => {
	const title = "Beneficios";
	const url = "/beneficios";
	return (
		<>
			<Seo
				title={title}
				description="Conoce los múltiples beneficios que puedes acceder en Mundo Experto"
				url={url}
			/>
			<Page
				title={title}
				banner
				breadcrumbs={[
					{
						type: "link",
						path: "/",
						label: "Inicio",
					},
					{
						type: "separator",
					},
					{
						type: "current",
						label: title,
					},
				]}
				content={
					<>
						<div className="beneficios">
							<div className="container">
								<div className="separador"></div>
								<div className="separador"></div>
								<div className="expertos__info">
									<h2 className="h3 text-wide text-upper">
										¿Qué beneficios tengo?
									</h2>
									<p className="section__subtitle">
										Si eres socio categoría Bronce, Plata u Oro, puedes acceder
										a los siguientes beneficios.
									</p>
								</div>
								<div className="beneficios__calugas">
									<div className="row">
										<div className="col-xs-12 col-md-6">
											<div className="beneficios__calugas__item bg--primario">
												<h3 className="beneficios__calugas__title text-upper text-wide color--blanco h4">
													Descuentos en múltiples categorías
												</h3>
												<p className="beneficios__calugas__desc color--blanco">
													En materiales, accesorios y herramientas de
													construcción.
												</p>
											</div>
										</div>
										<div className="col-xs-12 col-md-6">
											<div className="beneficios__calugas__item bg--secundario">
												<h3 className="beneficios__calugas__title text-upper text-wide color--blanco h4">
													DESCUENTOS ACUMULABLES
												</h3>
												<p className="beneficios__calugas__desc color--blanco">
													Todos tus descuentos Mundo Experto son acumulables con
													otras ofertas y promociones en las categorías afectas al programa.
												</p>
											</div>
										</div>
										{/* <div className="col-xs-12 col-md-4">
											<div className="beneficios__calugas__item bg--terciario">
												<h3 className="beneficios__calugas__title text-upper text-wide color--blanco h4">
													MÁS COMPRAS, MÁS DESCUENTO
												</h3>
												<p className="beneficios__calugas__desc color--blanco">
													Mientras más compras realices, más rápido subes de
													categoría, para llegar a PREFERENTE y obtener los
													mejores descuentos del Club.
												</p>
											</div>
										</div> */}
									</div>
								</div>
								<div className="separador--big"></div>
								<div className="expertos__info">
									<h2 className="h3 text-wide text-upper">
										¿Cuáles son los descuentos?
									</h2>
								</div>

								<div className="row">
									{Categories.filter(category => category.isActive).map(({ name, slug, desc, dscto }, key) => (
										<div
											className="col-xs-12 col-md-6 col-lg-4 col-xl-3"
											key={key}
										>
											<Category
												name={name}
												slug={slug}
												dscto={dscto}
												desc={desc}
											/>
										</div>
									))}
								</div>
							</div>
							{/* <div className="expertos__info expertos__info--section">
								<div className="container">
									<h2 className="h3 text-wide text-upper">
										Extiende tu descuento mundo experto
									</h2>
									<p>
										Podrás extender el beneficio de Socio <span>EXPERTO</span>{" "}
										realizando compras que{" "}
										<span>ACUMULEN $300.000 en un periodo de seis meses.</span>
									</p>
									<p>
										Así también puedes optar a un descuento mayor siendo Cliente{" "}
										<span>EXPERTO PREFERENTE</span>. Para ello tienes que
										acumular <span>$1.500.000</span> en compras durante el mismo
										periodo de tiempo.
									</p>
								</div>
							</div> */}
							<div className="container">
								<div className="separador"></div>
								<div className="expertos__info">
									<h2 className="h3 text-wide text-upper">
										Si no estás inscrito, sigue estos pasos:
									</h2>
								</div>
								<div className="beneficios__pasos">
									<div className="row">
										<div className="col-xs-12 col-md-6 col-lg-4">
											<div className="beneficios__pasos__item bg--primario">
												<div className="beneficios__pasos__content">
													<div className="beneficios__pasos__n">1</div>
													<div className="beneficios__pasos__desc">
														Inscríbete{" "}
														<Link
															to="/inscripcion"
															className="color--terciario"
														>
															Aquí
														</Link>{" "}
														y valida tu email.
													</div>
												</div>
											</div>
										</div>
										<div className="col-xs-12 col-md-6 col-lg-4">
											<div className="beneficios__pasos__item bg--terciario">
												<div className="beneficios__pasos__content">
													<div className="beneficios__pasos__n">2</div>
													<div className="beneficios__pasos__desc">
														<span className="color--secundario">
															En 3 días hábiles
														</span>{" "}
														después de validar tu email, tus descuentos quedarán
														activos.
													</div>
												</div>
											</div>
										</div>
										<div className="col-xs-12 col-md-6 col-lg-4">
											<div className="beneficios__pasos__item bg--secundario">
												<div className="beneficios__pasos__content">
													<div className="beneficios__pasos__n">3</div>
													<div className="beneficios__pasos__desc">
														<span className="color--terciario">
															Muestra tu rut en caja
														</span>{" "}
														para utilizar tus descuentos.
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="separador--big"></div>
								{/* <div className="expertos__info">
									<h2 className="h3 text-wide text-upper">
										Y si tienes tarjeta Cencosud Scotiabank, accede a:
									</h2>
								</div> */}
								{/* <Swiper
									spaceBetween={0}
									slidesPerView={1}
									pagination={{ clickable: true }}
									autoplay={{
										delay: 6000,
									}}
									loop
									speed={1500}
									className={"huincha__slider"}
								>
									<SwiperSlide>
										<div className="huincha">
											<picture>
												<source
													srcSet={huinchaCuotasM}
													media="(max-width:767px)"
												/>
												<source srcSet={huinchaCuotas} />
												<img
													src={huinchaCuotas}
													alt="3 Cuotas Precio Contado"
												/>
											</picture>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="huincha">
											<picture>
												<source
													srcSet={huinchaMesesM}
													media="(max-width:767px)"
												/>
												<source srcSet={huinchaMeses} />
												<img
													src={huinchaMeses}
													alt="3 Meses de Gracia"
												/>
											</picture>
										</div>
									</SwiperSlide>
								</Swiper> */}
								<div className="separador--big"></div>
								{/* <div className="row">
									<div className="col-xs-12">
										<a
											href="https://www.tarjetacencosud.cl/TarjetaMasWEB/simularAvance.do?menu=publico&tipoAvance=avance&utm_source=m_experto&utm_medium=banner&utm_campaign=avance"
											className="huincha"
											title="3 Cuotas Precio Contado"
											target="_blank"
											rel="noreferrer"
										>
											<picture>
												<source
													srcSet={huinchaAvanceM}
													media="(max-width:767px)"
												/>
												<source srcSet={huinchaAvance} />
												<img src={huinchaAvance} alt="Simula tu avance" />
											</picture>
										</a>
										<div className="legal-terms">
											{/* <div className="legal-terms__item">
												<p>
													<strong>3 Cuotas Precio Contado</strong>
												</p>
												<p>
													“Cae de 0% calculado sobre un monto referencial de
													$60.000 en 3 cuotas con tasa de interés del 0%. Costo
													total del crédito $60.000. Para compras con boletas
													efectuadas en locales Easy e Easy.cl pagando el monto
													total de la compra Tarjetas Cencosud Scotiabank
													emitidas por CAT Administradora de Tarjetas S.A.
													Infórmese sobre la garantía estatal de los depósitos
													en su banco o en{" "}
													<a
														href="https://www.cmfchile.cl"
														target="_blank"
														rel="noreferrer"
													>
														www.cmfchile.cl
													</a>{" "}
													. Cuotas en otros plazos se encuentra sujeta al
													interés informado en pizarras.”
												</p>
											</div> */}
											{/* <div className="legal-terms__item">
												<p>
													<strong>3 Meses Más (Meses de Gracia)</strong>
												</p>
												<p>
													“Meses de Gracia opción flexible que permite diferir
													el vencimiento de la primera cuota hasta en 3 meses.
													Válido para compras en cuotas con Tarjeta Cencosud y/o
													Tarjeta Más emitidas por CAT Administradora de
													Tarjetas S.A. Compras realizadas sólo en Paris, Easy y
													Johnson. Las compras y avances con Meses de Gracia
													generan interés adicional, consulta por el valor final
													de la cuota en caja. Infórmese sobre la garantía
													estatal de los depósitos en su banco o en{" "}
													<a
														href="https://www.cmfchile.cl"
														target="_blank"
														rel="noreferrer"
													>
														www.cmfchile.cl
													</a>
												</p>
											</div>
										</div>
									</div>
								</div> */}
							</div>
						</div>
					</>
				}
			/>
		</>
	);
};

export default Benefits;
