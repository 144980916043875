import React, { useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons/';
import { useForm } from 'react-hook-form';
import Cookies from 'js-cookie';

import { validate, format } from 'rut.js';
import Seo from '../SEO';
import { login } from '../../actions/auth';
import { CodeResponse } from '../../utils/helpers';
import ReCAPTCHA from 'react-google-recaptcha';

export default function Login({ setLogin }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const { register, handleSubmit, errors } = useForm({
        mode: 'onBlur',
    });

    const captchaRef = useRef(null);
    const [captchaValid, changeCaptchaValid] = useState(null);
    const onChange = () => {
        if (captchaRef.current.getValue()) {
            console.log('El usuario no es un robot');
            changeCaptchaValid(true);
        }
    };

    const onSubmitLogin = async (data) => {
        if (Object.keys(data).length > 0) {
            if (captchaRef.current.getValue()) {
                console.log('El usuario no es un robot');

                changeCaptchaValid(true);
                const { dni = '', password = '' } = data;
                dispatch(
                    login({
                        username: dni,
                        password,
                    })
                );
            } else {
                console.log('Por favor acepta el captcha');

                changeCaptchaValid(false);
            }
        }
    };

    const auth = useSelector((state) => state.auth);
    const {
        err: errAuth = {},
        isFetching = true,
        data: dataLogin = {},
    } = auth || {};
    const { code = '' } = errAuth || {};

    if (Object.keys(dataLogin).length > 1) {
        const { token = '' } = dataLogin;
        if (!token) {
            history.push('/recordar-contrasena?require_reset=1');
        }
        if (token) {
            Cookies.set('token', token);
            history.push('/mi-cuenta');
            setLogin(true);
        }
    }

    return (
        <>
            <Seo
                title='Login'
                description='Inicia Sesión y conoce tus montos de compras y puntos de socio Cencosud'
                url='/login'
            />
            <section className='login'>
                <div className='login__bg'>
                    <img src='assets/img/login-bg.jpg' alt='Login' />
                </div>
                <div className='login__container container'>
                    <div className='login__form'>
                        <form
                            className='form login__form__content'
                            onSubmit={handleSubmit(onSubmitLogin)}
                        >
                            <h2 className='color--secundario section__title h1 text-upper text-wide'>
                                Iniciar sesión
                            </h2>
                            <p className='color--secundario section__subtitle'>
                                Escribe tus datos de seguridad para entrar a tu
                                sesión.
                            </p>
                            <div className='separador'></div>
                            <fieldset className='form__fieldset'>
                                <div className='form__grupo'>
                                    {errors.dni && (
                                        <span className='alerta alerta--error alerta--xsmall'>
                                            {errors.dni.message}
                                        </span>
                                    )}
                                    <input
                                        className='text'
                                        id='dni'
                                        name='dni'
                                        placeholder='11.111.111-1'
                                        maxLength='13'
                                        onKeyUp={(e) =>
                                            (e.target.value = format(
                                                e.target.value
                                            ))
                                        }
                                        ref={register({
                                            required: 'El campo es requerido',
                                            maxLength: {
                                                value: 12,
                                                message: 'El RUT es inválido',
                                            },
                                            validate: {
                                                validateDNI: (value) =>
                                                    validate(value) ||
                                                    'El RUT es inválido',
                                            },
                                        })}
                                    />
                                    <label htmlFor='rut'>RUT</label>
                                </div>
                                <div className='form__grupo'>
                                    {errors.password && (
                                        <span className='alerta alerta--error alerta--xsmall'>
                                            {errors.password.message}
                                        </span>
                                    )}
                                    <input
                                        className='text'
                                        id='password'
                                        type='password'
                                        name='password'
                                        placeholder='•••••••••'
                                        ref={register({
                                            required: 'El campo es requerido',
                                        })}
                                    />
                                    <label htmlFor='password'>Contraseña</label>
                                </div>
                                {isFetching && (
                                    <div className='preloader'>
                                        <div className='preloader__child'></div>
                                    </div>
                                )}
                                {((!isFetching &&
                                    Object.keys(dataLogin).length < 1) ||
                                    code) && (
                                    <>
                                        <ReCAPTCHA
                                            ref={captchaRef}
                                            sitekey={
                                                process.env.REACT_APP_SITE_KEY
                                            }
                                            onChange={onChange}
                                        />

                                        {captchaValid === false && (
                                            <div className='separador'>
                                                Por favor acepta el captcha
                                            </div>
                                        )}
                                        <div className='separador'></div>
                                        <button
                                            type='submit'
                                            className='btn btn--small btn--redondo btn--invertido btn--terciario btn--ancho'
                                        >
                                            Ingresar
                                        </button>
                                    </>
                                )}
                                {code && (
                                    <div className='alerta alerta--error'>
                                        {CodeResponse(code)}
                                    </div>
                                )}
                            </fieldset>
                        </form>
                        <footer className='login__footer'>
                            <div className='login__footer__item'>
                                <Link
                                    to='/recordar-contrasena'
                                    className='link-externo'
                                >
                                    He olvidado mi contraseña
                                </Link>
                            </div>
                            <div className='login__footer__item'>
                                <p>¿Aún no estás inscrito?</p>
                                <div className='separador--small'></div>
                                <Link
                                    to='/inscripcion'
                                    className={
                                        'btn btn--primario btn--redondo btn--small btn--ancho  btn--icono-izq'
                                    }
                                >
                                    <FontAwesomeIcon icon={faClipboardList} />
                                    Regístrate
                                </Link>
                            </div>
                        </footer>
                    </div>
                </div>
            </section>
        </>
    );
}
