import React from "react";

import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const Carousel = ({ items }) => {
  return (
    <Swiper
      spaceBetween={0}
      slidesPerView={1}
      // navigation
      pagination={{ clickable: true }}
      // scrollbar={{ draggable: true }}
      autoplay
      loop
      className={"players__carousel"}
    >
      {items.map(({ name, date }, index) => (
        <SwiperSlide key={index}>
          <div className="players__carousel__item">
            <h3 className="players__carousel__name text-wide text-upper h5 color--blanco">
              {name}
            </h3>
            <p className="players__carousel__date text-upper color--blanco">
              Jugador Experto Easy <span>Fecha {date}</span>
            </p>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;
