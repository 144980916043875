import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Redirect,
} from "react-router-dom";

// Components
import Footer from "./containers/Footer";
import Header from "./containers/Header";

// Pages
import Benefits from "./containers/Pages/Benefits";
import Bronce from "./containers/Pages/Bronce";
import ChangePassword from "./containers/Login/changePassword";
import Clients from "./containers/Pages/Clients";
import Error404 from "./containers/404";
import Expert from "./containers/Pages/Expert";
import ExpertPlayer from "./containers/ExpertPlayer";
import FAQ from "./containers/FAQs";
import Football from "./containers/Profile/FootBall";
import GuiaExperto from "./containers/Pages/GuiaExperto";
import Home from "./containers/Home";
import Login from "./containers/Login";
import Oro from "./containers/Pages/Oro";
import Plata from "./containers/Pages/Plata";
import Preferential from "./containers/Pages/Preferential";
import Privacy from "./containers/Pages/Privacy";
import Register from "./containers/Register";
import RegisterConfirm from "./containers/Register/confirm";
import RegisterSuccess from "./containers/Register/success";
import RememberPassword from "./containers/Login/rememberPassword";
import Stores from "./containers/Stores";
import Terms from "./containers/Pages/Terms";

// Profile
import Profile from "./containers/Profile";
import MyData from "./containers/Profile/myData";
// import { Topic as ProfileTopic} from "./containers/Profile/Topic";
// import { TopicItem as ProfileTopicItem} from "./containers/Profile/TopicItem";


// // Blog
// import Topic from "./containers/Topic";
// import TopicSingle from "./containers/Topic/single";


// Utilities
import Cookies from "js-cookie";

// Assets
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "./assets/scss/style.scss";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return null;
}

const ProtectedRoute = ({ children, ...rest }) => {
  const token = Cookies.get("token");
  return (
    <Route
      {...rest}
      children={(props) => {
        return token ? (
          children
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }}
    />
  );
};

function App() {
  const [pu, showPu] = useState(true);
  const [logged, setLogged] = useState(false);
  const [login, setLogin] = useState(false);
  useEffect(() => {
    const tokenExist = Cookies.get("token") ? true : false;
    setLogin(tokenExist);
    setLogged(login);
  }, [login]);

  const handleClick = (e) => {
    setLogin(!login);
    !login ? Cookies.set("token", true) : Cookies.remove("token");
  };

  const ROUTES = [
    {
      name: "Inicio",
      active: true,
      path: "/",
      exact: true,
      header: {
        layout: false,
      },
      main: "home",
      component: {
        name: Home,
        attrs: {
          pu,
          showPu,
        },
      },
      ScrollToTop: true,
    },
    {
      name: "Tipo de Socios",
      active: true,
      path: "/tipo-socios",
      exact: false,
      header: {
        layout: "pages",
      },
      component: {
        name: Clients,
      },
      ScrollToTop: true,
    },
    {
      name: "Beneficios",
      active: true,
      path: "/beneficios",
      exact: false,
      header: {
        layout: "pages",
      },
      component: {
        name: Benefits,
      },
      ScrollToTop: true,
    },
    {
      name: "Preguntas Frecuentes",
      active: true,
      path: "/preguntas-frecuentes",
      exact: false,
      header: {
        layout: "pages",
      },
      component: {
        name: FAQ,
      },
      ScrollToTop: true,
    },
    {
      name: "Tiendas",
      active: true,
      path: "/tiendas",
      exact: false,
      header: {
        layout: "pages",
      },
      component: {
        name: Stores,
      },
      ScrollToTop: true,
    },
    {
      name: "Jugador Experto",
      active: true,
      path: "/jugador-experto",
      exact: false,
      header: {
        layout: "pages",
      },
      component: {
        name: ExpertPlayer,
      },
      ScrollToTop: true,
    },
    {
      name: "Experto",
      active: true,
      path: "/experto",
      exact: false,
      header: {
        layout: "pages",
      },
      component: {
        name: Expert,
      },
      ScrollToTop: true,
    },
    {
      name: "Preferente",
      active: true,
      path: "/preferente",
      exact: false,
      header: {
        layout: "pages",
      },
      component: {
        name: Preferential,
      },
      ScrollToTop: true,
    },
    {
      name: "Bronce",
      active: true,
      path: "/bronce",
      exact: false,
      header: {
        layout: "pages",
      },
      component: {
        name: Bronce,
      },
      ScrollToTop: true,
    },
    {
      name: "Plata",
      active: true,
      path: "/plata",
      exact: false,
      header: {
        layout: "pages",
      },
      component: {
        name: Plata,
      },
      ScrollToTop: true,
    },
    {
      name: "Oro",
      active: true,
      path: "/oro",
      exact: false,
      header: {
        layout: "pages",
      },
      component: {
        name: Oro,
      },
      ScrollToTop: true,
    },
    {
      name: "Políticas de Privacidad",
      active: true,
      path: "/politicas-privacidad",
      exact: false,
      header: {
        layout: "pages",
      },
      component: {
        name: Privacy,
      },
      ScrollToTop: true,
    },
    {
      name: "Términos y Condiciones",
      active: true,
      path: "/terminos-condiciones",
      exact: false,
      header: {
        layout: "pages",
      },
      component: {
        name: Terms,
      },
      ScrollToTop: true,
    },
    {
      name: "Guía Experto",
      active: true,
      path: "/guia-experto",
      exact: false,
      header: {
        layout: "pages",
      },
      component: {
        name: GuiaExperto,
      },
      ScrollToTop: true,
    },
    {
      name: "Login",
      active: true,
      path: "/login",
      exact: false,
      header: {
        layout: "pages",
      },
      component: {
        name: Login,
      },
      ScrollToTop: true,
    },
    {
      name: "Recordar Contraseña / Código",
      active: true,
      path: "/recordar-contrasena/:code",
      exact: false,
      header: {
        layout: "pages",
      },
      component: {
        name: ChangePassword,
      },
      ScrollToTop: true,
    },
    {
      name: "Recordar Contraseña",
      active: true,
      path: "/recordar-contrasena",
      exact: false,
      header: {
        layout: "pages",
      },
      component: {
        name: RememberPassword,
      },
      ScrollToTop: true,
    },
    {
      name: "Inscripción / Confirmar",
      active: true,
      path: "/inscripcion/confirmar/:code",
      exact: false,
      header: {
        layout: "pages",
      },
      component: {
        name: RegisterConfirm,
      },
      ScrollToTop: true,
    },
    {
      name: "Inscripción / Éxito",
      active: true,
      path: "/inscripcion/exito",
      exact: false,
      header: {
        layout: "pages",
      },
      component: {
        name: RegisterSuccess,
      },
      ScrollToTop: true,
    },
    {
      name: "Inscripción",
      active: true,
      path: "/inscripcion",
      exact: false,
      header: {
        layout: "pages",
      },
      component: {
        name: Register,
      },
      ScrollToTop: true,
    },

    {
      name: "Mi cuenta",
      active: true,
      path: "/mi-cuenta",
      exact: true,
      protected: true,
      header: {
        layout: "dashboard",
      },
      main: "dashboard",
      component: {
        name: Profile,
      },
      ScrollToTop: true,
    },
    {
      name: "Mi cuenta / Mis datos",
      active: true,
      path: "/mi-cuenta/mis-datos",
      exact: false,
      protected: true,
      header: {
        layout: "dashboard",
      },
      main: "dashboard",
      component: {
        name: Profile,
        attrs: {
          component: MyData,
        },
      },
      ScrollToTop: true,
    },
    {
      name: "Mi cuenta / Futbol",
      active: true,
      path: "/mi-cuenta/futbol",
      exact: false,
      protected: true,
      header: {
        layout: "dashboard",
      },
      main: "dashboard",
      component: {
        name: Profile,
        attrs: {
          component: Football,
        },
      },
      ScrollToTop: true,
    },
    // {
    //   name: "Mi cuenta / Temas de Interés",
    //   active: true,
    //   path: "/mi-cuenta/temas-interes",
    //   exact: false,
    //   protected: true,
    //   header: {
    //     layout: "dashboard",
    //   },
    //   main: "dashboard",
    //   component: {
    //     name: Profile,
    //     attrs: {
    //       component: ProfileTopic,
    //     },
    //   },
    //   ScrollToTop: true,
    // },
    // {
    //   name: "Mi cuenta / Temas de Interés",
    //   active: true,
    //   path: "/mi-cuenta/temas-interes/:item",
    //   exact: false,
    //   protected: true,
    //   header: {
    //     layout: "dashboard",
    //   },
    //   main: "dashboard",
    //   component: {
    //     name: Profile,
    //     attrs: {
    //       component: ProfileTopicItem,
    //     },
    //   },
    //   ScrollToTop: true,
    // },
    // {
    //   name: "Temas de Interés",
    //   active: true,
    //   path: "/mi-cuenta/temas-interes/:item",
    //   exact: false,
    //   protected: true,
    //   header: {
    //     layout: "dashboard",
    //   },
    //   main: "dashboard",
    //   component: {
    //     name: Profile,
    //     attrs: {
    //       component: TopicSingle,
    //     },
    //   },
    //   ScrollToTop: true,
    // },
    // {
    //   name: "Temas de Interés",
    //   active: true,
    //   path: "/temas-interes/",
    //   exact: false,
    //   protected: false,
    //   header: {
    //     layout: "dashboard",
    //   },
    //   main: "dashboard",
    //   component: {
    //     name: Topic
    //   },
    //   ScrollToTop: true,
    // },
  ];
 
  return (
    <div className="App">
      <Router>
        <Switch>
          {ROUTES.filter((x) => x.active).map((x, key) => {
            let Component = x.component?.name;
            let Protected = x.protected ? ProtectedRoute : Route;
            return (
              <Protected exact={x.exact} path={x.path} key={key}>
                {x.ScrollToTop && <ScrollToTop />}
                <Header
                  cookie={logged}
                  setLogin={setLogin}
                  layout={x.header?.layout}
                />
                <main className={x.main}>
                  <Component
                    cookie={logged}
                    {...x?.component?.attrs}
                    setLogin={setLogin}
                  />
                </main>
                <Footer logged={logged} />
              </Protected>
            );
          })}
          <Route>
            <Header cookie={logged} setLogin={setLogin} layout="pages" />
            <main>
              <Error404 />
            </main>
            <Footer logged={logged} />
          </Route>
        </Switch>
      </Router>
      {process.env.NODE_ENV === "development" && (
        <span className="btn" onClick={handleClick}>
          {logged ? "Desloguear" : "Loguear"}
        </span>
      )}
    </div>
  );
}

export default App;
