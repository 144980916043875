import React from 'react';
import { NavLink } from 'react-router-dom';
/*
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faTwitter,
  faInstagram,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons/";
*/
// import PopUp from "../../components/Popup";
import FixedBtns from './FixedBtns';
import pages from '../../assets/json/pages.json';

// import logo from "../../assets/logo.svg";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faCheckCircle,
//   faClipboardList,
//   faList,
// } from "@fortawesome/free-solid-svg-icons";

const Footer = (props) => {
    return (
        <footer className='footer'>
            <div className='footer__container container'>
                {pages.length && (
                    <>
                        <nav className='footer__menu'>
                            <ul className='footer__menu__list'>
                                {pages
                                    .filter((x) => x.menu === 'secondary')
                                    .map((x) => (
                                        <li
                                            className='footer__menu__item'
                                            key={x.slug}
                                        >
                                            <NavLink
                                                to={x.path}
                                                className='footer__menu__link'
                                                activeClassName='active'
                                                exact={x.exact}
                                            >
                                                {x.title}
                                            </NavLink>
                                        </li>
                                    ))}
                                <li className='footer__menu__item'>
                                    <a
                                        href='https://www.easy.cl/'
                                        className='footer__menu__link'
                                    >
                                        Easy.cl
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </>
                )}
                <p className='footer__copy'>
                    &copy; Todos los derechos reservados
                </p>
                <p className='footer__copy'>
                    Escríbenos a:{' '}
                    <a href='mailto:info.mundoexperto@easy.cl'>
                        info.mundoexperto@easy.cl
                    </a>
                </p>
            </div>
            <FixedBtns logged={props.logged} />
        </footer>
    );
};

export default Footer;
