import {
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_ERROR,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  UPDATE_AVATAR_REQUEST,
  UPDATE_AVATAR_SUCCESS,
  UPDATE_AVATAR_ERROR,
} from '../types/auth';

const initialState = {
  err: {},
  isFetching: false,
  data: {},
};

const profileReducer = (state = initialState, action = {}) => {
  const { type = '', payload = {} } = action;
  switch (type) {
    case UPDATE_AVATAR_REQUEST:
    case GET_PROFILE_REQUEST:
    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        isFetching: true,
        err: null,
      };
    case GET_PROFILE_SUCCESS:
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: payload,
        err: null,
      };
    case UPDATE_AVATAR_SUCCESS:
      const { data: userDataNew = [] } = payload;
      const { data: avatarURL = '' } = Array.isArray(userDataNew) ? userDataNew.find(({ type = '' }) => type === 'avatar') : {};
      const { data: { data: userData = [] }  } = state;
      const foundIndex = Array.isArray(userData) ? userData.findIndex(({ type = '' }) => type === 'avatar') : null;
      if (foundIndex && avatarURL !== '') {
        userData[foundIndex].data = avatarURL;
        state.data.data = userData;
      }
      return {
        ...state,
        isFetching: false,
        data: state.data,
        err: null,
      };
    case UPDATE_AVATAR_ERROR:
    case GET_PROFILE_ERROR:
    case UPDATE_PROFILE_ERROR:
      const { errors = '' } = payload;
      return {
        ...state,
        isFetching: false,
        err: errors,
      };
    default: {
      return state;
    }
  }
};

export default profileReducer;
