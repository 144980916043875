import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/";

import { isValidEmail, CodeResponse } from "../../utils/helpers";

const { rememberPassword } = require("../../actions/auth");

export default function RememberPassword(props) {
  const dispatch = useDispatch();
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const requireResetPass = params.get('require_reset')
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
  });
  const [remember, setRememberPassword] = useState({});

  const onSubmitLogin = (data) => {
    setRememberPassword(data);
  };

  useEffect(() => {
    const { email = "" } = remember;
    if (email !== "") {
      dispatch(
        rememberPassword({
          email,
        })
      );
    }
  }, [dispatch, remember]);

  const rememberState = useSelector(
    ({ rememberPassword = {} }) => rememberPassword
  );
  const { isFetching = true, err = {}, data: dataRemember = false } =
    rememberState || {};
  const { code: codeErr = "" } = err || {};

  const cancelChangePassword = () => {
    dispatch({
      type: "POST_LOGIN_LOGOUT",
    });
  }

  return (
    <>
      <section className="login">
        <div className="login__bg">
          <img
            src="assets/img/login-bg.jpg"
            alt="Login"
          />
        </div>
        <div className="login__container container">
          <div className="login__form">
            <form
              className="form login__form__content"
              onSubmit={handleSubmit(onSubmitLogin)}
            >
              <h2 className="color--secundario section__title h1 text-upper text-wide">
                {!requireResetPass && "Recordar contraseña"}
                {requireResetPass && "Reestablecer contraseña"}
              </h2>
              <p className="color--secundario section__subtitle">
                {!requireResetPass &&
                  "Escribe tu email para recordar la contraseña"}
                {requireResetPass &&
                  "Se requiere cambio de contraseña, ingrese su email registrado al rut asociado"}
              </p>
              <div className="separador"></div>
              <fieldset className="form__fieldset">
                <div className="form__grupo">
                  {errors.email && (
                    <span className="alerta alerta--error alerta--xsmall">
                      {errors.email.message}
                    </span>
                  )}
                  <input
                    className="text"
                    id="email"
                    name="email"
                    placeholder="example@email.com"
                    ref={register({
                      required: "El campo es requerido",
                      validate: {
                        validateEmail: (value) =>
                          isValidEmail(value) || "El email es inválido",
                      },
                    })}
                  />
                  <label htmlFor="rut">Email</label>
                </div>
                {codeErr && <div>{CodeResponse(codeErr)}</div>}
                {isFetching && (
                  <div className="preloader">
                    <div className="preloader__child"></div>
                  </div>
                )}
                {dataRemember && (
                  <span className="alerta alerta--success alerta--xsmall">
                    Se ha enviado un email con las instrucciones para recuperar
                    su contraseña
                  </span>
                )}
                {((!isFetching && !dataRemember) || codeErr) && (
                  <button
                    type="submit"
                    className="btn btn--small btn--redondo btn--invertido btn--terciario btn--ancho"
                  >
                    Recordar
                  </button>
                )}
              </fieldset>
            </form>
            <footer className="login__footer">
              <div className="login__footer__item">
                <div className="separador--small"></div>
                {!requireResetPass && (
                  <Link
                    to="/login"
                    className={
                      "btn btn--primario btn--redondo btn--small btn--ancho btn--icono btn--icono-izq"
                    }
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                    Volver
                  </Link>
                )}
                {requireResetPass && (
                  <Link
                    onClick={cancelChangePassword}
                    to=""
                    className={
                      "btn btn--primario btn--redondo btn--small btn--ancho btn--icono btn--icono-izq"
                    }
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                    Cancelar
                  </Link>
                )}
              </div>
            </footer>
          </div>
        </div>
      </section>
    </>
  );
}
