import React, { useState } from "react";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "./Card";

const Content = ({ items, dateNow }) => {
  const _dates = [...new Set(items.map((x) => x.date))];
  const [date, setDate] = useState(dateNow);
  const [dates, showDates] = useState(false);
  return (
    <>
      <svg
        className="svg"
        style={{
          width: 0,
          height: 0,
        }}
      >
        <clipPath id="player" clipPathUnits="objectBoundingBox">
          <path d="M0,0.061 C0.202,0.123,0.269,0,0.5,0 c0.248,0,0.303,0.123,0.5,0.061 v0.658 c0,0.026,-0.018,0.051,-0.05,0.065 l-0.451,0.216 L0.05,0.784 c-0.031,-0.015,-0.05,-0.039,-0.05,-0.065 V0.061"></path>
        </clipPath>
      </svg>
      <div className="container">
        <h2 className="players__title h3 text-wide text-upper">
          Jugadores Experto Anteriores
        </h2>
        <div className="players__separador"></div>
        <div className="players__select" onClick={() => showDates(!dates)}>
          <h5 className="players__subtitle text-wide text-upper">
            Selecciona una fecha
          </h5>
          <div className="players__select__input text-wide text-upper">
            <span className="players__select__label">Fecha {date}</span>
            <span className="players__select__arrow">
              <FontAwesomeIcon icon={dates ? faChevronUp : faChevronDown} />
            </span>
          </div>
        </div>
        <div
          className={`players__dates players__dates--${
            dates ? "visible" : "hidden"
          }`}
        >
          {_dates.map((x, key) => (
            <div
              className={`players__dates__item text-wide ${
                date === x ? "players__dates__item--active" : ""
              }`}
              key={key}
              onClick={() => setDate(x)}
            >
              {x}
            </div>
          ))}
        </div>
        <div className="players__grid">
          {items
            .filter((x) => x.date === date)
            .map(({ name, avatar, team, logoCampeonato }, key) => (
              <Card
                key={key}
                avatar={`${avatar}?random=${key}`}
                team={team}
                name={name}
                logoCampeonato={logoCampeonato}
                title="Jugador Experto Easy"
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default Content;
