import {
  POST_REMEMBER_PASSWORD_REQUEST,
  POST_REMEMBER_PASSWORD_SUCCESS,
  POST_REMEMBER_PASSWORD_ERROR,
  POST_CHANGE_PASSWORD_REQUEST,
  POST_CHANGE_PASSWORD_SUCCESS,
  POST_CHANGE_PASSWORD_ERROR,
} from '../types/auth';

const initialState = {
  err: {},
  isFetching: false,
  data: false,
};

const rememberReducer = (state = initialState, action = {}) => {
  const { type = '', payload = {} } = action;
  switch (type) {
    case POST_REMEMBER_PASSWORD_REQUEST:
      case POST_CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
        err: null,
      };
    case POST_REMEMBER_PASSWORD_SUCCESS:
    case POST_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: true,
        err: null,
      };
    case POST_REMEMBER_PASSWORD_ERROR:
    case POST_CHANGE_PASSWORD_ERROR:
      const { errors = '' } = payload;
      return {
        ...state,
        isFetching: false,
        err: errors,
      };
    default: {
      return state;
    }
  }
};

export default rememberReducer;
