import React from "react";
import Banner from "../Banner";
import Breadcrumbs from "../Breadcrumbs";

const Page = ({
  title,
  bg = "assets/img/temas-interes-bg.jpg",
  breadcrumbs,
  content,
  banner,
}) => {
  return (
    <div className="page">
      {banner && (
        <>
          <Banner title={title} bg={bg} breadcrumbs={breadcrumbs} />
          <div className="separador"></div>
        </>
      )}
      {!banner && (
        <div className="container">
          <h1 className="text-wide text-upper">{title}</h1>
          {breadcrumbs && <Breadcrumbs crumbs={breadcrumbs} />}
          <div className="separador"></div>
        </div>
      )}
      <div className="container">
        <div className="content">{content}</div>
        <div className="separador--big"></div>
      </div>
    </div>
  );
};

export default Page;
