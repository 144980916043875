import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Redirect, useParams } from "react-router";

import { CodeResponse } from "../../utils/helpers";

const { changePassword } = require("../../actions/auth");

export default function ChangePassword() {
  const { code = "" } = useParams();
  const dispatch = useDispatch();
  const { register, handleSubmit, getValues, errors } = useForm({
    mode: "onBlur",
  });
  const [stateChangePassword, setChangePassword] = useState({});

  const onSubmitChangePassword = (data) => {
    setChangePassword(data);
  };

  useEffect(() => {
    const { repeat_password: password = "" } = stateChangePassword;
    if (password !== "" && code !== "") {
      dispatch(
        changePassword({
          password,
          code,
        })
      );
    }
  }, [dispatch, stateChangePassword, code]);

  const rememberState = useSelector(
    ({ rememberPassword = {} }) => rememberPassword
  );
  const { isFetching = true, err = {}, data: dataRemember = false } =
    rememberState || {};
  const { code: codeErr = "" } = err || {};

  return (
    <>
      <section className="login">
        <div className="login__bg">
          <img
            src="assets/img/login-bg.jpg"
            alt="Cambiar Contraseña"
          />
        </div>
        <div className="login__container container">
          <div className="login__form">
            <form
              className="form login__form__content"
              onSubmit={handleSubmit(onSubmitChangePassword)}
            >
              <h2 className="color--secundario section__title h1 text-upper text-wide">
                Cambiar contraseña
              </h2>
              <p className="color--secundario section__subtitle">
                Escribe tu nueva contraseña
              </p>
              <div className="separador"></div>
              <fieldset className="form__fieldset">
                <div className="form__grupo">
                  {errors.password && (
                    <span className="color--error">
                      {errors.password.message}
                    </span>
                  )}
                  <input
                    className="text"
                    id="password"
                    type="password"
                    name="password"
                    placeholder="•••••••••"
                    ref={register({
                      required: "El campo es requerido",
                    })}
                  />
                  <label htmlFor="password">Contraseña</label>
                </div>
                <div className="form__grupo">
                  {errors.repeat_password && (
                    <span className="color--error">
                      {errors.repeat_password.message}
                    </span>
                  )}
                  <input
                    className="text"
                    id="repeat_password"
                    type="password"
                    name="repeat_password"
                    placeholder="•••••••••"
                    ref={register({
                      required: "El campo es requerido",
                      validate: {
                        matchesPreviousPassword: (value) => {
                          const { password } = getValues();
                          return (
                            password === value ||
                            "Las contraseñas ingresadas no coinciden"
                          );
                        },
                      },
                    })}
                  />
                  <label htmlFor="repeat_password">Repetir Contraseña</label>
                </div>
                {codeErr && <div>{CodeResponse(codeErr)}</div>}
                {isFetching && (
                  <div className="preloader">
                    <div className="preloader__child"></div>
                  </div>
                )}
                {dataRemember && <Redirect to="/login" />}
                {((!isFetching && !dataRemember) || codeErr) && (
                  <button
                    type="submit"
                    className="btn btn--small btn--redondo btn--invertido btn--terciario btn--ancho"
                  >
                    Cambiar contraseña
                  </button>
                )}
              </fieldset>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
