import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { updateProfile } from '../../actions/auth';

import Categorias from '../../assets/json/categorias.json';
import CategoryIcons from '../../components/Category/Icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/';

import {
    isValidDate,
    // isValidPhone,
    // isValidCellPhone,
    isValidEmail,
    getUserDateType,
} from '../../utils/helpers';
import ComunasRegiones from '../../assets/json/comunas-regiones.json';
import Breadcrumbs from '../../components/Breadcrumbs';
import { split } from 'lodash';

const preferredStoreArray = [
    'Easy Kennedy',
    'Easy Maipu',
    'Easy Easy Puerto Montt',
    'Easy Viña Del Mar',
    'Easy La Florida',
    'Easy Costanera Center',
    'Easy La Reina',
    'Easy El Llano',
    'Easy La Dehesa',
    'Easy Temuco',
    'Easy Quilin',
    'Easy Valparaiso',
    'Easy La Serena',
    'Easy Cerrillos',
    'Easy Linares',
    'Easy Chillan',
    'Easy Los Angeles',
    'Easy Antofagasta',
    'Easy Osorno',
    'Easy Talca',
    'Easy Curico',
    'Easy Calama',
    'Easy Portal Bio Bio Ex Hualpen',
    'Easy Ochagavia',
    'Easy Quillota',
    'Easy Quilicura',
    'Easy Pte Alto',
    'Easy Copiapo',
    'Easy El Belloto',
    'Easy San Bernardo',
    'Easy Portal Los Andes',
    'Easy Coronel',
    'Easy Chicureo',
    'Easy Chiguyante',
    'Easy Puerto Madero',
    'Easy Portal Temuco',
    'Easy Portal Ñuñoa',
    'Easy Portal Osorno',
    'Easy Santa Amalia',
    'Easy Arica',
];

/*
const equipos = [
    'Colo-Colo',
    'Universidad de Chile',
    'Universidad Católica',
    'Cobreloa',
    'Unión Española',
    'Everton',
    'Magallanes',
    'Audax Italiano',
    'Santiago Wanderers',
    'Palestino',
    'Huachipato',
    'Deportes Temuco',
    'Santiago Morning',
    'Unión San Felipe',
    'Cobresal',
    "O'Higgins",
    'San Luis',
    'San Marcos',
    'Deportes Iquique',
    'Deportes La Serena',
    'Unión La Calera',
    'Rangers',
    'Provincial Osorno',
    'Coquimbo Unido',
    'Ñublense',
    'Lota Schwager',
    'Curicó Unido',
    'Deportes Concepción',
    'Deportes Melipilla',
    'Deportes Antofagasta',
    'Deportes Puerto Montt',
    'Universidad de Concepción',
    'Arturo Fernández Vial',
    'Trasandino',
    'Thomas Bata',
    "O'Higgins Braden",
    'Ferrobádminton',
    'Naval de Talcahuano',
    'Aviación',
    'Iberia',
    'Deportes Santa Cruz',
    'Barnechea',
    'Deportes Valdivia',
    'Deportes Vallenar',
    'Luis Cruz Martínez',
].sort(function (a, b) {
    return a.localeCompare(b);
});
*/

export default function MyData() {
    const dispatch = useDispatch();
    const { register, handleSubmit, errors, getValues } = useForm({
        mode: 'onBlur',
    });

    const profile = useSelector((state) => state.profile);
    const { data = {}, isFetching = true } = profile || {};
    const {
        firstname = '',
        lastname = '',
        email = '',
        data: userData = [],
    } = data || {};

    const birthDate = getUserDateType(userData, 'birthdate');
    const genre = getUserDateType(userData, 'genre');
    const maritalstatus = getUserDateType(userData, 'maritalstatus');
    const regionValue = getUserDateType(userData, 'region');
    const districtValue = getUserDateType(userData, 'district');
    const address = getUserDateType(userData, 'address');
    // const phone = getUserDateType(userData, 'phone');
    const mobile = getUserDateType(userData, 'mobile');
    const specialty = getUserDateType(userData, 'specialty');
    const purchaseFrequency = getUserDateType(userData, 'purchaseFrequency');
    // const futbolteam = getUserDateType(userData, "futbolteam");
    const preferredStore = getUserDateType(userData, 'preferredStore');
    const addressNumber = getUserDateType(userData, 'addressNumber');
    const addressType = getUserDateType(userData, 'addressType');
    const avatar = getUserDateType(userData, 'avatar');
    const categories = split(getUserDateType(userData, 'categories'), ',');

    const onSubmitUpdate = (data) => {
        const {
            firstname = '',
            lastname = '',
            email = '',
            birthdate = '',
            address = '',
            district = '',
            // futbolteam = '',
            preferredStore = '',
            genre = '',
            maritalstatus = '',
            // phone = '',
            mobile = '',
            region = '',
            specialty = '',
            purchaseFrequency = '',
            addressNumber = '',
            addressType = '',
            password = '',
            categories = [],
        } = data || {};
        const payload = {
            firstname,
            lastname,
            email,
            password,
            data: [],
        };
        if (birthdate)
            payload.data.push({ type: 'birthdate', data: birthdate });
        if (address) payload.data.push({ type: 'address', data: address });
        if (district) payload.data.push({ type: 'district', data: district });
        // if (futbolteam) payload.data.push({ type: "futbolteam", data: futbolteam });
        if (preferredStore)
            payload.data.push({ type: 'preferredStore', data: preferredStore });
        if (genre) payload.data.push({ type: 'genre', data: genre });
        if (maritalstatus)
            payload.data.push({ type: 'maritalstatus', data: maritalstatus });
        // if (phone) payload.data.push({ type: 'phone', data: phone });
        if (mobile) payload.data.push({ type: 'mobile', data: mobile });
        if (region) payload.data.push({ type: 'region', data: region });
        if (specialty)
            payload.data.push({ type: 'specialty', data: specialty });
        if (purchaseFrequency)
            payload.data.push({
                type: 'purchaseFrequency',
                data: purchaseFrequency,
            });
        if (addressNumber)
            payload.data.push({ type: 'addressNumber', data: addressNumber });
        if (addressType)
            payload.data.push({ type: 'addressType', data: addressType });
        if (avatar) payload.data.push({ type: 'avatar', data: avatar });

        if (Array.isArray(categories) && categories.length > 0) {
            payload.data.push({
                type: 'categories',
                data: categories.join(',') || '',
            });
        }

        dispatch(updateProfile(payload));
    };

    const [region, setRegion] = useState('');
    const [district, setDistrict] = useState([]);
    const handleComunas = (e) => {
        const rellenar = () => {
            const { regiones = [] } = ComunasRegiones || {};
            setRegion(e.target.value);
            setDistrict(regiones.filter((x) => x.region === e.target.value));
        };
        const limpiar = () => {
            setRegion('');
            setDistrict([]);
        };
        e.target.value !== '0' ? rellenar() : limpiar();
    };

    useEffect(() => {
        const { regiones = [] } = ComunasRegiones || {};
        setRegion(regionValue);
        setDistrict(regiones.filter((x) => x.region === regionValue));
    }, [regionValue]);

    const atLeastOneAndLessThanThree = () => {
        const values = getValues('categories');
        if (values.length === 0)
            return 'Se requiere al menos una categoría de interés';
        if (values.length > 2)
            return 'Solo se pueden seleccionar hasta 2 categorías';
        return true;
    };

    return (
        <>
            <Breadcrumbs
                crumbs={[
                    {
                        type: 'link',
                        path: '/mi-cuenta',
                        label: 'Mi cuenta',
                    },
                    { type: 'separator' },
                    { type: 'current', label: 'Mis Datos' },
                ]}
            />
            <h1 className='h3'>Mis Datos</h1>
            {isFetching && (
                <div className='preloader__container'>
                    <div className='preloader'>
                        <div className='preloader__child'></div>
                    </div>
                </div>
            )}
            {!isFetching && (
                <form className='form' onSubmit={handleSubmit(onSubmitUpdate)}>
                    <fieldset className='form__fieldset'>
                        <div className='row'>
                            <div className='col-xs-12'>
                                <h3 className='text-wide text-upper h5'>
                                    Datos personales
                                </h3>
                                <div className='separador'></div>
                            </div>
                            <div className='col-xs-12 col-md-6 col-lg-4'>
                                <div className='form__grupo'>
                                    {errors.firstname && (
                                        <span className='alerta alerta--error alerta--xsmall'>
                                            {errors.firstname.message}
                                        </span>
                                    )}
                                    <input
                                        className='text'
                                        id='firstname'
                                        name='firstname'
                                        placeholder='Juan'
                                        defaultValue={firstname}
                                        ref={register({
                                            required: 'El campo es requerido',
                                        })}
                                    />
                                    <label htmlFor='firstname'>Nombre</label>
                                </div>
                            </div>
                            <div className='col-xs-12 col-md-6 col-lg-4'>
                                <div className='form__grupo'>
                                    {errors.lastname && (
                                        <span className='alerta alerta--error alerta--xsmall'>
                                            {errors.lastname.message}
                                        </span>
                                    )}
                                    <input
                                        className='text'
                                        id='lastname'
                                        name='lastname'
                                        defaultValue={lastname}
                                        placeholder='Sepúlveda'
                                        ref={register({
                                            required: 'El campo es requerido',
                                        })}
                                    />
                                    <label htmlFor='lastname'>Apellidos</label>
                                </div>
                            </div>
                            <div className='col-xs-12 col-md-6 col-lg-4'>
                                <div className='form__grupo'>
                                    {errors.birthdate && (
                                        <span className='alerta alerta--error alerta--xsmall'>
                                            {errors.birthdate.message}
                                        </span>
                                    )}
                                    <InputMask
                                        className='text'
                                        id='birthdate'
                                        name='birthdate'
                                        mask='99/99/9999'
                                        placeholder='14/05/1980'
                                        defaultValue={birthDate}
                                        ref={register({
                                            required: 'El campo es requerido',
                                            validate: {
                                                validateDate: (value) =>
                                                    isValidDate(value) ||
                                                    'La fecha es inválida',
                                            },
                                        })}
                                    />
                                    <label htmlFor='birthdate'>
                                        Fecha nacimiento
                                    </label>
                                </div>
                            </div>
                            <div className='col-xs-12 col-md-6 col-lg-4'>
                                <div className='form__grupo'>
                                    {errors.genre && (
                                        <span className='alerta alerta--error alerta--xsmall'>
                                            {errors.genre.message}
                                        </span>
                                    )}
                                    <div className='icono'>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </div>
                                    <select
                                        name='genre'
                                        id='genre'
                                        ref={register({
                                            required: 'El campo es requerido',
                                        })}
                                    >
                                        <option value='' hidden>
                                            Seleccionar
                                        </option>
                                        <option
                                            value='hombre'
                                            selected={genre === 'hombre'}
                                        >
                                            Hombre
                                        </option>
                                        <option
                                            value='mujer'
                                            selected={genre === 'mujer'}
                                        >
                                            Mujer
                                        </option>
                                        <option
                                            value='no'
                                            selected={genre === 'no'}
                                        >
                                            Prefiero no decirlo
                                        </option>
                                    </select>
                                    <label htmlFor='genre'>Sexo</label>
                                </div>
                            </div>
                            <div className='col-xs-12 col-md-6 col-lg-4'>
                                <div className='form__grupo'>
                                    {errors.maritalstatus && (
                                        <span className='alerta alerta--error alerta--xsmall'>
                                            {errors.maritalstatus.message}
                                        </span>
                                    )}
                                    <div className='icono'>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </div>
                                    <select
                                        name='maritalstatus'
                                        id='maritalstatus'
                                        ref={register({
                                            required: 'El campo es requerido',
                                        })}
                                    >
                                        <option value='' hidden>
                                            Seleccionar
                                        </option>
                                        <option
                                            value='soltero'
                                            selected={
                                                maritalstatus === 'soltero'
                                            }
                                        >
                                            Soltero/a
                                        </option>
                                        <option
                                            value='casado'
                                            selected={
                                                maritalstatus === 'casado'
                                            }
                                        >
                                            Casado/a
                                        </option>
                                        <option
                                            value='divorciado'
                                            selected={
                                                maritalstatus === 'divorciado'
                                            }
                                        >
                                            Divorciado/a
                                        </option>
                                        <option
                                            value='viudo'
                                            selected={maritalstatus === 'viudo'}
                                        >
                                            Viudo/a
                                        </option>
                                    </select>
                                    <label htmlFor='maritalstatus'>
                                        Estado Civil
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-xs-12'>
                                <div className='separador'></div>
                                <h3 className='text-wide text-upper h5'>
                                    Datos de ubicación
                                </h3>
                                <div className='separador'></div>
                            </div>
                            <div className='col-xs-12 col-md-6 col-lg-4'>
                                <div className='form__grupo'>
                                    {errors.region && (
                                        <span className='alerta alerta--error alerta--xsmall'>
                                            {errors.region.message}
                                        </span>
                                    )}
                                    <div className='icono'>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </div>
                                    <select
                                        name='region'
                                        id='region'
                                        onChange={handleComunas}
                                        ref={register({
                                            required: 'El campo es requerido',
                                        })}
                                    >
                                        <option value=''>Seleccionar</option>
                                        {ComunasRegiones.regiones.map(
                                            (x, key) => (
                                                <option
                                                    key={key}
                                                    value={x.region}
                                                    selected={
                                                        regionValue === x.region
                                                    }
                                                >
                                                    {x.region}
                                                </option>
                                            )
                                        )}
                                    </select>
                                    <label htmlFor='region'>Región</label>
                                </div>
                            </div>
                            <div className='col-xs-12 col-md-6 col-lg-4'>
                                <div className='form__grupo'>
                                    {errors.district && (
                                        <span className='alerta alerta--error alerta--xsmall'>
                                            {errors.district.message}
                                        </span>
                                    )}
                                    <div className='icono'>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </div>
                                    <select
                                        name='district'
                                        id='district'
                                        disabled={region ? '' : 'disabled'}
                                        ref={register({
                                            required: 'El campo es requerido',
                                        })}
                                    >
                                        <option value=''>Seleccionar</option>
                                        {district[0] &&
                                            district[0].comunas.map(
                                                (x, key) => (
                                                    <option
                                                        value={x}
                                                        key={key}
                                                        selected={
                                                            districtValue === x
                                                        }
                                                    >
                                                        {x}
                                                    </option>
                                                )
                                            )}
                                    </select>
                                    <label htmlFor='district'>Comuna</label>
                                </div>
                            </div>
                            {/* <div className="col-xs-12 col-md-6 col-lg-4">
                <div className="form__grupo">
                  {errors.address && (
                    <span className="alerta alerta--error alerta--xsmall">
                      {errors.address.message}
                    </span>
                  )}
                  <input
                    className="text"
                    id="address"
                    name="address"
                    type="text"
                    defaultValue={address}
                    ref={register({
                      required: "El campo es requerido",
                    })}
                  />
                  <label htmlFor="address">Dirección</label>
                </div>
              </div> */}
                            <div className='col-xs-12 col-md-6 col-lg-4'>
                                <div className='form__grupo'>
                                    {errors.address && (
                                        <span className='alerta alerta--error alerta--xsmall'>
                                            {errors.address.message}
                                        </span>
                                    )}
                                    <input
                                        className='text'
                                        id='address'
                                        name='address'
                                        type='text'
                                        defaultValue={address}
                                        ref={register({
                                            required: 'El campo es requerido',
                                        })}
                                    />
                                    <label htmlFor='address'>Calle</label>
                                </div>
                            </div>
                            <div className='col-xs-12 col-md-6 col-lg-4'>
                                <div className='form__grupo'>
                                    {errors.addressNumber && (
                                        <span className='alerta alerta--error alerta--xsmall'>
                                            {errors.addressNumber.message}
                                        </span>
                                    )}
                                    <input
                                        className='text'
                                        id='addressNumber'
                                        name='addressNumber'
                                        type='text'
                                        defaultValue={addressNumber}
                                        ref={register({
                                            required: 'El campo es requerido',
                                        })}
                                    />
                                    <label htmlFor='addressNumber'>
                                        Número
                                    </label>
                                </div>
                            </div>
                            <div className='col-xs-12 col-md-6 col-lg-4'>
                                <div className='form__grupo'>
                                    {errors.addressType && (
                                        <span className='alerta alerta--error alerta--xsmall'>
                                            {errors.addressType.message}
                                        </span>
                                    )}
                                    <input
                                        type='text'
                                        className='text'
                                        id='addressType'
                                        name='addressType'
                                        defaultValue={addressType}
                                        ref={register({
                                            required: 'El campo es requerido',
                                        })}
                                    />
                                    <label htmlFor='addressType'>
                                        Dpto / Casa / Oficina
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-xs-12'>
                                <div className='separador'></div>
                                <h3 className='text-wide text-upper h5'>
                                    Datos de contacto
                                </h3>
                                <div className='separador'></div>
                            </div>
                            <div className='col-xs-12 col-md-6 col-lg-4'>
                                <div className='form__grupo'>
                                    <InputMask
                                        className='text'
                                        id='mobile'
                                        name='mobile'
                                        placeholder='+56....'
                                        mask='+56999999999'
                                        type='phone'
                                        defaultValue={mobile}
                                    />
                                    <label htmlFor='telefono'>Teléfono</label>
                                </div>
                            </div>
                            {/* <div className='col-xs-12 col-md-6 col-lg-4'>
                                <div className='form__grupo'>
                                    {errors.mobile && (
                                        <span className='alerta alerta--error alerta--xsmall'>
                                            {errors.mobile.message}
                                        </span>
                                    )}
                                    <InputMask
                                        className='text'
                                        id='mobile'
                                        name='mobile'
                                        placeholder='+569...'
                                        mask='+56\9********'
                                        type='phone'
                                        defaultValue={mobile}
                                        ref={register({
                                            required: 'El campo es requerido',
                                            validate: {
                                                validateMobile: (value) =>
                                                    isValidCellPhone(value) ||
                                                    'El teléfono es inválido',
                                            },
                                        })}
                                    />
                                    <label htmlFor='mobile'>Celular</label>
                                </div>
                            </div> */}

                            <div className='col-xs-12 col-md-6 col-lg-4'>
                                <div className='form__grupo'>
                                    {errors.email && (
                                        <span className='alerta alerta--error alerta--xsmall'>
                                            {errors.email.message}
                                        </span>
                                    )}
                                    <input
                                        className='text'
                                        id='email'
                                        type='email'
                                        name='email'
                                        defaultValue={email}
                                        placeholder='nombre@tudominio.com'
                                        ref={register({
                                            required: 'El campo es requerido',
                                            validate: {
                                                validateEmail: (value) =>
                                                    isValidEmail(value) ||
                                                    'El email es inválido',
                                            },
                                        })}
                                    />
                                    <label htmlFor='email'>Email</label>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-xs-12'>
                                <div className='separador'></div>
                                <h3 className='text-wide text-upper h5'>
                                    Modificar contraseña
                                </h3>
                                <div className='separador'></div>
                            </div>
                            <div className='col-xs-12 col-md-6 col-lg-4'>
                                <div className='form__grupo'>
                                    {errors.password && (
                                        <span className='alerta alerta--error alerta--xsmall'>
                                            {errors.password.message}
                                        </span>
                                    )}
                                    <input
                                        className='text'
                                        type='password'
                                        id='password'
                                        name='password'
                                        placeholder='****'
                                        ref={register}
                                    />
                                    <label htmlFor='password'>Contraseña</label>
                                </div>
                            </div>
                            <div className='col-xs-12 col-md-6 col-lg-4'>
                                <div className='form__grupo'>
                                    {errors.repeat_password && (
                                        <span className='alerta alerta--error alerta--xsmall'>
                                            {errors.repeat_password.message}
                                        </span>
                                    )}
                                    <input
                                        className='text'
                                        type='password'
                                        id='repeat_password'
                                        name='repeat_password'
                                        placeholder='****'
                                        ref={register({
                                            validate: {
                                                matchesPreviousPassword: (
                                                    value
                                                ) => {
                                                    const { password } =
                                                        getValues();
                                                    if (
                                                        password !== '' &&
                                                        password !== value
                                                    ) {
                                                        return 'Las contraseñas ingresadas no coinciden';
                                                    }
                                                    return null;
                                                },
                                            },
                                        })}
                                    />
                                    <label htmlFor='repeat_password'>
                                        Repetir Contraseña
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-xs-12'>
                                <div className='separador'></div>
                                <h3 className='text-wide text-upper h5'>
                                    Datos generales
                                </h3>
                                <div className='separador'></div>
                            </div>
                            <div className='col-xs-12 col-md-6 col-lg-4'>
                                <div className='form__grupo'>
                                    {errors.specialty && (
                                        <span className='alerta alerta--error alerta--xsmall'>
                                            {errors.specialty.message}
                                        </span>
                                    )}
                                    <div className='icono'>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </div>
                                    <select
                                        name='specialty'
                                        id='specialty'
                                        ref={register({
                                            required: 'El campo es requerido',
                                        })}
                                    >
                                        <option value='' hidden>
                                            Seleccionar
                                        </option>
                                        <option
                                            value='pintor'
                                            selected={specialty === 'pintor'}
                                        >
                                            Pintor
                                        </option>
                                        <option
                                            value='constructor'
                                            selected={
                                                specialty === 'constructor'
                                            }
                                        >
                                            Constructor
                                        </option>
                                        <option
                                            value='ingeniero'
                                            selected={specialty === 'ingeniero'}
                                        >
                                            Ingeniero
                                        </option>
                                        <option
                                            value='arquitecto'
                                            selected={
                                                specialty === 'arquitecto'
                                            }
                                        >
                                            Arquitecto
                                        </option>
                                        <option
                                            value='paisajista'
                                            selected={
                                                specialty === 'paisajista'
                                            }
                                        >
                                            Paisajista
                                        </option>
                                        <option
                                            value='ceramista'
                                            selected={specialty === 'ceramista'}
                                        >
                                            Ceramista
                                        </option>
                                        <option
                                            value='jardinero'
                                            selected={specialty === 'jardinero'}
                                        >
                                            Jardinero
                                        </option>
                                        <option
                                            value='alabanil'
                                            selected={specialty === 'alabanil'}
                                        >
                                            Albañil
                                        </option>
                                        <option
                                            value='carpintero'
                                            selected={
                                                specialty === 'carpintero'
                                            }
                                        >
                                            Carpintero
                                        </option>
                                        <option
                                            value='electricista'
                                            selected={
                                                specialty === 'electricista'
                                            }
                                        >
                                            Electricista
                                        </option>
                                        <option
                                            value='gasfiter'
                                            selected={specialty === 'gasfiter'}
                                        >
                                            Gasfíter
                                        </option>
                                        <option
                                            value='otro'
                                            selected={specialty === 'otro'}
                                        >
                                            Otro
                                        </option>
                                    </select>
                                    <label htmlFor='specialty'>
                                        Especialidad
                                    </label>
                                </div>
                            </div>
                            {/* <div className="col-xs-12 col-md-6 col-lg-4">
                <div className="form__grupo">
                  {errors.futbolteam && (
                    <span className="alerta alerta--error alerta--xsmall">
                      {errors.futbolteam.message}
                    </span>
                  )}
                  <div className="icono">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                  <select
                    name="futbolteam"
                    id="futbolteam"
                    ref={register({
                      required: "El campo es requerido",
                    })}
                  >
                    <option value="" hidden>
                      Seleccionar
                    </option>
                    {equipos.map((x, i) => (
                      <option key={i} value={x} selected={futbolteam === x}>
                        {x}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="futbolteam">Equipo de Fútbol</label>
                </div>
              </div> */}

                            <div className='col-xs-12 col-md-6 col-lg-4'>
                                <div className='form__grupo'>
                                    {errors.preferredStore && (
                                        <span className='alerta alerta--error alerta--xsmall'>
                                            {errors.preferredStore.message}
                                        </span>
                                    )}
                                    <div className='icono'>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </div>
                                    <select
                                        name='preferredStore'
                                        id='preferredStore'
                                        ref={register({
                                            required: 'El campo es requerido',
                                        })}
                                    >
                                        <option value='' hidden>
                                            Seleccionar
                                        </option>
                                        {preferredStoreArray.map((x, i) => (
                                            <option
                                                key={i}
                                                value={x}
                                                selected={preferredStore === x}
                                            >
                                                {x}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor='preferredStore'>
                                        Tienda preferida
                                    </label>
                                </div>
                            </div>

                            <div className='col-xs-12 col-md-6 col-lg-4'>
                                <div className='form__grupo'>
                                    {errors.purchaseFrequency && (
                                        <span className='alerta alerta--error alerta--xsmall'>
                                            {errors.purchaseFrequency.message}
                                        </span>
                                    )}
                                    <div className='icono'>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </div>
                                    <select
                                        name='purchaseFrequency'
                                        id='purchaseFrequency'
                                        ref={register({
                                            required: 'El campo es requerido',
                                        })}
                                    >
                                        <option value='' hidden>
                                            Seleccionar
                                        </option>
                                        <option
                                            value='semanal'
                                            selected={
                                                purchaseFrequency === 'Semanal'
                                            }
                                        >
                                            Semanal
                                        </option>
                                        <option
                                            value='quincenal'
                                            selected={
                                                purchaseFrequency ===
                                                'Quincenal'
                                            }
                                        >
                                            Quincenal
                                        </option>
                                        <option
                                            value='cada3Meses'
                                            selected={
                                                purchaseFrequency ===
                                                'cada3Meses'
                                            }
                                        >
                                            Cada 3 meses
                                        </option>
                                        <option
                                            value='cada6MesesOMas'
                                            selected={
                                                purchaseFrequency ===
                                                'cada6MesesOMas'
                                            }
                                        >
                                            Cada 6 meses o más
                                        </option>
                                    </select>
                                    <label htmlFor='purchaseFrequency'>
                                        Frecuencia de compra
                                    </label>
                                </div>
                            </div>

                            <div className='col-xs-12'>
                                <label>Categorías interés</label>
                                <p>
                                    Selecciona las categorías de tu interés
                                    (Máximo dos categorías)
                                </p>
                                <div className='separador--small'></div>
                                {errors.categories && (
                                    <>
                                        <div className='color--error'>
                                            {errors.categories.message}
                                        </div>
                                        <div className='separador--small'></div>
                                    </>
                                )}
                                <div className='checks'>
                                    {Categorias.map(({ name, slug }, key) => (
                                        <label
                                            htmlFor={slug}
                                            className='checks__item'
                                            key={key}
                                        >
                                            <input
                                                type='checkbox'
                                                id={slug}
                                                name='categories'
                                                value={key}
                                                defaultChecked={categories.includes(
                                                    key?.toString()
                                                )}
                                                ref={register({
                                                    validate:
                                                        atLeastOneAndLessThanThree,
                                                })}
                                            />
                                            <div className='checks__icon'>
                                                <CategoryIcons icon={slug} />
                                            </div>
                                            <div className='checks__title'>
                                                {name}
                                            </div>
                                            <div className='checks__check'></div>
                                        </label>
                                    ))}
                                </div>
                                <div className='separador'></div>
                            </div>

                            <div className='col-xs-12 text-align-right-xs'>
                                <button
                                    type='submit'
                                    className='btn btn--secundario btn--small btn--redondo'
                                >
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </fieldset>
                </form>
            )}
        </>
    );
}
