import {
  GET_SALE_REQUEST,
  GET_SALE_SUCCESS,
  GET_SALE_ERROR,
} from '../types/sale';

import {
  GetSale,
} from '../requests/sale';

const getSaleRequest = data => ({
  type: GET_SALE_REQUEST,
  payload: data,
});

const getSaleSuccess = data => ({
  type: GET_SALE_SUCCESS,
  payload: data,
});

const getSaleError = data => ({
  type: GET_SALE_ERROR,
  payload: data,
});

const getSale = (payload = {}) => dispatch => {
  dispatch(getSaleRequest(payload));
  GetSale(payload)
    .then(({ data = {} }) => {
      dispatch(getSaleSuccess(data));
    })
    .catch((error = {}) => {
      const { response = {} } = error;
      const { data = [] } = response;
      dispatch(getSaleError(data))
    });
};

export {
  getSale,
};
