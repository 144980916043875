import React from "react";
import { Helmet } from "react-helmet";

const SITE_URL = process.env.REACT_APP_SITE_URL;
const Seo = ({ title, url, description, og = {}, home }) => {
  const _title = `${
    title && !home ? `${title} - Mundo Experto` : title
  }`;
  const _url = `${SITE_URL}${og.url || url ? og.url || url : ""}`;
  const _description = og.description || description;
  const _image =
    og.image || "assets/img/og-image.png";
  return (
    <Helmet>
      <title>{_title}</title>
      <meta name="description" content={description} />
      <meta name="robots" content="index, follow"></meta>
      <link rel="canonical" href={_url} />

      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={_title} />
      <meta property="og:description" content={_description} />
      <meta property="og:url" content={_url} />
      <meta property="og:image" content={_image} />

      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={_url} />
      <meta property="twitter:title" content={_title} />
      <meta property="twitter:description" content={_description} />
      <meta property="twitter:image" content={_image} />
    </Helmet>
  );
};

export default Seo;
