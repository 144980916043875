import {
  GET_FAQ_REQUEST,
  GET_FAQ_SUCCESS,
  GET_FAQ_ERROR,
  GET_FAQ_SECTION_REQUEST,
  GET_FAQ_SECTION_SUCCESS,
  GET_FAQ_SECTION_ERROR,
} from '../types/faq';

import {
  GetFAQ,
  GetSections,
} from '../requests/faq';

const getFAQRequest = data => ({
  type: GET_FAQ_REQUEST,
  payload: data,
});

const getFAQSuccess = data => ({
  type: GET_FAQ_SUCCESS,
  payload: data,
});

const getFAQError = data => ({
  type: GET_FAQ_ERROR,
  payload: data,
});

const getFAQ = (payload = {}) => dispatch => {
  dispatch(getFAQRequest(payload));
  GetFAQ(payload)
    .then(({ data = {} }) => {
      dispatch(getFAQSuccess(data));
    })
    .catch((error = {}) => {
      const { response = {} } = error;
      const { data = [] } = response;
      dispatch(getFAQError(data))
    });
};

const getSectionsRequest = data => ({
  type: GET_FAQ_SECTION_REQUEST,
  payload: data,
});

const getSectionsSuccess = data => ({
  type: GET_FAQ_SECTION_SUCCESS,
  payload: data,
});

const getSectionsError = data => ({
  type: GET_FAQ_SECTION_ERROR,
  payload: data,
});

const getSections = (payload = {}) => dispatch => {
  dispatch(getSectionsRequest(payload));
  GetSections(payload)
    .then(({ data = {} }) => {
      dispatch(getSectionsSuccess(data));
    })
    .catch((error = {}) => {
      const { response = {} } = error;
      const { data = [] } = response;
      dispatch(getSectionsError(data))
    });
};

export {
  getFAQ,
  getSections,
};
