import React, { Component } from "react";

import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Navigation, Pagination, Autoplay]);

class Principal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        {
          title: "Mundo Experto",
          image: "assets/img/slider-bg-01.jpg",
          description: `Easy tiene para ustedes el programa de Beneficios Mundo Experto, pensado para entregar descuentos exclusivos y acumulables, para sus socios Plata y Oro. Con Mundo Experto podrás obtener siempre el mejor precio del mercado.`,
        },
        {
          title: "Disfruta de los beneficios",
          image: "assets/img/slider-bg-02.jpg",
          description: `En Mundo Experto encontrarás descuentos exclusivos para los socios Plata y Oro del Programa. Recuerda que estos descuentos son permanentes y acumulables con otras ofertas y promociones.
          `,
        },
        {
          title: "El mejor precio para tus proyectos",
          image: "assets/img/slider-bg-03.jpg",
          description: `En Mundo Experto los beneficios están pensados para todos quienes trabajan en el mundo de la construcción, por eso podrán encontrar descuentos en <span class="text-wide">todos los productos</span> de las siguientes categorías: Construcción, Maderas, Pinturas, Herramientas, Gasfitería, Electricidad y Ferretería.`,
        },
      ],
    };
  }
  render() {
    const { items = [] } = this.state;
    return (
      <section className="slider swiper--custom">
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          autoplay={{
            delay: 6000,
          }}
          loop
          speed={1500}
          className={"slider__container"}
        >
          {items.map(({ title = "", image = "", description = "" }, index) => (
            <SwiperSlide key={index}>
              <div className="slider__item">
                <div className="slider__bg">
                  <img src={image} alt={title} rel="preconnect" />
                </div>
                <div className="slider__content container">
                  <h2 className="slider__title h1 text-upper text-wide">
                    {title}
                  </h2>
                  <p
                    className="slider__desc"
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="slider__bottom">
          <div className="slider__bottom__bg">
            <img
              src="assets/img/slider-bottom.jpg"
              rel="preconnect"
              alt="Mundo experto"
            />
          </div>
        </div>
      </section>
    );
  }
}

export default Principal;
