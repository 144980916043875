import React from "react";
import tiposClientes from "../../assets/json/tipos-clientes.json";
import { ClientBox } from "../../components/ClientItem";

const ClientsBox = (props) => (
  <section className="clientes">
    <div className="clientes__container container">
      <h2 className="color--secundario section__title h1 text-upper text-wide">
        Tipo de Socios
      </h2>
      <div className="separador--small"></div>
      <div className="row">
        {tiposClientes.map((x, key) => (
          <div className="col-xs-12 col-md-4" key={key}>
            <ClientBox client={x} />
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default ClientsBox;
