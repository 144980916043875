import React from 'react';
import Page from '../../components/Page';
import DarkModeToggle from '../../components/DarkModeToggle';
import Seo from '../SEO';

const Terms = () => {
	const title = 'Términos y condiciones';
	const url = 'terminos-condiciones';
	return (
		<>
			<Seo
				title={title}
				description='Términos y Condiciones del Club Mundo Experto'
				url={url}
			/>
			<Page
				title={title}
				banner
				breadcrumbs={[
					{
						type: 'link',
						path: '/',
						label: 'Inicio',
					},
					{
						type: 'separator',
					},
					{
						type: 'current',
						label: title,
					},
				]}
				content={
					<>
						<DarkModeToggle />

<h1>Términos Y Condiciones Programa Mundo Experto</h1>

<h2>I. DISPOSICIONES GENERALES</h2>

<h3>1. Antecedentes Easy Retail</h3>
<p>S.A., RUT N°76.568.660-1, domiciliado en Avenida Kennedy
9001, piso cuarto, comuna de Las Condes, Región
Metropolitana, ha elaborado estos Términos y
Condiciones, con el propósito que los consumidores (en
adelante “Usuarios”) registrados en el Programa Mundo
Experto (en adelante el “Programa”), tengan conocimiento
sobre cómo funciona el Programa. Mundo Experto es un
Programa de membresía sin costo, que permite obtener a
los Usuarios registrados promociones y ofertas
preferenciales en las tiendas de Easy, la página web
www.easy.cl, en la aplicación Easy, las que son
detalladas en la página web www.mundoexperto.cl (en
adelante, “Plataforma”).</p>

<h3>2. Registro Del Usuario Y Clave</h3>
<p>Para tener la calidad de Usuario registrado en el
Programa, debe crear una cuenta en la Plataforma
llenando sus datos en el formulario que aparece en
pantalla, siguiendo las instrucciones que se le informa.
Luego, deberá verificar su correo electrónico y crear
una clave de acceso la que será secreta, personal, e
intransferible; cuyo uso será de exclusiva
responsabilidad del Usuario. Por último, el Usuario
deberá aceptar los Términos y Condiciones y la Política
de Privacidad y Tratamiento de Datos antes del envío del
formulario. El registro es gratuito. El Usuario podrá
acceder a los beneficios del Programa Mundo Experto en
un plazo máximo de tres días hábiles, a contar del
término del proceso de registro de Usuario. El Usuario
podrá cambiar la clave de acceso las veces que estime
pertinente, mediante el procedimiento establecido e
informado en la Plataforma. La clave permite acceder a
visualizar sus condiciones de socio y los beneficios que
accede.</p>

<h3>3. Adaptaciones Para Facilitar La Revisión Y Comprensión De Nuestros Términos Y Condiciones.</h3>
<p>Estos Términos y Condiciones han sido adaptados en la
Plataforma, mediante la incorporación de funcionalidades
especiales que contribuyan a facilitar la comprensión a
todos los Usuarios; de acuerdo con normas de la ley
19.496 Sobre Protección de los Derechos de los
Consumidores. Estas funcionalidades especiales,
consisten en que, mediante su utilización, los Usuarios
podrán:</p>

<p>a) Controlar el tamaño de la letra y escoger el
que más se acomode a su visión, utilizando los controles
habilitados y de fácil acceso en la Plataforma.</p>

<p>b) Controlar, a su voluntad, el contraste entre la letra y
el color de fondo, de modo de facilitar la legibilidad
de los textos.</p>

<p>c) Utilizar lectores de pantalla y
elementos de navegación del teclado que transforman el
texto en un audio. Para utilizar esta funcionalidad, el
Usuario debe instalar un programa lector de pantalla.</p>

<h3> 4. Capacidad Legal Para Contratar</h3>
<p>Los servicios de esta
Plataforma sólo están disponibles para personas
naturales, mayor de 18 años y que tengan capacidad legal
para contratar, según lo dispuesto por la legislación
vigente. Si una persona no tiene capacidad legal para
contratar, debe abstenerse de utilizar los servicios y
la Plataforma podrá, en cualquier momento, en forma
temporal o definitiva, suspender la participación del
Usuario que carezca de capacidad legal para usar los
servicios ofrecidos en la Plataforma.</p>


<h3>5. Efecto Vinculante De Los Términos Y Condiciones.</h3>
<p>Una vez aceptados los Términos y Condiciones, estos serán
obligatorios y vinculantes. Cada vez que se realice una
actualización del presente documento, el Usuario deberá
expresar nuevamente su aceptación en la Plataforma.</p>

<h3>6. Jurisdicción.</h3>
<p>Las controversias que surjan en relación
con lo establecido en estos Términos y Condiciones
podrán interponerse, a elección del consumidor, ante el
Juzgado de Policía Local correspondiente a su domicilio
o al domicilio del proveedor. Sin perjuicio de la
competencia de los tribunales ordinarios de justicia
para otro tipo de materias. Todo lo anterior, según el
artículo 50 A de la ley 19.496.</p>

<h3>7. Representante Legal.</h3>
<p>Para todos los efectos legales y relacionados con la
presente información, Easy Retail S.A. designa como
representante legal de la empresa al señor Ricardo
González Novoa, Cédula de Identidad N°14.292.860-4,
domiciliado en Av. Presidente Kennedy 9001, Piso 4, Las
Condes.</p>

<h3>8. Centro De Ayuda: Contacto</h3>
<p>Para presentar solicitudes y/o reclamos referentes a acciones
ejecutadas o celebradas a través de esta plataforma,
puede comunicarse a través de:</p>
<ul>
	<li>Info.mundoexperto@easy.cl</li>
	<li>Call Center Easy: 6006003010</li>
</ul>

<h2>II DISPOSICIONES ESPECIFICAS</h2>

<h3>2. Usuarios Del Programa</h3>
<p>El programa Mundo Experto está destinado exclusivamente
para personas naturales con ingresos derivados de una
actividad de la construcción, ya sea, reparación,
construcción, renovación del hogar y relacionados. No
tendrán acceso a los beneficios de Mundo Experto los
colaboradores contratados por Cencosud S.A. y sus
filiales, ni beneficiarios de descuentos cruzados.
 Asimismo, no tendrán acceso a los beneficios del Programa 
Mundo Experto aquellos Usuarios que no cumplan con alguno de 
los requisitos establecidos en estos Términos y Condiciones.</p>

<h4>2.1. ¿Cómo Puedo Inscribirme?</h4>
<p>Para inscribirse en el Programa, el Usuario puede escoger una de las siguientes
opciones:</p>
<ul>
	<li>
	Plataforma: El Usuario puede ingresar a
www.mundoexperto.cl para completar y enviar el
“Formulario de Inscripción”. Previo al envío del
formulario, el Usuario debe leer y aceptar los Términos
y Condiciones y la Política de Privacidad y de
Tratamiento de Datos Personales disponibles en la misma
Plataforma. Easy podrá aceptar o rechazar la solicitud
vía correo electrónico dentro de un plazo máximo de tres
días hábiles, a contar del término del proceso de
registro de Usuario y según los requisitos establecidos
en estos Términos y Condiciones.
	</li>
	<li>
		Call center
	</li>
	<li>
	Tiendas: Mesón de Centro de Clientes. El Usuario se
podrá inscribir al Programa Mundo Experto con un
ejecutivo en el Centro de Clientes ubicado en los
distintos locales a lo largo del país.
</li>
</ul>

<h4>2.2. ¿Qué datos debo proporcionar en la solicitud de registro?</h4>
Para inscribirte en Club Mundo Experto, el Usuario debe
indicar los siguientes datos mínimos:
<ul>
<li>Nombre</li>
<li>Apellido</li>
<li>Rut</li>
<li>Fecha de nacimiento</li>
<li>Número de celular</li>
<li>Correo electrónico</li>
<li>Contraseña</li>
<li>Frecuencia de Compra: El Usuario deberá elegir, según la frecuencia
con la que compra en Easy, una de las opciones que
entrega la siguiente lista desplegable:
<ul>
	<li>Semanal</li>
	<li>Quincenal</li>
	<li>Mensual</li>
	<li>Cada 3 meses</li>
	<li>Cada 6 meses o más</li>
</ul>
	</li>
<li>Oficio: El Usuario deberá indicar el oficio que
ejerce, según la siguiente lista desplegable:
<ul>
	<li>Pintor</li>
	<li>Constructor</li>
	<li>Ingeniero</li>
	<li>Arquitecto</li>
	<li>Paisajista</li>
	<li>Ceramista</li>
	<li>Jardinero</li>
	<li>Albañil</li>
	<li>Carpintero</li>
	<li>Electricista</li>
	<li>Gasfiter</li>
	<li>Otro (en caso de elegir esta opción, deberá especificar el oficio)</li>
</ul>
</li>
</ul>

Es responsabilidad del Usuario mantener actualizados los datos de contacto.
Si desea actualizar su información, le sugerimos hacerlo
directamente en su cuenta o perfil de Usuario en
www.mundoexperto.cl, con el correo electrónico y
contraseña.

	<h3>3. SEGMENTO</h3>
	El Usuario, una vez inscrito, se incorporará a un segmento según su comportamiento de
compras de determinadas categorías durante los últimos 6
meses. Para ello, debe hacerse la siguiente mención:
	<ul>
		<li>
Segmentos: Son tres los segmentos a los que pueden
acceder los Usuarios, los que corresponden a los
siguientes:
<ul>
	<li>Tramo Bronce: Corresponderá al Tramo
Bronce para Usuarios que realizaron compras dentro de
los últimos 6 meses en determinadas categorías hasta por
la suma total de $499.999.- (cuatrocientos noventa y
nueve mil novecientos noventa y nueve pesos);</li>
<li>TramoPlata: Corresponderá al Tramo Plata para Usuarios que realizaron compras
dentro de los últimos 6 meses en determinadas categorías
desde $500.000.- (quinientos mil pesos) hasta por la
suma total de $1.499.999.- (un millón cuatrocientos
noventa y nueve mil novecientos noventa y nueve pesos);</li>


<li>Tramo Oro: Corresponderá al Tramo Oro para Usuarios
que realizaron compras dentro de los últimos 6 meses en
determinadas categorías desde $1.500.000.- (un millón
quinientos mil pesos). En todas las categorías se
acumularán las compras realizadas según el precio pagado
por los Usuarios, esto es, el precio pagado incluido los
descuentos correspondientes. En caso que el cliente no
haya realizado ninguna compra durante el periodo de 6
(seis) meses en la Plataforma o en tiendas físicas, se
le dará de baja de manera inmediata al Programa, lo que
será informado por correo electrónico.
</li></ul>
	</li>
	<li>
		Categorías de productos: Se considerarán las siguientes categorías de productos:
		<ul>
			<li>Ferretería<br/>
				FIJACIONES<br/>
				HERRAJES Y QUINCALLERÌA<br/>
				SEGURIDAD INDUSTRIAL<br/>
				</li>
			<li>Herramientas<br/>
				HERRAMIENTAS ELECTRICAS<br/>
				HERRAMIENTAS MANUALES<br/>
				RUBRO MAQUINARIAS JARDIN<br/>
				</li>
				<li> Plomería<br/>
					BOMBAS Y RIEGO<br/>
					CANALIZACION-DESAGÜES<br/>
					DISTRIBUCION AGUA-GAS-AIRE<br/>
					FOSAS-ESTANQUES-TANQUES<br/>
					ACCESORIOS DE INSTALACION<br/>
					DISTRIBUCION GAS<br/>
					ACCESORIOS DE INSTALACION AGUA Y DESAGUE<br/>
					COMPLEMENTOS<br/>
				</li>
				<li>Pinturas<br/>
					PINTURAS<br/>
					TINTOMETRICO<br/>
					ACCESORIOS DE PINTURA<br/>
				</li>
				<li>Construcción<br/>
					ACERO<br/>
					AISLACION<br/>
					OBRA GRUESA<br/>
					OBRA INTERMEDIA<br/>
					TECHOS<br/>
				</li>
				<li>Electricidad<br/>
					CABLES<br/>
					CONDUCCION / CANALIZACIÓN Y ACCESORIOS<br/>
					PROLONGADORES STD Y MULTIPLES / ALARGADO<br/>
					TECLAS Y TAPAS / INTERRUPTORES Y ENCHUFE<br/>
					TABLERO, PROTECCION Y ACCESORIOS DE INSTALACIÓN<br/>
				</li>
<li>Maderas<br/>
	CARPINTERÍA<br/>
	MADERAS<br/>
	MOLDURAS<br/>
	TABLEROS<br/>
	</li>
	</ul>
	</li>

	<li>
		Beneficios: Los Usuarios podrán acceder a diversos
beneficios según el segmento donde se encuentren. Para
esto, deberán presentar su Cédula de Identidad al
momento de declarar su condición de socio del programa
en las cajas o ingresar con su Usuario y contraseña a la
Plataforma. Lo anterior, se considerará como requisito para 
hacer uso de los beneficios del Programa. Para lo anterior 
se deberá especificar lo siguiente:

	<ul>
<li>TramoBronce:
	Los Usuarios de este tramo tendrán acceso a descuentos específicos,
los que serán informados de manera oportuna en el correo
electrónico registrado por el Usuario, y en el sitio
www.mundoexperto.cl;</li>

	<li> TramoPlata:
		Los Usuarios de este tramo tendrán acceso a un descuento en
los productos de las categorías detallados en la
cláusula tercera, del segundo capítulo de estos Términos
y Condiciones, sin perjuicio de algún otro beneficio
adicional, el que será informado de manera oportuna en
el correo electrónico registrado por el Usuario, y en el
sitio www.mundoexperto.cl;</li>
	<li>Tramo Oro: Los Usuarios de
este tramo tendrán acceso de un 7% al 15% de descuento
en los productos de las categorías detallados en la
cláusula tercera, del segundo capítulo de estos Términos
y Condiciones, sin perjuicio de algún otro beneficio
adicional, el que será informado de manera oportuna en
el correo electrónico registrado por el Usuario, y en el
sitio www.mundoexperto.cl.</li>
	</ul>
	</li>

	<li>Modificación de tramo: Cada
Usuario podrá cambiar su categoría según su
comportamiento de compra. Para subir o bajar de tramo,
se evaluarán las compras realizadas los últimos 6 meses.
La modificación de un tramo a otro es automática, lo que
será informado a los Usuarios cumplidos dentro de los
primeros siete días hábiles de cada mes por correo
electrónico.
	</li>
	</ul>
	<h3>3. PROCESO DE COMPRA O CONTRATACIÓN EN WWW.EASY.CL </h3>
	Al ser aceptada por parte del Usuario una o
más de las ofertas comunicadas en la Plataforma, la
confirmación de la solicitud de compra quedará sujeta a
que EASY, previamente, valide la transacción. Para lo
anterior, se verificará lo siguiente:
<ul>
<li>Stock disponible de los productos o servicios ofrecidos al
momento en que se acepta la oferta.</li>
<li>Validación del
medio de pago elegido por el Usuario.</li>

<li>Validación de
los datos registrados por el Usuario, los que deberán
coincidir con los que hubiera ingresado en la Plataforma
al momento de registrarse o de haber aceptado la oferta.</li>
<li>Toda transacción que incluya bebidas alcohólicas
deberá ser realizada por un Usuario mayor de 18 años.</li>
</ul>
Al momento de ser validada la transacción, se contactará al
Usuario al correo electrónico que hubiera ingresado al
momento de registrarse en la Plataforma; sin perjuicio
de que podrá ser contactado por otro medio de
comunicación si fuera necesario.

<h3>4. MEDIOS DE PAGO ACEPTADOS Y DEVOLUCIONES</h3>
<h4>4.1. Para compras en www.easy.cl</h4>

Los productos ofrecidos en la Plataforma,
salvo que se señale una forma diferente para casos
particulares u ofertas o promociones determinadas,
pueden ser pagados con:
<ul>
	<li>Tarjeta de crédito Bancarias
y No Bancarias emitidas en Chile: Visa, Mastercard,
American Express o Diners Club;</li>
<li>Tarjeta de Débito.</li>
</ul>
	No están habilitados los siguientes medios de pago: Gift
Card Cencosud y Puntos Cencosud.

El uso de las tarjetas
precedentemente referidas se sujetará a lo establecido
en estos Términos y Condiciones, y en relación con su
emisor, a lo pactado por el Usuario con este en los
respectivos Contratos de Apertura y Reglamento de Uso.
En caso de contradicción, predominará lo expresado en
este último instrumento.

<h4>4.2. Para compras presenciales.</h4>
Los productos y servicios ofrecidos en compras
presenciales, salvo que se señale una forma diferente
para casos particulares u ofertas y/o promociones de
determinados bienes o servicios, pueden ser pagados con
dinero en efectivo, Tarjeta de crédito bancaria y/o No
Bancarias emitidas en Chile: Visa, Mastercard, American
Express o Diners Club; Tarjetas de Débito, Gift Card
Cencosud y Puntos Cencosud.

<h4>4.3. Reembolsos.</h4>
En caso de anulación de la compra, una vez que el producto haya
sido físicamente devuelto a Easy, se emitirá una nota de
crédito para reembolsar el pago. Tenga presente que el
medio de pago que utilizó es el que determina el plazo
en que recibirá el reembolso. Si el Usuario realizó la
compra con tarjeta de crédito, el cargo será anulado en
máximo 10 días hábiles contados desde la devolución del
producto. Luego, la reversa o abono deberá ser informada
y reflejada en el estado de cuenta por el emisor de la
tarjeta, en el período siguiente o subsiguiente
dependiendo de la fecha de facturación mensual que el
Usuario haya convenido con dicho emisor. Si el Usuario
ocupó una Tarjeta de Débito, se realizará una
transferencia electrónica a la cuenta corriente que el
Usuario indique, en un plazo máximo de 15 días hábiles
desde que proporcionó sus datos bancarios y se haya
emitido la Nota de Crédito.

<h3>5. PRECIOS, PROMOCIONES Y OFERTAS.</h3>
Los precios y demás condiciones ofrecidas en la
Plataforma son exclusivos y vigentes para los productos
y/o servicios que se comercializan en esta. Las
promociones y ofertas estarán vigentes en la Plataforma
según el plazo informado en las respectivas bases
legales de cada campaña, o evento comercial relacionado.
Estas pueden ser exclusivas de la Plataforma y no
necesariamente aplicables a tiendas físicas.

<h3>5. RENUNCIA AL PROGRAMA</h3>
El Usuario podrá, en todo momento, renunciar
al Programa Mundo Experto. Para hacerlo, el Usuario
deberá informar expresamente a Easy Mundo Experto,
enviando un correo electrónico a
datospersonales@cencosud.cl o llamando a nuestro Call
Center Easy (600 600 3010).


	</>
	}
	/>
	</>
	);
};

export default Terms;
