import React from 'react'
import { Link } from 'react-router-dom';

const Topics = (props) => {
  const {infoTopics} = props;
  <section className="section temas-interes">
    <picture className="temas-interes__bg">
      <img
        src="assets/img/temas-interes-bg.jpg"
        alt="Temas de interés"
      />
    </picture>
    <div className="temas-interes__container container">
      <h2 className="color--blanco section__title h1 text-upper text-wide">
        Temas de interés
      </h2>
      <div className="temas-interes__grid">
        {infoTopics &&
          infoTopics.map(
            ({
              id = 0,
              slug: topicSlug = "",
              title = "",
              summary = "",
              image = "",
              category: { slug = "" },
            }) => (
              <div key={id} className="temas-interes__item">
                <picture className="temas-interes__image">
                  <img src={image} alt="" />
                </picture>
                <h3 className="temas-interes__item__title color--blanco">
                  {title}
                </h3>
                <p className="temas-interes__item__desc color--blanco">
                  {summary}
                </p>
                <Link
                  className="btn btn--small btn--redondo btn--invertido btn--terciario"
                  to={`/temas-interes/${slug}/${topicSlug}`}
                >
                  + Ver más
                </Link>
              </div>
            )
          )}
      </div>
    </div>
  </section>;
}

export default Topics;