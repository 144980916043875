import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";

const PopUp = (props) => {
  const { open, delay, content, onClose } = props;
  const [o, setOpen] = useState(open);
  const [show, setShow] = useState(true);
  useEffect(() => {
    delay ? setTimeout(() => setOpen(open), delay) : setOpen(open);
    const over = () => {
      let html = document.querySelector("html");
      o ? (html.style.overflow = "hidden") : (html.style.overflow = "");
    };
    show && over();
  }, [delay, o, open, setOpen, show]);
  return (
    show && (
      <Popup
        className="popup"
        open={o}
        onClose={() => {
          setOpen(false);
          setShow(false);
          onClose();
        }}
      >
        {content}
      </Popup>
    )
  );
};

export default PopUp;
