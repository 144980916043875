import React, { useEffect, useState } from 'react';
import Accessibility from '../../containers/Popus/Accessibility';

const DarkModeToggle = () => {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [fontSizeClass, setFontSizeClass] = useState('');
    const [termsOpen, setTermsOpen] = useState(false);
    useEffect(() => {
        const body = document.body;

        if (isDarkMode) {
            body.classList.add('dark-mode');
        } else {
            body.classList.remove('dark-mode');
        }

        body.classList.toggle(
            'letter-size--big',
            fontSizeClass === 'letter-size--big'
        );
        body.classList.toggle(
            'letter-size--sbig',
            fontSizeClass === 'letter-size--sbig'
        );

        return () => {
            // Limpiar las clases cuando el componente se desmonte
            body.classList.remove('dark-mode');
            body.classList.remove('letter-size--big');
            body.classList.remove('letter-size--sbig');
        };
    }, [isDarkMode, fontSizeClass]);

    const downloadFile = () => {
        const link = document.createElement('a');
        link.href = 'assets/docs/TyCMundoExperto_V2_1.pdf';
        link.setAttribute('download', 'Términos y Condiciones programa Mundo Experto');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
    };

    const increaseFontSize = () => {
        if (fontSizeClass === '') {
            setFontSizeClass('letter-size--big');
        } else if (fontSizeClass === 'letter-size--big') {
            setFontSizeClass('letter-size--sbig');
        } else {
            setFontSizeClass('');
        }
    };

    const decreaseFontSize = () => {
        setFontSizeClass('');
    };

    return (
        <div
            className={`dark-mode-toggle ${isDarkMode ? 'active--effect' : ''}`}
        >
            <h3
                className={`title--darkmode ${
                    isDarkMode ? 'darkmode--title--ligth' : ''
                }`}
            >
                Accesibilidad:
            </h3>
            <div className='dark-mode-toggle__content'>
                <button onClick={downloadFile}>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='svg-icon'
                        viewBox='0 0 512 512'
                    >
                        <path d='M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z' />
                    </svg>
                    <div className='dark-mode-toggle__text'>Descargar</div>
                </button>
                <button onClick={toggleDarkMode}>
                    <svg className='svg-icon' viewBox='0 0 20 20'>
                        <path
                            fill=''
                            d='M10,0.625c-5.178,0-9.375,4.197-9.375,9.375S4.822,19.375,10,19.375s9.375-4.197,9.375-9.375S15.178,0.625,10,0.625 M10,18.522V1.478c4.707,0,8.522,3.815,8.522,8.522S14.707,18.522,10,18.522'
                        ></path>
                    </svg>
                    <div className='dark-mode-toggle__text'>
                        {isDarkMode ? 'Contraste' : 'Contraste'}
                    </div>
                </button>
                <button onClick={increaseFontSize}>
                    <svg className='svg-icon' viewBox='0 0 20 20'>
                        <path
                            fill=''
                            d='M13.388,9.624h-3.011v-3.01c0-0.208-0.168-0.377-0.376-0.377S9.624,6.405,9.624,6.613v3.01H6.613c-0.208,0-0.376,0.168-0.376,0.376s0.168,0.376,0.376,0.376h3.011v3.01c0,0.208,0.168,0.378,0.376,0.378s0.376-0.17,0.376-0.378v-3.01h3.011c0.207,0,0.377-0.168,0.377-0.376S13.595,9.624,13.388,9.624z M10,1.344c-4.781,0-8.656,3.875-8.656,8.656c0,4.781,3.875,8.656,8.656,8.656c4.781,0,8.656-3.875,8.656-8.656C18.656,5.219,14.781,1.344,10,1.344z M10,17.903c-4.365,0-7.904-3.538-7.904-7.903S5.635,2.096,10,2.096S17.903,5.635,17.903,10S14.365,17.903,10,17.903z'
                        ></path>
                    </svg>
                    <div className='dark-mode-toggle__text'>
                        Aumentar tamaño
                    </div>
                </button>
                <button onClick={decreaseFontSize}>
                    <svg className='svg-icon' viewBox='0 0 20 20'>
                        <path
                            fill=''
                            d='M10,1.344c-4.781,0-8.656,3.875-8.656,8.656c0,4.781,3.875,8.656,8.656,8.656c4.781,0,8.656-3.875,8.656-8.656C18.656,5.219,14.781,1.344,10,1.344z M10,17.903c-4.365,0-7.904-3.538-7.904-7.903S5.635,2.096,10,2.096S17.903,5.635,17.903,10S14.365,17.903,10,17.903z M13.388,9.624H6.613c-0.208,0-0.376,0.168-0.376,0.376s0.168,0.376,0.376,0.376h6.775c0.207,0,0.377-0.168,0.377-0.376S13.595,9.624,13.388,9.624z'
                        ></path>
                    </svg>
                    <div className='dark-mode-toggle__text'>Reducir tamaño</div>
                </button>
                <button onClick={() => setTermsOpen(!termsOpen)}>
                    <svg class='svg-icon' viewBox='0 0 20 20'>
                        <path
                            fill=''
                            d='M12.361,6.852H7.639C5.9,6.852,4.491,8.262,4.491,10s1.409,3.148,3.148,3.148h4.723c1.738,0,3.148-1.41,3.148-3.148S14.1,6.852,12.361,6.852z M12.361,12.361H7.639c-1.304,0-2.361-1.058-2.361-2.361s1.057-2.36,2.361-2.36h4.723c1.304,0,2.36,1.057,2.36,2.36S13.665,12.361,12.361,12.361z M10,0.949c-4.999,0-9.051,4.053-9.051,9.051S5.001,19.051,10,19.051c4.999,0,9.051-4.053,9.051-9.051S14.999,0.949,10,0.949z M10,18.264c-4.564,0-8.264-3.699-8.264-8.264S5.436,1.736,10,1.736c4.564,0,8.264,3.699,8.264,8.264S14.564,18.264,10,18.264z M7.639,8.819c-0.652,0-1.18,0.528-1.18,1.181s0.528,1.181,1.18,1.181c0.652,0,1.181-0.528,1.181-1.181S8.291,8.819,7.639,8.819z'
                        ></path>
                    </svg>
                    <div className='dark-mode-toggle__text'>
                        ¿Qué es accesibilidad?
                    </div>
                </button>
                {termsOpen && (
                    <Accessibility open={termsOpen} setOpen={setTermsOpen} />
                )}
            </div>
        </div>
    );
};

export default DarkModeToggle;
