import React from "react";

const Frame = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="143"
    height="202"
    viewBox="0 0 143.4 201.7"
    className="players__card__frame"
  >
    <path
      className="players__card__frame--1"
      d="M71.7 15c12.5 0 20.5 3.8 28.9 7.8 7.8 3.7 16.6 8 27.8 8 0 0 0 0 0 0v114.1c0 0.3-0.2 0.6-0.4 0.8l-56.3 37.9 -56.3-37.9c-0.3-0.2-0.4-0.5-0.4-0.8V30.8c0.2 0 0.3 0 0.5 0 11.7 0 20.9-4.4 29.1-8.3C52.9 18.7 60.7 15 71.7 15M71.7 0C45.3 0 33.8 15.8 15.5 15.8c-4.6 0-9.7-1-15.5-3.6v132.6c0 5.3 2.6 10.3 7.1 13.2l64.7 43.5 64.7-43.5c4.4-3 7.1-7.9 7.1-13.2V12.3c-5.7 2.5-10.6 3.6-15 3.6C110.9 15.8 100.1 0 71.7 0L71.7 0z"
    />
    <path
      className="players__card__frame--2"
      d="M71.7 10c13.6 0 22.5 4.2 31.1 8.3 7.7 3.7 15.6 7.5 25.6 7.5 1.7 0 3.3-0.1 5-0.3v119.4c0 2-1 3.8-2.6 5l-59.1 39.8 -59.1-39.8c-1.7-1.1-2.6-3-2.6-5V25.4c1.8 0.2 3.7 0.4 5.5 0.4 10.5 0 18.9-4 27-7.8C51.3 13.9 59.5 10 71.7 10M71.7 0C45.3 0 33.8 15.8 15.5 15.8c-4.6 0-9.7-1-15.5-3.6v132.6c0 5.3 2.6 10.3 7.1 13.2l64.7 43.5 64.7-43.5c4.4-3 7.1-7.9 7.1-13.2V12.3c-5.7 2.5-10.6 3.6-15 3.6C110.9 15.8 100.1 0 71.7 0L71.7 0z"
    />
    <path
      className="players__card__frame--3"
      d="M71.7 4c15 0 24.9 4.7 33.7 8.9 7.4 3.5 14.4 6.9 23 6.9 3.6 0 7.2-0.6 11-1.8v126.8c0 4-2 7.7-5.3 9.9l-62.4 42 -62.4-42C6 152.6 4 148.9 4 144.9V18c4 1.2 7.8 1.8 11.5 1.8 9.2 0 16.6-3.5 24.4-7.2C48.9 8.4 58.2 4 71.7 4M71.7 0C45.3 0 33.8 15.8 15.5 15.8c-4.6 0-9.7-1-15.5-3.6v132.6c0 5.3 2.6 10.3 7.1 13.2l64.7 43.5 64.7-43.5c4.4-3 7.1-7.9 7.1-13.2V12.3c-5.7 2.5-10.6 3.6-15 3.6C110.9 15.8 100.1 0 71.7 0L71.7 0z"
    />
  </svg>
);

const Card = ({ name, title, team, avatar, logoCampeonato }) => {
  return (
    <div className="players__card">
      <div className="players__card__avatar">
        <img src={avatar} alt={name} />
      </div>
      {Frame}
      <div className="players__card__logos">
        <img src={logoCampeonato} alt="Campeonato Plan Vital" />
        <img
          src="assets/img/easy-logo.png"
          alt="Easy"
        />
      </div>
      <div className="players__card__info">
        <div className="players__card__ribbon">
          <div className="players__card__name">{name}</div>
          <div className="players__card__title">{title}</div>
        </div>
        <div className="players__card__team">
          <img
            src={`https://mundoexperto.s3.us-west-2.amazonaws.com/assets/img/equipos/${team}.svg`}
            alt={team}
          />
        </div>
      </div>
    </div>
  );
};

export default Card;
