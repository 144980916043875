import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Cookies from "js-cookie";
import {
    faUserCircle,
    faClipboardList,
    faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons/';
import MobileMenu, { BurgerIcon } from './MobileMenu';
import pages from '../../assets/json/pages.json';
// import logo from '../../assets/img/logo.svg'

export default function Header(props) {
    const { layout, cookie, setLogin } = props;

    const dispatch = useDispatch();
    // const [cookie, setCookie] = useState(false);
    const [open, setOpen] = useState(false);
    const [width, setWidth] = useState(
        window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth
    );
    const [scroll, setScroll] = useState(window.scroolY);

    // Manejador del estado del menu mobile
    const handleOpen = (state) => setOpen(state);

    // Manejador del ancho de pantalla
    const handleWidth = (e) =>
        setWidth(
            window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth
        );

    // Manejador del estado del scroll
    const handleScroll = (e) => setScroll(window.scrollY);

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleWidth);
        width < 1200 && setOpen(false);
    }, [width]);

    useEffect(() => {
        // const tokenExist = Cookies.get("token") ? true : false;
        // setCookie(tokenExist);
        // window.logged = tokenExist;
    }, [cookie]);

    const logout = () => {
        setLogin(false);
        dispatch({
            type: 'POST_LOGIN_LOGOUT',
        });
    };

    return (
        <>
            <header
                className={`header ${scroll > 140 ? 'header--active' : ''} ${
                    layout ? `header--${layout}` : ''
                }
          ${open ? 'header--active header--menu-active' : ''}
          `}
            >
                {layout === 'dashboard' && (
                    <Link to='/' className='header__logo'>
                        <img src='assets/img/logo-new-black.png' alt='Mundo Experto' />
                    </Link>
                )}
                <div className='header__top'>
                    <div className='header__container container'>
                        {!cookie ? (
                            <div className='header__actions btn__grupo'>
                                <NavLink
                                    className={
                                        'btn btn--fantasma btn--redondo btn--small btn--ancho btn--icono btn--icono-izq'
                                    }
                                    to='/login'
                                >
                                    <FontAwesomeIcon icon={faUserCircle} />
                                    Iniciar Sesión
                                </NavLink>
                                <NavLink
                                    className={
                                        'btn btn--primario btn--redondo btn--small btn--ancho btn--icono btn--icono-izq'
                                    }
                                    to='/inscripcion'
                                >
                                    <FontAwesomeIcon icon={faClipboardList} />
                                    Inscríbete
                                </NavLink>
                            </div>
                        ) : (
                            <nav className='header__menu-top menu-top'>
                                <ul className='menu-top__list'>
                                    <li className='menu-top__item'>
                                        <Link
                                            to='/mi-cuenta'
                                            className='menu-top__link'
                                        >
                                            Mi cuenta
                                        </Link>
                                    </li>
                                    <li className='menu-top__item'>
                                        <Link
                                            to=''
                                            onClick={logout}
                                            className='menu-top__link'
                                        >
                                            <FontAwesomeIcon
                                                icon={faSignOutAlt}
                                                size='lg'
                                            />
                                            Cerrar sesión
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        )}
                    </div>
                </div>
                <div className='header__bottom'>
                    <div className='header__container container'>
                        {layout !== 'dashboard' ? (
                            <Link
                                className='header__item header__item--logo'
                                to='/'
                            >
                                <img
                                    src='assets/img/logo-new-black.png'
                                    alt='Mundo Experto'
                                />
                            </Link>
                        ) : (
                            ''
                        )}
                        <BurgerIcon
                            open={open}
                            handleOpen={handleOpen}
                            responsive={width < 1200}
                        />
                        {pages.length && (
                            <nav className='header__item header__item--menu menu'>
                                <ul className='menu__list'>
                                    {pages
                                        .filter(
                                            (x) =>
                                                x.visible &&
                                                x.menu === 'primary'
                                        )
                                        .map((x) => (
                                            <li
                                                className='menu__item'
                                                key={x.slug}
                                            >
                                                <NavLink
                                                    to={x.path}
                                                    className='menu__link'
                                                    activeClassName='active'
                                                    exact={x.exact}
                                                >
                                                    {x.title}
                                                </NavLink>
                                            </li>
                                        ))}
                                </ul>
                            </nav>
                        )}
                    </div>
                </div>
            </header>
            <MobileMenu
                open={open}
                handleOpen={handleOpen}
                responsive={width < 1200}
            />
        </>
    );
}
