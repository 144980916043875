import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faClipboardList,
  faBars,
  faTimes,
} from "@fortawesome/free-solid-svg-icons/";
import pages from "../../assets/json/pages.json";

export class MobileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      currentWidth: false,
    };
    this.open = this.handleOpen.bind(this);
    // this.props.handleOpen(!this.state.open);
  }
  handleOpen = this.props.handleOpen;
  render() {
    return (
      this.props.responsive && (
        <div
          className={`menu-mobile ${
            this.props.open ? "menu-mobile--active" : ""
          }`}
        >
          {pages.length && (
            <nav className="menu-mobile__nav">
              <ul className="menu-mobile__list">
                {pages
                  .filter((x) => x.visible)
                  .map((x) => (
                    <li className="menu-mobile__item" key={x.slug}>
                      <NavLink
                        to={x.path}
                        className="menu-mobile__link"
                        activeClassName="active"
                        exact={x.exact}
                        onClick={() => this.props.handleOpen(false)}
                      >
                        {x.title}
                      </NavLink>
                    </li>
                  ))}
              </ul>
            </nav>
          )}
          <nav className="menu-mobile__nav menu-mobile__nav--btns">
            <NavLink
              className="menu-mobile__link menu-mobile__link--1"
              onClick={() => this.props.handleOpen(false)}
              to="/login"
            >
              <FontAwesomeIcon icon={faUserCircle} />
              Iniciar Sesión
            </NavLink>
            <NavLink
              className="menu-mobile__link menu-mobile__link--2"
              onClick={() => this.props.handleOpen(false)}
              to="/inscripcion"
            >
              <FontAwesomeIcon icon={faClipboardList} />
              Registrarse
            </NavLink>
          </nav>
        </div>
      )
    );
  }
}

export class BurgerIcon extends Component {
  render() {
    return (
      this.props.responsive && (
        <div
          className="header__burger"
          onClick={() => this.props.handleOpen(!this.props.open)}
        >
          <FontAwesomeIcon icon={!this.props.open ? faBars : faTimes} size="lg"/>
        </div>
      )
    );
  }
}

export default MobileMenu;
