import React from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import Futbol from "../../assets/json/futbol.json";

const Football = () => {
  const logo = (slug, name) => {
    try {
      const logo = `assets/img/equipos/${slug}.svg`;
      return (
        <img
          src={logo.default}
          alt={name}
          className="futbol__fechas__match__logo"
        />
      );
    } catch (e) {
      return (
        <svg
          className="futbol__fechas__match__logo"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 981.25 981.25"
        >
          <path
            d="M946.23,206.651c-0.3-23-18-42-40.899-44.101c-190.3-17.8-345.601-119.5-396.8-156.7c-10.7-7.8-25.2-7.8-35.9,0
		c-51.1,37.2-206.4,138.9-396.7,156.7c-22.9,2.101-40.5,21.101-40.9,44.101c-2.3,150.1,21.8,659.699,444.1,773.1
		c7.5,2,15.4,2,22.9,0C924.331,866.451,948.43,356.75,946.23,206.651z"
          />
        </svg>
      );
    }
  };
  return (
    <>
      <Breadcrumbs
        crumbs={[
          {
            type: "link",
            path: "/mi-cuenta",
            label: "Mi cuenta",
          },
          { type: "separator" },
          { type: "current", label: "Fútbol" },
        ]}
      />
      <h1 className="h3">Fútbol</h1>
      <div className="futbol">
        <div className="futbol__fechas">
          {Futbol.map((x, key) => {
            const jornadas = x.totalJornadas;
            return x.jornadas
              .sort((a) => a.jornada)
              .reverse()
              .map((x, key) => {
                return (
                  <div className="futbol__fechas__content" key={key}>
                    <div className="futbol__fechas__header">
                      Jornada {x.jornada} de {jornadas}
                    </div>
                    <div className="futbol__fechas__matches">
                      {x.matchs.map((x, key) => (
                        <div className="futbol__fechas__match">
                          <div className="futbol__fechas__match__teams">
                            <div className="futbol__fechas__match__team">
                              {logo(x.teams[0].slug, x.teams[0].name)}

                              <span className="futbol__fechas__match__name">
                                {x.teams[0].name}
                              </span>
                              <span className="futbol__fechas__match__goals">
                                {x.teams[0].goals}
                              </span>
                            </div>
                            <div className="futbol__fechas__match__team">
                              {logo(x.teams[1].slug, x.teams[1].name)}
                              <span className="futbol__fechas__match__name">
                                {x.teams[1].name}
                              </span>
                              <span className="futbol__fechas__match__goals">
                                {x.teams[1].goals}
                              </span>
                            </div>
                          </div>
                          <div className="futbol__fechas__match__status">
                            {x.state === "finished" && (
                              <div className="badge badge--terciario">
                                Finalizado
                              </div>
                            )}
                            <div className="badge bandge--secundario">
                              {x.date}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              });
          })}
        </div>
      </div>
      {/* <Content dateNow={Fechas[0].date} items={Fechas[0].items} /> */}
    </>
  );
};

export default Football;
