import React from "react";
import { useScroll } from "../../utils/helpers";
import {
  faChevronUp,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";
import {
  FontAwesomeIcon,
} from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const FixedBtns = (props) => {
  const { logged } = props;
  let scrollY = useScroll().scrollY;

  return (
    <div className="fixed-btns">
      {!logged && (
        <div className="fixed-btns__item">
          <Link to="/inscripcion" className="btn btn--primario btn--redondo btn--icono btn--icono-izq btn--big">
            <FontAwesomeIcon icon={faClipboardList} />
            Inscríbete
          </Link>
        </div>
      )}
      {scrollY > 250 && (
        <div className="fixed-btns__item">
          <span
            className="btn btn--circulo btn--terciario"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            <FontAwesomeIcon icon={faChevronUp} size="lg" />
          </span>
        </div>
      )}
    </div>
  );
};

export default FixedBtns;
