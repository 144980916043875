import React from "react";
import CategoryIcons from "./Icons";

const Category = ({ name, slug, desc, dscto, type }) => (
	<div className="categorias__item">
		<div className="categorias__item__container">
			<div className="categorias__item__bg">
				<img
					src={`assets/img/categorias/${slug}.jpg`}
					alt={slug}
				/>
			</div>
			<div className="categorias__item__info">
				{dscto && (
					<div className="categorias__item__dsctos">
						{(!type || type === "plata") && (
							<div className="categorias__item__dscto categorias__item__dscto--plata">
								{dscto.plata}%{!type && <span>Plata</span>}
							</div>
						)}
						{(!type || type === "oro") && (
							<div className="categorias__item__dscto categorias__item__dscto--oro">
								{dscto.oro}%{!type && <span>Oro</span>}
							</div>
						)}
					</div>
				)}
				<div className="categorias__item__info__label">
					<div className="categorias__item__icon">
						<CategoryIcons icon={slug} />
					</div>
					<div className="categorias__item__title">{name}</div>
				</div>
			</div>
		</div>
		{desc && (
			<div className="categorias__hover">
				<div className="categorias__hover__title">{name}</div>
				{desc && <div className="categorias__hover__desc">{desc}</div>}
			</div>
		)}
	</div>
);

export default Category;
