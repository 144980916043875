import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";

import { updateAvatar } from "../../actions/auth";
import { uploadImage } from "../../requests/upload";

const Uploadavatar = ({
  setAvatar,
  img,
  setImg,
  imgAnterior,
  setImgAnterior,
  showCrop,
}) => {
  const dispatch = useDispatch();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const profile = useSelector((state) => state.profile);
  const { data = {} } = profile || {};
  const {
    firstname = '',
    lastname = '',
    email = '',
  } = data || {};

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(img, croppedAreaPixels);
      const { data: URLImage = '' } = await uploadImage({
        file: croppedImage,
      });

      dispatch(updateAvatar({
        firstname,
        lastname,
        email,
        data: [{ type: "avatar", data: URLImage }],
      }));

      setAvatar(URLImage);
      setImgAnterior(URLImage);
    } catch (e) {
      console.error(e);
    }
  }, [dispatch, email, firstname, lastname, croppedAreaPixels, img, setAvatar, setImgAnterior]);

  return (
    <div className="crop">
      <div className="crop__container">
        <Cropper
          image={img}
          crop={crop}
          aspect={1 / 1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
        />
        <div className="crop__footer">
          <div className="btn__grupo">
            <span
              className="btn btn--borde btn--secundario"
              onClick={() => {
                showCrop(false);
                setAvatar(imgAnterior);
                setImg(undefined);
              }}
            >
              Cancelar
            </span>
            <span
              className="btn btn--primario"
              onClick={() => {
                showCroppedImage();
                showCrop(false);
              }}
            >
              Aceptar
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Uploadavatar;
