import { doAuthentication } from '../../utils/api-client';
const queryString = require('query-string');

export const GetCategoriesTopic = (payload = {}) => {
  return doAuthentication({
    method: 'GET',
    url: `/topic/category`,
  });
};

export const GetTopics = (payload = {}) => {
  const { query = {} } = payload;
  const filters = queryString.stringify(query);
  return doAuthentication({
    method: 'GET',
    url: `/topic?${filters}`,
  });
};
