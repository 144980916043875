import {
  GET_TOPICS_REQUEST,
  GET_TOPICS_SUCCESS,
  GET_TOPICS_ERROR,
  GET_TOPIC_REQUEST,
  GET_TOPIC_SUCCESS,
  GET_TOPIC_ERROR,
} from '../types/topics';

const initialState = {
  err: {},
  isFetching: false,
  isFetchingTopic: false,
  data: [],
  topic: {},
};

const topicReducer = (state = initialState, action = {}) => {
  const { type = '', payload = {} } = action;
  switch (type) {
    case GET_TOPICS_REQUEST:
    case GET_TOPIC_REQUEST:
      return {
        ...state,
        isFetching: true,
        isFetchingTopic: true,
        err: null,
      };
    case GET_TOPICS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: payload,
        err: null,
      };
    case GET_TOPIC_SUCCESS:
      const { topics = [] } = payload || {};
      const [firstTopic = {}] = topics;
      return {
        ...state,
        isFetchingTopic: false,
        topic: firstTopic,
        err: null,
      };
    case GET_TOPICS_ERROR:
    case GET_TOPIC_ERROR:
      const { errors = '' } = payload;
      return {
        ...state,
        isFetching: false,
        isFetchingTopic: false,
        err: errors,
      };
    default: {
      return state;
    }
  }
};

export default topicReducer;
