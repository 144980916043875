/*global google*/
import React, { useState, useEffect } from "react";
import { Loader } from "@googlemaps/js-api-loader";
// import icon from "../../assets/marker.png";

const mostrarMapa = (tiendas, setLoading, center, zoom) => {
  const mapa = document.getElementById("mapa");
  const loader = new Loader({
    apiKey: "AIzaSyBMXQgtb0Wor9ld0t4nuGiveh2XXTJh8HQ",
    version: "weekly",
  });

  loader.load().then(() => {
    setLoading(false);
    const map = new google.maps.Map(mapa, {
      center: {
        lat: center[0],
        lng: center[1],
      },
      zoom,
    });

    tiendas.forEach((x) => {
      const info = `
        <div id="container" className="mapa__window">
          <div className="mapa__image">
            <img src="${`assets/img/tiendas/${x.slug}.jpg`}">
          <div className="mapa__window__content">
            <h5>${x.tienda}</h5>
            <p><strong>Dirección</strong></p>
            <div className="mapa__text">${x.info.direccion}</div>
            <br>
            <p><strong>Horarios</strong></p>
            ${
              x.info.horarios["lun-sab"] &&
              `<div className="mapa__text">Lunes a Sábado: ${x.info.horarios["lun-sab"]}</div>`
            }
            ${
              x.info.horarios["dom-fest"] &&
              `<div className="mapa__text">Domingos y Festivos: ${x.info.horarios["dom-fest"]}</div>`
            }
            <p>
            <br>
            <strong>Teléfono</strong></p>
            <div className="mapa__text">${x.info.telefono}</div>
          </div>
        </div>
      `;
      const infowindow = new google.maps.InfoWindow({
        content: info,
      });
      const marker = new google.maps.Marker({
        position: { lat: x.coord[0], lng: x.coord[1] },
        map,
        title: x.tienda,
        icon: "assets/img/marker.png",
      });
      marker.addListener("click", () => {
        infowindow.open(map, marker);
      });
    });
  });
};

const MapaTiendas = ({ data }) => {
  const { tiendas, zoom, center } = data;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    mostrarMapa(tiendas, setLoading, center, zoom);
  }, [center, tiendas, zoom]);
  return loading ? (
    <div className="mapa" id="mapa">
      <div className="preloader">
        <div className="preloader__child"></div>
      </div>
    </div>
  ) : (
    <div className="mapa mapa--loaded" id="mapa"></div>
  );
};

// export default GoogleApiWrapper({
//   apiKey: "AIzaSyCDBZubEDdJH9zXAQpquHrY1yTSX0ufFHE",

// })(MapaTiendas);

export default MapaTiendas;
