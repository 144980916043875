import { doAuthentication } from '../../utils/api-client';
const queryString = require('query-string');

export const GetFAQ = (payload = {}) => {
  const { query = {} } = payload;
  const filters = queryString.stringify(query);
  return doAuthentication({
    method: 'GET',
    url: `/question?${filters}`,
  });
};

export const GetSections = (payload = {}) => {
  const { query = {} } = payload;
  const filters = queryString.stringify(query);
  return doAuthentication({
    method: 'GET',
    url: `/question/section?${filters}`,
  });
};
