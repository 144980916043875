import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhone,
  faQuoteLeft,
  faQuoteRight,
} from "@fortawesome/free-solid-svg-icons/";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/components/pagination/pagination.scss";

SwiperCore.use([Navigation, Pagination, Autoplay]);

class Testimonial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonial: [
        {
          name: "Jhon Silva",
          position: "Contratista",
          location: "Santiago",
          image: "https://uifaces.co/our-content/donated/xZ4wg2Xj.jpg",
          description:
            "El club de beneficios me ha ayudado bastante a optimizar mis presupuestos con buenos descuentos. De esta forma  doy un mejor servicio a mis clientes.",
        },
        {
          name: "Luis Carrasco",
          position: "Maestro Constructor",
          location: "Santiago",
          image: "https://uifaces.co/our-content/donated/Square__small_.jpg",
          description:
            "Al inscribirme en mundo experto mis descuentos se activaron a los pocos días. Tengo descuentos en las mejores categorías. ",
        },
        {
          name: "Teresa Vergara",
          position: "Contratista",
          location: "Santiago",
          image:
            "https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9",
          description:
            "Mundo Experto me ha ayudado llegar con presupuestos más efectivos con mis clientes ya que les traspaso ese descuento ellos.",
        },
      ],
    }; // phone: '+56912345678', // phone: '+56912345678', // phone: '+56912345678',
  }
  render() {
    const { testimonial = [] } = this.state;
    return (
      <section className="section testimonios">
        <picture className="testimonios__bg">
          <img
            src="assets/img/testimonios-bg.jpg"
            alt="Temas de interés"
          />
        </picture>
        <div className="testimonios__container container">
          <div className="testimonios__info">
            <h2 className="color--secundario section__title h1 text-upper text-wide">
              Testimonios
            </h2>
            <p className="color--secundario section__subtitle">
              Conoce la experiencia de nuestros socios
            </p>
          </div>
          <div className="testimonios__carousel swiper--custom">
            <div className="swiper-container">
              <Swiper
                spaceBetween={20}
                slidesPerView={3}
                // navigation={{
                //   nextEl: '.swiper-button-next',
                //   prevEl: '.swiper-button-prev'
                // }}
                // pagination
                // autoplay
                // loop
                breakpoints={
                  {
                    0: {
                      slidesPerView: 1,
                      navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      },
                    },
                    992: {
                      pagination: false,
                      slidesPerView: 3,
                      navigation: false,
                    },
                  } // scrollbar={{ draggable: true }}
                }
                className={"testimonios__wrapper swiper-wrapper"}
              >
                {testimonial.map(
                  (
                    {
                      name = "",
                      position = "",
                      location = "",
                      phone = "",
                      image = "",
                      description = "",
                    },
                    index
                  ) => (
                    <SwiperSlide key={index} className="testimonios__item">
                      <div className="testimonios__item__bg">
                        <img
                          src="assets/img/testimonios-item-bg.jpg"
                          alt={name}
                          rel="preconnect"
                        />
                      </div>
                      <div className="testimonios__item__content">
                        {/* <picture className="testimonios__image">
                          <img src={image} alt="" />
                        </picture> */}
                        <div className="separador"></div>
                        <p className="testimonios__name text-upper text-wide color--terciario">
                          {name}
                        </p>
                        <div className="testimonios__cargo__container">
                          <h4 className="testimonios__cargo">{position}</h4>
                        </div>
                        <div className="testimonios__meta">
                          <h5 className="testimonios__meta__item">
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                            {location}
                          </h5>
                          {phone && (
                            <h5 className="testimonios__meta__item">
                              <FontAwesomeIcon icon={faPhone} />
                              {phone}
                            </h5>
                          )}
                        </div>
                        <p className="testimonios__desc">
                          <FontAwesomeIcon icon={faQuoteLeft} />
                          {description}
                          <FontAwesomeIcon icon={faQuoteRight} />
                        </p>
                      </div>
                    </SwiperSlide>
                  )
                )}
              </Swiper>
            </div>
            {/* <div
              className="testimonios__button-prev swiper-button-prev"
              data-swiper-prev="data-swiper-prev"
            />
            <div
              className="testimonios__button-next swiper-button-next"
              data-swiper-next="data-swiper-next"
            /> */}
          </div>
        </div>
      </section>
    );
  }
}

export default Testimonial;
