import {faInfoCircle, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from "react";
import { formatNumber } from "../../utils/helpers";

import tiposClientes from "../../assets/json/tipos-clientes.json";

const StatusBar = ({ value }) => {

	const plata = tiposClientes.find((tipoCliente) => tipoCliente.type === 'plata');
	const oro = tiposClientes.find((tipoCliente) => tipoCliente.type === 'oro');

	const percent = (value / 1700000) * 100;

	return (
		<>
			<div className="statusbar">
				{value < oro.totalPurchases ? (
					<div className="statusbar__data text-align-center-xs">
						<FontAwesomeIcon icon={faInfoCircle} /> Este mes te falta comprar{" "}
						<strong>{formatNumber((value < plata.totalPurchases ? plata.totalPurchases : oro.totalPurchases) - value)}</strong> para obtener tu
						categoría <strong>{value < plata.totalPurchases ? 'PLATA' : 'ORO'}</strong>

					</div>
				) : (
					<div className="statusbar__data statusbar__data--success text-align-center-xs">
						<FontAwesomeIcon icon={faStar} /> !Felicidades¡ Ya eres un socio <strong>ORO</strong> <FontAwesomeIcon icon={faStar} />
					</div>
				)}
				<div className="statusbar__container">
					<div className="statusbar__bar">
						<div
							className={`statusbar__bar__item ${
								value >= oro.totalPurchases ? "bg--preferente" : ""
							} ${value >= plata.totalPurchases && value < oro.totalPurchases ? "bg--experto" : ""} ${
								value < plata.totalPurchases ? "bg--inactivo" : ""
							}`}
							style={{
								width: `${percent < 100 ? percent : 100}%`,
							}}
						/>
					</div>
					<div className="statusbar__info">
						<div className="statusbar__info__item statusbar__info__item--1">
							<span>Meta Plata</span>
							{ plata.value }
						</div>
						<div className="statusbar__info__item statusbar__info__item--2">
							<span>Meta Oro</span>
							{ oro.value }
						</div>
					</div>
					<div
						className="statusbar__now"
						style={{ left: `${percent < 100 ? percent : 100}%` }}
					>
						<div
							className="statusbar__now__total"
							data-value={formatNumber(value)}
						/>
					</div>
					{value >= plata.totalPurchases ? (
						<div
							className={`statusbar__label ${
								value >= oro.totalPurchases ? "bg--oro" : "bg--plata"
							}`}
							style={{
								left: `calc(${percent < 100 ? percent : 100}%)`,
							}}
						>
							Socio {value >= oro.totalPurchases ? "Oro" : "Plata"}
						</div>
					) : (
						<div
							className='statusbar__label bg--bronce'
							style={{
								left: `calc(${percent < 100 ? percent : 100}%)`,
							}}
						>
							Socio bronce
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default StatusBar;
