import {
  POST_REGISTER_REQUEST,
  POST_REGISTER_SUCCESS,
  POST_REGISTER_ERROR,
  VALIDATE_EXIST_USER_REQUEST,
  VALIDATE_EXIST_USER_SUCCESS,
  VALIDATE_EXIST_USER_ERROR,
} from '../types/auth';

const initialState = {
  err: {},
  isFetching: false,
  data: {},
  validateUser: {
    err: {},
    data: false,
    isFetching: false,
  }
};

const registerReducer = (state = initialState, action = {}) => {
  const { type = '', payload = {} } = action;
  switch (type) {
    case POST_REGISTER_REQUEST:
      return {
        ...state,
        isFetching: true,
        err: null,
      };
    case POST_REGISTER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: payload,
        err: null,
      };
    case POST_REGISTER_ERROR:
      const { errors = '' } = payload;
      return {
        ...state,
        isFetching: false,
        err: errors,
      };
    case VALIDATE_EXIST_USER_REQUEST:
      return {
        ...state,
        validateUser: {
          isFetching: true,
        },
        err: null,
      };
    case VALIDATE_EXIST_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        validateUser: {
          isFetching: false,
          data: payload,
        },
      };
    case VALIDATE_EXIST_USER_ERROR:
      const { errors: errValidate = '' } = payload;
      return {
        ...state,
        validateUser: {
          err: errValidate,
          isFetching: false,
        },
      };
    default: {
      return state;
    }
  }
};

export default registerReducer;
