import React from "react";
import PopUp from "../../components/Popup";

const Accessibility = ({ open, setOpen }) => (
  <PopUp
    open={open}
    onOpen={() => setOpen(true)}
    onClose={() => setOpen(false)}
    content={(close) => (
      <div className="popup__content slideUp">
        <div className="content">
          <p>
            <strong>
              ¿Qué es accesibilidad?
            </strong>
          </p>
          <p>
            La accesibilidad busca que todas las personas que son usuarias 
            de un sitio web puedan navegar e interactuar, sin importar si 
            es que tienen o no algún grado de discapacidad.
          </p>
          <p>
            En mundoexperto.cl nos hemos preocupado de que nuestros términos y condiciones 
            cumplan con los requisitos mínimos recomendados por el Servicio Nacional de la Discapacidad (Senadis) 
            basados en los estándares entregados por el consorcio de la World Wide Web en sus Pautas de 
            Accesibilidad para el Contenido Web (WCAG) 2.0.
          </p>
          <p>
            <strong>Tamaño de letras</strong>
          </p>
          <p>
            La fuente de esta página tiene dimensiones relativas, así las personas podrán 
            controlar los tamaños con los controles habilitados.
          </p>
          <p>
            <strong>Contraste</strong>
          </p>
          <p>
            Habilitamos una opción con mayor contraste para mejorar la legibilidad de los textos en las personas que puedan necesitarlo.
          </p>
          <p>
            <strong>Lectores de pantalla</strong>
          </p>
          <p>
            Nos preocupamos de habilitar el uso de los lectores de pantalla e integramos elementos de navegación del teclado 
            para que las personas puedan utilizarlo mediante un lector de pantalla. Para poder utilizarlo, debes bajar una extensión o instalar un software de Screen Reader. Algunos ejemplos gratuitos que puedes utilizar son:
          </p>
          <p>
            <ol>
              <li>NVDA (para Windows)</li>
              <li>VoiceOver (integrado en dispositivos Apple)</li>
              <li>Screen reader (extensión de Google Chrome)</li>
            </ol>
          </p>
          <p>
            En celulares, solo debes ir a la configuración y activar la opción de VoiceOver en dispositivos iOS o TalkBack en Android.
          </p>
        </div>
        <footer className="popup__footer">
          <div className="separador"></div>
          <div className="btn__grupo justify-end-xs flex-xs">
            <span
              className="btn btn--secundario btn--borde btn--redondo"
              onClick={close}
            >
              Entendido
            </span>
          </div>
        </footer>
      </div>
    )}
  />
);

export default Accessibility;
