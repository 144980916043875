import React from "react";
import { Link } from "react-router-dom";

export const ClientBox = ({ client, desc = true, btn = true, boxSide = false }) => (
  <div
    className={`clientes__item__box ${ boxSide ? "clientes__item__box--side" : "clientes__item__box--aside" }`}
  >
    <div
      className={`clientes__item__bg ${
        client.type === "bronce" ? "bg--bronce" :
        client.type === "plata" ? "bg--plata" :
        client.type === "oro" ? "bg--oro" :
        ""
      }`}
    >
      <img
        src={`assets/img/tipoSocios/${client.type}.png`}
        alt=""
      />
    </div>
    <div className="clientes__item__info">
      <div
        className={`clientes__item__title h1 text-upper text-wideclientes__item__title h1 text-upper text-wide color--blanco`}
      >
        {client.name}
      </div>
      {desc && (
        <div
          className="clientes__item__parraf color--blanco"
          dangerouslySetInnerHTML={{ __html: client["short-desc"] }}
          data-type={client.type}
        ></div>
      )}
      {btn && (
        <Link
          to={`/${client.type}`}
          className={`clientes__item__btn btn btn--redondo btn--small btn--ancho btn--invertido ${
            client.type === "experto" ? "btn--experto" : "btn--preferente"
          }`}
        >
          Conoce más
        </Link>
      )}
    </div>
  </div>
);

export const ClientItem = ({ client, btn, desc, boxSide }) => (
  <div className={`clientes__item bg--${client.type}`}>
    <ClientBox client={client} btn={btn} desc={desc} boxSide={boxSide} />
    <div className="clientes__item__content clientes__item__content--left">
      <p
        className="clientes__item__parraf color--blanco"
        dangerouslySetInnerHTML={{ __html: client.desc }}
      ></p>
      <div className="clientes__progress" data-type={client.type}>
        <div className="clientes__progress__barra"></div>
        <div className="clientes__progress__points">
          <div className="clientes__progress__point">
            <span data-active={client.value === "0"}></span>
            $0
          </div>
          <div className="clientes__progress__point">
            <span data-active={client.value === "$500.000"}></span>
            $500.000
          </div>
          <div className="clientes__progress__point">
            <span data-active={client.value === "$1.500.000"}></span>
            $1.500.000
          </div>
        </div>
      </div>
    </div>
  </div>
);
