import React from "react";
import Page from "../../components/Page";
import Seo from "../SEO";
import { ClientItem } from "../../components/ClientItem";
import tiposClientes from "../../assets/json/tipos-clientes.json";

export default function Bronce() {
	const tipoCliente = tiposClientes.find((tipoCliente) => tipoCliente.type === 'bronce');

	return (
		<>
			<Seo
				title={tipoCliente.name}
				description={`Categoría ${tipoCliente.name}: Súmate a este beneficio y obtén descuentos en todas tus compras`}
				url={`/${tipoCliente.slug}`}
			/>
			<Page
				title={tipoCliente.name}
				banner
				breadcrumbs={[
					{
						type: "link",
						path: "/",
						label: "Inicio",
					},
					{
						type: "separator",
					},
					{
						type: "current",
						label: tipoCliente.name,
					},
				]}
				content={
					<>
						<ClientItem
							client={tipoCliente}
							btn={false}
							desc={false}
							boxSide
						/>
						<div className="separador"></div>
						<div className="expertos__info">
							<h2 className="h3 text-wide text-upper">Condiciones</h2>
						</div>
						<div className="beneficios__calugas">
							<div className="row">
								{
									tipoCliente.conditions
										.map((condition, key) => (
										<div className="col-xs-12 col-md-6" key={key}>
											<div className="beneficios__calugas__item bg--primario">
												<h3 className="beneficios__calugas__title text-upper text-wide color--blanco h4">
													{condition.title}
												</h3>
												<p className="beneficios__calugas__desc color--blanco">
													{condition.desc}
												</p>
											</div>
										</div>
									))
								}
							</div>
						</div>
						<div className="separador"></div>
						<div className="expertos__info">
							<h2 className="h3 text-wide text-upper">Más Beneficios</h2>
						</div>
						<div className="beneficios__calugas">
							<div className="row">
							{
								tipoCliente.moreBenefits.map((moreBenefit, key) => (
									<div className="col-xs-12 col-md-6" key={key}>
										<div className="beneficios__calugas__item bg--secundario">
											<p className="beneficios__calugas__desc color--blanco">
												{moreBenefit}
											</p>
										</div>
									</div>
								))
							}
							</div>
						</div>
					</>
				}
			/>
		</>
	);
};
