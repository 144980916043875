import React from "react";
import moment from "moment";
import 'moment/locale/es';
import { useSelector } from "react-redux";

import {
  formatNumber,
  sumTotalSalesWithoutMonthCurrent,
} from "../../utils/helpers";

export default function DashboardHistory({ type }) {
  const sale = useSelector((state) => state.sale);
  const { data: dataSale = {}, isFetching: isFetchingSaleHistory = true } = sale || {};
  const {
    last_month6 = 0,
    last_month5 = 0,
    last_month4 = 0,
    last_month3 = 0,
    last_month2 = 0,
    last_month1 = 0,
  } = dataSale || {};

  return (
    <>
      <p>Tus compras de los últimos 6 meses fueron</p>
      <div className="separador"></div>
      <div className="dashboard__history">
        <div className="dashboard__history__table">
          {isFetchingSaleHistory && (
            <div className="dashboard__stats__item">
              <div className="preloader">
                <div className="preloader__child"></div>
              </div>
            </div>
          )}
          {!isFetchingSaleHistory && (
            <table>
              <thead>
                <tr>
                  <th>{moment().subtract(6, "months").format("MMMM")}</th>
                  <th>{moment().subtract(5, "months").format("MMMM")}</th>
                  <th>{moment().subtract(4, "months").format("MMMM")}</th>
                  <th>{moment().subtract(3, "months").format("MMMM")}</th>
                  <th>{moment().subtract(2, "months").format("MMMM")}</th>
                  <th>{moment().subtract(1, "months").format("MMMM")}</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className="text-wide text-upper"
                    data-td={moment().subtract(6, "months").format("MMMM")}
                  >
                    {formatNumber(last_month6)}
                  </td>
                  <td
                    className="text-wide text-upper"
                    data-td={moment().subtract(5, "months").format("MMMM")}
                  >
                    {formatNumber(last_month5)}
                  </td>
                  <td
                    className="text-wide text-upper"
                    data-td={moment().subtract(4, "months").format("MMMM")}
                  >
                    {formatNumber(last_month4)}
                  </td>
                  <td
                    className="text-wide text-upper"
                    data-td={moment().subtract(3, "months").format("MMMM")}
                  >
                    {formatNumber(last_month3)}
                  </td>
                  <td
                    className="text-wide text-upper"
                    data-td={moment().subtract(2, "months").format("MMMM")}
                  >
                    {formatNumber(last_month2)}
                  </td>
                  <td
                    className="text-wide text-upper"
                    data-td={moment().subtract(1, "months").format("MMMM")}
                  >
                    {formatNumber(last_month1)}
                  </td>
                  <td className="text-wide text-upper" data-td={"Total"}>
                    {formatNumber(sumTotalSalesWithoutMonthCurrent(dataSale))}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};
