import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { NavLink } from "react-router-dom";
import avatarDefault from "../../assets/img/avatar-default.svg";

import { getProfile } from "../../actions/auth";

import { getUserDateType } from "../../utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCamera } from "@fortawesome/free-solid-svg-icons";
import Uploadavatar from "../../components/UploadAvatar";

import { getSegment } from "../../utils/helpers";

export default function Sidebar() {
  const dispatch = useDispatch();
  const [crop, showCrop] = useState(false);
  const [img, setImg] = useState(undefined);

  const [imgAnterior, setImgAnterior] = useState(avatarDefault);
  const [avatar, setAvatar] = useState(imgAnterior || avatarDefault);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      showCrop(true);
      e.target.value = "";
    }
  };

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  const profile = useSelector((state) => state.profile);
  const { data = {}, isFetching = true } = profile || {};
  const { firstname = "", lastname = "", data: userData = [] } = data || {};

  const dni = getUserDateType(userData, "dni");
  const avatarProfile = getUserDateType(userData, "avatar");

  useEffect(() => {
    setAvatar(avatarProfile);
  }, [avatarProfile]);

  const sale = useSelector((state) => state.sale);
  const { data: dataSale = {} } = sale || {};
  const { segment_current = "" } = dataSale || {};

  return (
    <>
      {crop && (
        <Uploadavatar
          setAvatar={setAvatar}
          img={img}
          setImg={setImg}
          imgAnterior={imgAnterior}
          setImgAnterior={setImgAnterior}
          showCrop={showCrop}
        />
      )}
      <div className="dashboard__side">
        <div className="dashboard__side__container">
          <div className="dashboard__side__card card">
            {isFetching && (
              <div className="preloader__container">
                <div className="preloader">
                  <div className="preloader__child"></div>
                </div>
              </div>
            )}
            {!isFetching && (
              <>
                <div className="card__image">
                  <img
                    src={avatar || avatarDefault}
                    alt={`${firstname} ${lastname}`}
                  />
                  <div
                    className="card__image__upload"
                    title="Cambiar imagen de perfil"
                  >
                    <FontAwesomeIcon icon={faCamera} size="lg" />
                    <input
                      type="file"
                      accept="image/jpeg"
                      onChange={(e) => {
                        onSelectFile(e);
                      }}
                    />
                  </div>
                </div>
                <div className="card__name">
                  ¡Hola!
                  <br /> {firstname} {lastname}
                </div>
                <div className="card__id">
                  {dni}
                </div>
              </>
            )}
            <div className="card__title">{getSegment(segment_current)}</div>
          </div>
          <div className="dashboard__menu">
            <input className="dashboard__menu__open" type="checkbox" />
            <div className="dashboard__menu__burger">
              Menú <FontAwesomeIcon icon={faBars} />
            </div>
            <ul className="dashboard__menu__list">
              <li className="dashboard__menu__item">
                <NavLink
                  className="dashboard__menu__link"
                  to="/mi-cuenta"
                  exact
                  activeClassName="active"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                    <g>
                      <path d="M94.677,82.929l-5.013-1.074-.018-.006L77.622,79.277A13.7,13.7,0,0,0,77.75,77.5V71.916A25.516,25.516,0,0,0,89.53,50.43V45.88h2.747a1.749,1.749,0,0,0,1.75-1.75v-6a1.749,1.749,0,0,0-1.75-1.75H91.014A26.953,26.953,0,0,0,70.25,10.473V6.5A1.749,1.749,0,0,0,68.5,4.75h-9A1.75,1.75,0,0,0,57.75,6.5v3.973A26.953,26.953,0,0,0,36.986,36.38H35.723a1.749,1.749,0,0,0-1.75,1.75v6a1.749,1.749,0,0,0,1.75,1.75H38.47v4.55A25.516,25.516,0,0,0,50.25,71.916V77.5a13.7,13.7,0,0,0,.128,1.777L38.354,81.849l-.013,0-5.016,1.074A13.816,13.816,0,0,0,22.44,96.37V121.5a1.75,1.75,0,0,0,1.75,1.75h79.62a1.75,1.75,0,0,0,1.75-1.75V96.37A13.815,13.815,0,0,0,94.677,82.929ZM87.514,36.38H79.25V26.13a1.75,1.75,0,1,0-3.5,0V36.38h-5.5V14.057A23.478,23.478,0,0,1,87.514,36.38ZM61.25,8.25h5.5V36.38h-5.5Zm-3.5,5.808V36.38h-5.5V26.13a1.75,1.75,0,1,0-3.5,0V36.38H40.486A23.476,23.476,0,0,1,57.75,14.058ZM37.473,39.88H90.527v2.5H37.473Zm4.5,10.55V45.88H86.03v4.55a22.03,22.03,0,0,1-44.06,0ZM64,75.95A25.394,25.394,0,0,0,74.25,73.8v3.7a10.25,10.25,0,0,1-20.5,0V73.8A25.394,25.394,0,0,0,64,75.95ZM25.94,96.37a10.3,10.3,0,0,1,8.117-10.019l2.913-.624V119.75H25.94ZM40.47,84.978l10.8-2.312a13.774,13.774,0,0,0,10.984,8.46V119.75H40.47ZM65.75,119.75V91.126a13.772,13.772,0,0,0,10.984-8.46l10.8,2.312V119.75Zm36.31,0H91.03V85.728l2.914.624A10.3,10.3,0,0,1,102.06,96.37Z" />
                      <path d="M97.81,102.058a1.749,1.749,0,0,0-1.75,1.75v6.255a1.75,1.75,0,0,0,3.5,0v-6.255A1.75,1.75,0,0,0,97.81,102.058Z" />
                      <path d="M55.86,98.08h-9a1.75,1.75,0,0,0,0,3.5h9a1.75,1.75,0,0,0,0-3.5Z" />
                    </g>
                  </svg>

                  <span>
                    Mi cuenta <em>Información general y Movimientos</em>
                  </span>
                </NavLink>
              </li>
              <li className="dashboard__menu__item">
                <NavLink
                  className="dashboard__menu__link"
                  to="/mi-cuenta/mis-datos"
                  activeClassName="active"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 59.69 59.69"
                  >
                    <path
                      d="M27.166,43.332A16.166,16.166,0,1,1,43.332,27.166,16.185,16.185,0,0,1,27.166,43.332Zm0-29.845A13.679,13.679,0,1,0,40.845,27.166,13.7,13.7,0,0,0,27.166,13.487Z"
                      transform="translate(16.358 16.358)"
                    />
                    <path
                      d="M20.218,27.436a1.257,1.257,0,0,1-.88-.363L14.363,22.1a1.243,1.243,0,0,1,1.758-1.758l4.034,4.034,7.829-8.948a1.244,1.244,0,0,1,1.873,1.636l-8.7,9.948a1.253,1.253,0,0,1-.9.425h-.04Z"
                      transform="translate(20.819 22.306)"
                    />
                    <path
                      d="M23.627,47.767H6.218A6.225,6.225,0,0,1,0,41.55V9.218A6.225,6.225,0,0,1,6.218,3h4.974a1.244,1.244,0,1,1,0,2.487H6.218A3.735,3.735,0,0,0,2.487,9.218V41.55A3.735,3.735,0,0,0,6.218,45.28h17.41a1.244,1.244,0,0,1,0,2.487Z"
                      transform="translate(0 4.461)"
                    />
                    <path
                      d="M23.192,17.922a1.244,1.244,0,0,1-1.244-1.244V9.218a3.735,3.735,0,0,0-3.731-3.731H13.244a1.244,1.244,0,0,1,0-2.487h4.974a6.225,6.225,0,0,1,6.218,6.218v7.461A1.244,1.244,0,0,1,23.192,17.922Z"
                      transform="translate(17.845 4.461)"
                    />
                    <path
                      d="M22.653,14.922H7.731A3.735,3.735,0,0,1,4,11.192V6.218A1.244,1.244,0,0,1,5.244,4.974H9.1a6.219,6.219,0,0,1,12.187,0H25.14a1.244,1.244,0,0,1,1.244,1.244v4.974A3.735,3.735,0,0,1,22.653,14.922ZM6.487,7.461v3.731a1.246,1.246,0,0,0,1.244,1.244H22.653A1.246,1.246,0,0,0,23.9,11.192V7.461H20.166a1.244,1.244,0,0,1-1.244-1.244,3.731,3.731,0,0,0-7.461,0,1.244,1.244,0,0,1-1.244,1.244Z"
                      transform="translate(5.948)"
                    />
                    <path
                      d="M29.114,10.487H4.244A1.244,1.244,0,1,1,4.244,8H29.114a1.244,1.244,0,1,1,0,2.487Z"
                      transform="translate(4.461 11.897)"
                    />
                    <path
                      d="M21.653,13.487H4.244a1.244,1.244,0,1,1,0-2.487h17.41a1.244,1.244,0,1,1,0,2.487Z"
                      transform="translate(4.461 16.358)"
                    />
                    <path
                      d="M16.679,16.487H4.244a1.244,1.244,0,1,1,0-2.487H16.679a1.244,1.244,0,1,1,0,2.487Z"
                      transform="translate(4.461 20.819)"
                    />
                  </svg>
                  <span>
                    Mis datos <em>Modifica tus datos.</em>
                  </span>
                </NavLink>
              </li>
              {/* <li className="dashboard__menu__item">
                <NavLink
                  className="dashboard__menu__link"
                  to="/mi-cuenta/futbol"
                  activeClassName="active"
                >
                  <svg viewBox="0 0 480 480" xmlns="http://www.w3.org/2000/svg">
                    <path d="m240 0c-132.546875 0-240 107.453125-240 240s107.453125 240 240 240 240-107.453125 240-240c-.148438-132.484375-107.515625-239.851562-240-240zm185.863281 115.121094-16.632812 70.703125-87.628907 29.207031-73.601562-58.871094v-86.746094l84-33.597656c38.03125 17.230469 70.523438 44.683594 93.863281 79.304688zm1.335938 247.640625-79.335938 12.527343-43.199219-57.066406 22.007813-88 86.65625-28.878906 49.640625 59.566406c-3.382812 36.371094-15.667969 71.351563-35.769531 101.851563zm-294.710938 12.582031-79.6875-12.582031c-20.101562-30.507813-32.382812-65.496094-35.761719-101.875l49.601563-59.566407 86.65625 28.878907 22.007813 88.046875zm-116.417969-138.328125c.425782-35.6875 9.429688-70.753906 26.25-102.230469l12.976563 55.199219zm174.175782 74.984375-21.222656-84.976562 70.976562-56.777344 70.992188 56.800781-21.238282 84.953125zm234.457031-122.046875 12.976563-55.199219c16.820312 31.476563 25.824218 66.539063 26.25 102.230469zm-114.519531-162.632813-70.183594 28.070313-70.558594-27.941406c45.671875-15.222657 95.042969-15.265625 140.742188-.128907zm-162.566406 8.679688 84.382812 33.441406v86.71875l-73.601562 58.871094-87.628907-29.207031-16.632812-70.703125c23.261719-34.496094 55.613281-61.878906 93.480469-79.121094zm-81.449219 345.070312 65.03125 10.273438 39.328125 61.601562c-40.960938-13.453124-77.1875-38.402343-104.359375-71.875zm127.296875 78.035157-47.738282-74.738281 42.273438-56.367188h104l42.878906 56.640625-41.597656 72.902344c-32.722656 8.058593-66.84375 8.605469-99.808594 1.601562zm122.039062-8.234375 34.097656-59.671875 64.261719-10.136719c-25.816406 31.792969-59.828125 55.929688-98.359375 69.808594zm0 0" />
                  </svg>
                  <span>
                    Fútbol <em>Lorem ipsum dolor sit amet consectetur.</em>
                  </span>
                </NavLink>
              </li> */}
              {/* <li className="dashboard__menu__item">
              <NavLink
                className="dashboard__menu__link"
                to="/mi-cuenta/novedades"
                activeClassName="active"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="59.69"
                  height="59.69"
                  viewBox="0 0 59.69 59.69"
                >
                  <path
                    d="M27.166,43.332A16.166,16.166,0,1,1,43.332,27.166,16.185,16.185,0,0,1,27.166,43.332Zm0-29.845A13.679,13.679,0,1,0,40.845,27.166,13.7,13.7,0,0,0,27.166,13.487Z"
                    transform="translate(16.358 16.358)"

                  />
                  <path
                    d="M20.218,27.436a1.257,1.257,0,0,1-.88-.363L14.363,22.1a1.243,1.243,0,0,1,1.758-1.758l4.034,4.034,7.829-8.948a1.244,1.244,0,0,1,1.873,1.636l-8.7,9.948a1.253,1.253,0,0,1-.9.425h-.04Z"
                    transform="translate(20.819 22.306)"

                  />
                  <path
                    d="M23.627,47.767H6.218A6.225,6.225,0,0,1,0,41.55V9.218A6.225,6.225,0,0,1,6.218,3h4.974a1.244,1.244,0,1,1,0,2.487H6.218A3.735,3.735,0,0,0,2.487,9.218V41.55A3.735,3.735,0,0,0,6.218,45.28h17.41a1.244,1.244,0,0,1,0,2.487Z"
                    transform="translate(0 4.461)"

                  />
                  <path
                    d="M23.192,17.922a1.244,1.244,0,0,1-1.244-1.244V9.218a3.735,3.735,0,0,0-3.731-3.731H13.244a1.244,1.244,0,0,1,0-2.487h4.974a6.225,6.225,0,0,1,6.218,6.218v7.461A1.244,1.244,0,0,1,23.192,17.922Z"
                    transform="translate(17.845 4.461)"

                  />
                  <path
                    d="M22.653,14.922H7.731A3.735,3.735,0,0,1,4,11.192V6.218A1.244,1.244,0,0,1,5.244,4.974H9.1a6.219,6.219,0,0,1,12.187,0H25.14a1.244,1.244,0,0,1,1.244,1.244v4.974A3.735,3.735,0,0,1,22.653,14.922ZM6.487,7.461v3.731a1.246,1.246,0,0,0,1.244,1.244H22.653A1.246,1.246,0,0,0,23.9,11.192V7.461H20.166a1.244,1.244,0,0,1-1.244-1.244,3.731,3.731,0,0,0-7.461,0,1.244,1.244,0,0,1-1.244,1.244Z"
                    transform="translate(5.948)"

                  />
                  <path
                    d="M29.114,10.487H4.244A1.244,1.244,0,1,1,4.244,8H29.114a1.244,1.244,0,1,1,0,2.487Z"
                    transform="translate(4.461 11.897)"

                  />
                  <path
                    d="M21.653,13.487H4.244a1.244,1.244,0,1,1,0-2.487h17.41a1.244,1.244,0,1,1,0,2.487Z"
                    transform="translate(4.461 16.358)"

                  />
                  <path
                    d="M16.679,16.487H4.244a1.244,1.244,0,1,1,0-2.487H16.679a1.244,1.244,0,1,1,0,2.487Z"
                    transform="translate(4.461 20.819)"

                  />
                </svg>
                <span>
                  Novedades <em>Lorem ipsum dolor sit amet consectetur.</em>
                </span>
              </NavLink>
            </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
