import React from "react";
import { Link } from "react-router-dom";

export default function RegisterSuccess() {
  return (
    <>
      <section className="registro">
        <div className="registro__bg">
          <img
            src="assets/img/registro-bg.jpg"
            alt="Registro"
          />
        </div>
        <div className="container">
          <div className="responses">
            <div className="responses__item">
              <h2 className="color--secundario text-upper text-wide">Éxito</h2>
              <p>
                Usuario registrado con éxito, se ha enviado a la casilla de
                correo registrada las instrucciones para verificar cuenta.
              </p>
              <Link to="/" className="btn btn--small btn--primario btn--borde">
                Ir al inicio
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
