import { combineReducers } from 'redux';

import faqReducer from './faqReducer';
import authReducer from './authReducer';
import topicReducer from './topicReducer';
import confirmReducer from './confirmReducer';
import registerReducer from './registerReducer';
import faqSectionReducer from './faqSectionReducer';
import newsletterReducer from './newsletterReducer';
import categoriesTopicReducer from './categoriesTopicReducer';
import rememberReducer from './rememberReducer';
import profileReducer from './profileReducer';
import saleReducer from './saleReducer';

const rootReducer = combineReducers({
  faq: faqReducer,
  auth: authReducer,
  sale: saleReducer,
  topics: topicReducer,
  profile: profileReducer,
  register: registerReducer,
  newsletter: newsletterReducer,
  faqSections: faqSectionReducer,
  accountConfirm: confirmReducer,
  rememberPassword: rememberReducer,
  categories_topics: categoriesTopicReducer,
});

export default rootReducer;
