import {
  POST_LOGIN_REQUEST,
  POST_LOGIN_SUCCESS,
  POST_LOGIN_ERROR,
  POST_REGISTER_REQUEST,
  POST_REGISTER_SUCCESS,
  POST_REGISTER_ERROR,
  POST_ACTIVE_EMAIL_REQUEST,
  POST_ACTIVE_EMAIL_SUCCESS,
  POST_ACTIVE_EMAIL_ERROR,
  POST_REMEMBER_PASSWORD_REQUEST,
  POST_REMEMBER_PASSWORD_SUCCESS,
  POST_REMEMBER_PASSWORD_ERROR,
  POST_CHANGE_PASSWORD_REQUEST,
  POST_CHANGE_PASSWORD_SUCCESS,
  POST_CHANGE_PASSWORD_ERROR,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_ERROR,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  UPDATE_AVATAR_REQUEST,
  UPDATE_AVATAR_SUCCESS,
  UPDATE_AVATAR_ERROR,
  VALIDATE_EXIST_USER_REQUEST,
  VALIDATE_EXIST_USER_SUCCESS,
  VALIDATE_EXIST_USER_ERROR,
} from '../types/auth';

import {
  Login,
  Profile,
  Register,
  ConfirmEmail,
  UpdateProfile,
  ChangePassword,
  RememberPassword,
  ValidateUserExist,
} from '../requests/auth';

const loginRequest = data => ({
  type: POST_LOGIN_REQUEST,
  payload: data,
});

const loginSuccess = data => ({
  type: POST_LOGIN_SUCCESS,
  payload: data,
});

const loginError = data => ({
  type: POST_LOGIN_ERROR,
  payload: data,
});

const login = (payload = {}) => dispatch => {
  dispatch(loginRequest(payload));
  Login(payload)
    .then(({ data = {} }) => {
      dispatch(loginSuccess(data));
    })
    .catch((error = {}) => {
      const { response = {} } = error;
      const { data = [] } = response;
      dispatch(loginError(data))
    });
};

const registerRequest = data => ({
  type: POST_REGISTER_REQUEST,
  payload: data,
});

const registerSuccess = data => ({
  type: POST_REGISTER_SUCCESS,
  payload: data,
});

const registerError = data => ({
  type: POST_REGISTER_ERROR,
  payload: data,
});

const registerUser = (payload = {}) => dispatch => {
  dispatch(registerRequest(payload));
  Register(payload)
    .then(({ data = {} }) => {
      dispatch(registerSuccess(data));
    })
    .catch((error = {}) => {
      const { response = {} } = error;
      const { data = [] } = response;
      dispatch(registerError(data))
    });
};

const confirmAccountEmailRequest = data => ({
  type: POST_ACTIVE_EMAIL_REQUEST,
  payload: data,
});

const confirmAccountEmailSuccess = data => ({
  type: POST_ACTIVE_EMAIL_SUCCESS,
  payload: data,
});

const confirmAccountEmailError = data => ({
  type: POST_ACTIVE_EMAIL_ERROR,
  payload: data,
});

const confirmAccountEmail = (payload = {}) => dispatch => {
  dispatch(confirmAccountEmailRequest(payload));
  ConfirmEmail(payload)
    .then(({ data = {} }) => {
      dispatch(confirmAccountEmailSuccess(data));
    })
    .catch((error = {}) => {
      const { response = {} } = error;
      const { data = [] } = response;
      dispatch(confirmAccountEmailError(data))
    });
};

const rememberPasswordRequest = data => ({
  type: POST_REMEMBER_PASSWORD_REQUEST,
  payload: data,
});

const rememberPasswordSuccess = data => ({
  type: POST_REMEMBER_PASSWORD_SUCCESS,
  payload: data,
});

const rememberPasswordError = data => ({
  type: POST_REMEMBER_PASSWORD_ERROR,
  payload: data,
});

const rememberPassword = (payload = {}) => dispatch => {
  dispatch(rememberPasswordRequest(payload));
  RememberPassword(payload)
    .then(({ data = {} }) => {
      dispatch(rememberPasswordSuccess(data));
    })
    .catch((error = {}) => {
      const { response = {} } = error;
      const { data = [] } = response;
      dispatch(rememberPasswordError(data))
    });
};

const changePasswordRequest = data => ({
  type: POST_CHANGE_PASSWORD_REQUEST,
  payload: data,
});

const changePasswordSuccess = data => ({
  type: POST_CHANGE_PASSWORD_SUCCESS,
  payload: data,
});

const changePasswordError = data => ({
  type: POST_CHANGE_PASSWORD_ERROR,
  payload: data,
});

const changePassword = (payload = {}) => dispatch => {
  dispatch(changePasswordRequest(payload));
  ChangePassword(payload)
    .then(({ data = {} }) => {
      dispatch(changePasswordSuccess(data));
    })
    .catch((error = {}) => {
      const { response = {} } = error;
      const { data = [] } = response;
      dispatch(changePasswordError(data))
    });
};

const getProfileRequest = data => ({
  type: GET_PROFILE_REQUEST,
  payload: data,
});

const getProfileSuccess = data => ({
  type: GET_PROFILE_SUCCESS,
  payload: data,
});

const getProfileError = data => ({
  type: GET_PROFILE_ERROR,
  payload: data,
});

const getProfile = (payload = {}) => dispatch => {
  dispatch(getProfileRequest(payload));
  Profile(payload)
    .then(({ data = {} }) => {
      dispatch(getProfileSuccess(data));
    })
    .catch((error = {}) => {
      const { response = {} } = error;
      const { data = [] } = response;
      dispatch(getProfileError(data))
    });
};


const updateProfileRequest = data => ({
  type: UPDATE_PROFILE_REQUEST,
  payload: data,
});

const updateProfileSuccess = data => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: data,
});

const updateProfileError = data => ({
  type: UPDATE_PROFILE_ERROR,
  payload: data,
});

const updateProfile = (payload = {}) => dispatch => {
  dispatch(updateProfileRequest(payload));
  UpdateProfile(payload)
    .then(({ data = {} }) => {
      dispatch(updateProfileSuccess(data));
    })
    .catch((error = {}) => {
      const { response = {} } = error;
      const { data = [] } = response;
      dispatch(updateProfileError(data))
    });
};

const updateAvatarRequest = data => ({
  type: UPDATE_AVATAR_REQUEST,
  payload: data,
});

const updateAvatarSuccess = data => ({
  type: UPDATE_AVATAR_SUCCESS,
  payload: data,
});

const updateAvatarError = data => ({
  type: UPDATE_AVATAR_ERROR,
  payload: data,
});

const updateAvatar = (payload = {}) => dispatch => {
  dispatch(updateAvatarRequest(payload));
  UpdateProfile(payload)
    .then(({ data = {} }) => {
      dispatch(updateAvatarSuccess(data));
    })
    .catch((error = {}) => {
      const { response = {} } = error;
      const { data = [] } = response;
      dispatch(updateAvatarError(data))
    });
};

const validateUserExistRequest = data => ({
  type: VALIDATE_EXIST_USER_REQUEST,
  payload: data,
});

const validateUserExistSuccess = data => ({
  type: VALIDATE_EXIST_USER_SUCCESS,
  payload: data,
});

const validateUserExistError = data => ({
  type: VALIDATE_EXIST_USER_ERROR,
  payload: data,
});

const validateUserExist = (payload = {}) => dispatch => {
  dispatch(validateUserExistRequest(payload));
  ValidateUserExist(payload)
    .then(({ data = {} }) => {
      dispatch(validateUserExistSuccess(data));
    })
    .catch((error = {}) => {
      const { response = {} } = error;
      const { data = [] } = response;
      dispatch(validateUserExistError(data))
    });
};

export {
  login,
  getProfile,
  registerUser,
  updateAvatar,
  updateProfile,
  changePassword,
  rememberPassword,
  validateUserExist,
  confirmAccountEmail,
};
