import React from "react";
import Category from "../../components/Category";
import AllCategories from "../../assets/json/categorias.json";

const Categories = (props) => {
  return (
    <section className="categorias">
      <div className="categorias__bg">
        <img
          src="assets/img/categorias-bg.jpg"
          alt="Categorías"
        />
      </div>
      <div className="container">
        <div className="categorias__info">
          <h2 className="color--blanco section__title h1 text-upper text-wide">
            Categorías
          </h2>
          <p className="color--blanco section__subtitle">
            Si eres un socio categoría Plata u Oro, tendrás tus descuentos activos en las 7 categorías de materiales, accesorios y herramientas de construcción, para tener siempre el mejor precio del mercado. “Los mejores precios, para expertos de verdad”
          </p>
        </div>
        <div className="row">
          {
          AllCategories
            .filter(category => category.isActive)
            .map(({ name, slug, desc, dscto }, key) => (
            <div className="col-xs-12 col-md-6 col-lg-4 col-xl-3" key={key}>
              <Category name={name} slug={slug} dscto={dscto} desc={desc} />
            </div>
          ))
          }
        </div>
      </div>
    </section>
  );
};

export default Categories;
