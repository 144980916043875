import {
  CREATE_NEWSLETTER_REQUEST,
  CREATE_NEWSLETTER_SUCCESS,
  CREATE_NEWSLETTER_ERROR,
} from '../types/newsletter';

const initialState = {
  err: {},
  isFetching: false,
  data: false,
};

const newsletterReducer = (state = initialState, action = {}) => {
  const { type = '', payload = {} } = action;
  switch (type) {
    case CREATE_NEWSLETTER_REQUEST:
      return {
        ...state,
        isFetching: true,
        err: null,
      };
    case CREATE_NEWSLETTER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: true,
        err: null,
      };
    case CREATE_NEWSLETTER_ERROR:
      const { errors = '' } = payload;
      return {
        ...state,
        isFetching: false,
        err: errors,
      };
    default: {
      return state;
    }
  }
};

export default newsletterReducer;
