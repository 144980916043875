import { useState, useEffect } from "react";
import moment from "moment";

export const CodeResponse = (data = "") => {
  const response = [];
  response["USER_NOT_VERIFY"] = "Usuario no verificado";
  response["CREDENTIALS_INVALID"] = "Los datos son incorrectos";
  response["FIELDS_REQUIRED"] = "Los campos son requeridos";
  response["USER_REGISTERED"] = "Usuario ya se encuentra registrado";
  response["CODE_INVALID"] = "El código es inválido";
  response["USER_NOT_FOUND"] = "Usuario no encontrado";
  return (
    response[data] ||
    "Ha ocurrido un problema, porfavor notifique al administrador"
  );
};

export const isValidCellPhone = (value = "") => {
  const re = /^(\+?56)?(0?9)[0-9]\d{7}$/gi;
  return re.test(String(value).toLowerCase());
};

export const isValidPhone = (value = "") => {
  const re = /^(\+?56)?(0?[29])[0-9]\d{7}$/gi;
  return re.test(String(value).toLowerCase());
};

export const isValidEmail = (value = "") => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
};

export const reverseDate = (value = "") => {
  const [day, month, year] = value.split("/") || [];
  return `${year}-${month}-${day}`;
};

export const isValidDate = (value = "") => {
  const [day, month, year] = value.split("/") || [];
  return moment(`${year}-${month}-${day}`).isValid();
};

export const formatDate = (value = "", layout = "YYYY-MM-DD") => {
  return moment(value).format(layout);
};

export const getUserDateType = (userData = [], find = "") => {
  if (!Array.isArray(userData) || !userData.length) {
    return "";
  }
  const { data = "" } = userData.find(({ type = "" }) => type === find) || {};
  return data;
};

export const formatNumber = (value = "", signo = true) => {
  const formatter = new Intl.NumberFormat("es-ES");
  return `${signo ? "$" : ""}${formatter.format(value)}`;
};

export const getSegment = (value = "") => {
  const response = [];
  response["I"] = "Experto Bronce";
  response["R"] = "Experto Plata";
  response["P"] = "Experto Oro";
  return response[value] || value;
};

export const sumTotalSales = (obj = {}) => {
  const {
    month_current = 0,
    last_month6 = 0,
    last_month5 = 0,
    last_month4 = 0,
    last_month3 = 0,
    last_month2 = 0,
  } = obj || {};

  return (
    month_current +
    last_month6 +
    last_month5 +
    last_month4 +
    last_month3 +
    last_month2
  );
};

export const sumTotalSalesWithoutMonthCurrent = (obj = {}) => {
  const {
    last_month6 = 0,
    last_month5 = 0,
    last_month4 = 0,
    last_month3 = 0,
    last_month2 = 0,
    last_month1 = 0,
  } = obj || {};

  return (
    last_month6 +
    last_month5 +
    last_month4 +
    last_month3 +
    last_month2 +
    last_month1
  );
};

export const useScroll = () => {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [bodyOffset, setBodyOffset] = useState(
    document.body.getBoundingClientRect()
  );
  const [scrollY, setScrollY] = useState(bodyOffset.top);
  const [scrollX, setScrollX] = useState(bodyOffset.left);
  const [scrollDirection, setScrollDirection] = useState();

  const listener = (e) => {
    setBodyOffset(document.body.getBoundingClientRect());
    setScrollY(-bodyOffset.top);
    setScrollX(bodyOffset.left);
    setScrollDirection(lastScrollTop > -bodyOffset.top ? "down" : "up");
    setLastScrollTop(-bodyOffset.top);
  };

  useEffect(() => {
    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  });

  return {
    scrollY,
    scrollX,
    scrollDirection,
  };
};
