import React from "react";
import Breadcrumbs from "../Breadcrumbs";

const Banner = ({ title, bg, breadcrumbs }) => (
  <div className="banner">
    <div className="banner__bg">
      <img src={bg} alt={title} />
    </div>
    <div className="banner__container container">
      <h1 className="banner__title color--blanco text-upper text-wide">
        {title}
      </h1>
      {breadcrumbs && <Breadcrumbs crumbs={[...breadcrumbs]} />}
    </div>
  </div>
);

export default Banner;
