import React from "react";
import Page from "../../components/Page";
import Seo from "../SEO";

const GuiaExperto = () => {
  const title = "Guía Experto";
  const url = "/guia-experto";
  return (
    <>
      <Seo
        title={title}
        description="La Guía de productos y precios para todos tus proyectos."
        url={url}
      />
      <Page
        title={title}
        banner
        breadcrumbs={[
          {
            type: "link",
            path: "/",
            label: "Inicio",
          },
          {
            type: "separator",
          },
          {
            type: "current",
            label: title,
          },
        ]}
        content={
          <>
            <div className="guia-experto">
              <div className="container">
                <picture>
                  <source srcSet="/assets/img/banner-guia-experto-new.jpg" />
                  <img
                    src="/assets/img/banner-guia-experto-new.jpg"
                    alt="Guía Experto"
                    style={{
                      width: "100%",
                    }}
                  />
                </picture>
                <div className="separador--big"></div>
                <div className="row justify-center-xs">
                  {/* <div className="col-xs-12 col-md-4 col-lg-3">
                    <img
                      src="/assets/img/guia-logo.png"
                      alt={title}
                      style={{
                        maxWidth: 300,
                        width: "100%",
                      }}
                    />
                  </div> */}
                  <div className="col-xs-12 col-md-8 col-xl-6">
                    <h3>¿Qué es la Guía del Experto?</h3>
                    <p>
                      Queremos que la nueva Guía del Experto sea el mejor aliado
                      para todos tus proyectos. Con ella podrás cotizar,
                      comparar y elegir los mejores materiales, herramientas y
                      accesorios, para llevar a cabo todos tus proyectos de
                      construcción y remodelación.
                    </p>
                    <p>Consigue la tuya en tiendas Easy.</p>
                    {/* <div className="separador--big"></div> */}
                  </div>
                </div>
                <div className="separador--big"></div>
                <div className="beneficios__calugas">
                  <div className="row">
                    <div className="col-xs-12">
                      <h3>¿Dónde la encuentro?</h3>
                      <p>Encuéntrala en todas nuestras tiendas</p>
                      <div className="separador"></div>
                    </div>
                    <div className="col-xs-12 col-md-4">
                      <div className="beneficios__calugas__item bg--secundario">
                        <h3 className="beneficios__calugas__title text-upper text-wide color--blanco h4">
                          Precio Normal <br /> $2.990
                        </h3>
                      </div>
                    </div>
                    {/* <div className="col-xs-12 col-md-3">
                      <div className="beneficios__calugas__item bg--terciario">
                        <h3 className="beneficios__calugas__title text-upper text-wide color--blanco h4">
                          Precio Tarjeta Cencosud <br /> $2.490
                        </h3>
                      </div>
                    </div> */}

                    <div className="col-xs-12 col-md-4">
                      <div className="beneficios__calugas__item bg--experto">
                        <h3 className="beneficios__calugas__title text-upper text-wide color--blanco h4">
                          Precio Socio Mundo Experto <br /> $1.990
                        </h3>
                      </div>
                    </div>
                    <div className="col-xs-12 col-md-3">
                      <div className="beneficios__calugas__item bg--preferente">
                        <h3 className="beneficios__calugas__title text-upper text-wide color--blanco h4">
                          Gratis
                        </h3>
                        <p className="beneficios__calugas__description color--blanco">
                          Para los primeros 1000 socios preferentes
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="separador--big"></div>
                <p class="text-align-center-xs">
                  <small>
                    *Stock limitado de 1000 guías gratis para socios
                    preferentes. Solo una guía gratis por socio preferente.
                    <br />
                    Accede a la guía gratis por la compra de cualquier producto
                    en Easy - sin un mínimo de compra
                  </small>
                </p>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default GuiaExperto;
