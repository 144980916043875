import { doAuthentication } from '../../utils/api-client';

export const uploadImage = (payload = {}) => {
  const { file } = payload;
  const formData = new FormData();
  formData.append('file', file, "avatar.jpg");
  const config = {
    'Content-Type': 'multipart/form-data',
  };

  return doAuthentication({
    method: 'POST',
    url: `/upload/image`,
    data: formData,
    config,
  });
};
