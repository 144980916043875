import {
  GET_FAQ_SECTION_REQUEST,
  GET_FAQ_SECTION_SUCCESS,
  GET_FAQ_SECTION_ERROR,
} from '../types/faq';

const initialState = {
  err: {},
  isFetching: false,
  data: [],
};

const faqSectionReducer = (state = initialState, action = {}) => {
  const { type = '', payload = {} } = action;
  switch (type) {
    case GET_FAQ_SECTION_REQUEST:
      return {
        ...state,
        isFetching: true,
        err: null,
      };
    case GET_FAQ_SECTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: payload,
        err: null,
      };
    case GET_FAQ_SECTION_ERROR:
      const { errors = '' } = payload;
      return {
        ...state,
        isFetching: false,
        err: errors,
      };
    default: {
      return state;
    }
  }
};

export default faqSectionReducer;
