import React from "react";
import Page from "../../components/Page";
import Seo from "../SEO";
import tiposClientes from "../../assets/json/tipos-clientes.json";
import { ClientItem } from "../../components/ClientItem";

const Clients = () => {
  const title = "Tipo de Socios";
  const url = "/tipo-socios";
  return (
    <>
      <Seo
        title={title}
        description="Conoce los diferentes tipos de socios que tenemos pensado para los profesionales de la construcción"
        url={url}
      />
      <Page
        title={title}
        banner
        breadcrumbs={[
          {
            type: "link",
            path: "/",
            label: "Inicio",
          },
          {
            type: "separator",
          },
          {
            type: "current",
            label: title,
          },
        ]}
        content={
          <>
            <div className="clientes">
              <div className="separador"></div>
              <div className="separador"></div>
              {tiposClientes.map((x, key) => (
                <ClientItem client={x} key={key} boxSide desc={false} />
              ))}
            </div>
          </>
        }
      />
    </>
  );
};

export default Clients;
