import React from "react";
import Page from "../../components/Page";
import Seo from "../SEO";
import Category from "../../components/Category";
import { ClientItem } from "../../components/ClientItem";
import tiposClientes from "../../assets/json/tipos-clientes.json";
import Categories from "../../assets/json/categorias.json";

const Expert = () => {
  const title = "Experto";
  const slug = "experto";
  return (
    <>
      <Seo
        title={title}
        description="Categoría Experto: Súmate a este beneficio y obtén descuentos en todas tus compras"
        url={`/${slug}`}
      />
      <Page
        title={title}
        banner
        breadcrumbs={[
          {
            type: "link",
            path: "/",
            label: "Inicio",
          },
          {
            type: "separator",
          },
          {
            type: "current",
            label: title,
          },
        ]}
        content={
          <>
            {tiposClientes
              .filter((x) => x.type === slug)
              .map((x, key) => (
                <ClientItem
                  client={x}
                  key={key}
                  btn={false}
                  desc={false}
                  boxSide
                />
              ))}
            <div className="separador"></div>
            <div className="expertos__info">
              <h2 className="h3 text-wide text-upper">Condiciones</h2>
            </div>
            <div className="beneficios__calugas">
              <div className="row">
                <div className="col-xs-12 col-md-4">
                  <div className="beneficios__calugas__item bg--primario">
                    <h3 className="beneficios__calugas__title text-upper text-wide color--blanco h4">
                      Inscríbete en el Club y muestra tu RUT en caja en cada
                      compra que realices
                    </h3>
                    <p className="beneficios__calugas__desc color--blanco">
                      También podrás obtener los descuentos a través de Easy.cl
                    </p>
                  </div>
                </div>
                <div className="col-xs-12 col-md-4">
                  <div className="beneficios__calugas__item bg--secundario">
                    <h3 className="beneficios__calugas__title text-upper text-wide color--blanco h4">
                      El total de tus compras acumuladas en los últimos 6 meses
                      debe superar los $300.000
                    </h3>
                  </div>
                </div>
                <div className="col-xs-12 col-md-4">
                  <div className="beneficios__calugas__item bg--terciario">
                    <h3 className="beneficios__calugas__title text-upper text-wide color--blanco h4">
                      Si lo prefieres puedes aumentar tus montos de compra para
                      llegar a categoría PREFERENTE
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="separador"></div>
            <div className="expertos__info">
              <h2 className="h3 text-wide text-upper">
                Descuentos{" "}
                <span className="color--primario">Categoria Experto</span>
              </h2>
            </div>
            <div className="row">
              {Categories.map((x, key) => (
                <div className="col-xs-12 col-md-6 col-lg-4 col-xl-3" key={key}>
                  <Category
                    name={x.name}
                    slug={x.slug}
                    desc={x.desc}
                    dscto={x.dscto}
                    type={slug}
                  />
                </div>
              ))}
            </div>
            <div className="separador"></div>
            <div className="expertos__info">
              <h2 className="h3 text-wide text-upper">Más Beneficios</h2>
            </div>
            <div className="beneficios__calugas">
              <div className="row">
                {/* <div className="col-xs-12 col-md-4 col-lg-3">
                  <div className="beneficios__calugas__item bg--secundario">
                    <p className="beneficios__calugas__desc color--blanco">
                      Facilidades de Financiación a través de Tarjeta Cencosud
                    </p>
                  </div>
                </div> */}
                <div className="col-xs-12 col-md-4 col-lg-4">
                  <div className="beneficios__calugas__item bg--primario">
                    <p className="beneficios__calugas__desc color--blanco">
                      Acumulación de Puntos Cencosud
                    </p>
                  </div>
                </div>
                <div className="col-xs-12 col-md-4 col-lg-4">
                  <div className="beneficios__calugas__item bg--secundario">
                    <p className="beneficios__calugas__desc color--blanco">
                      Guía del Experto a precio preferencial
                    </p>
                  </div>
                </div>
                <div className="col-xs-12 col-md-4 col-lg-4">
                  <div className="beneficios__calugas__item bg--primario">
                    <p className="beneficios__calugas__desc color--blanco">
                      Atención especializada a través de nuestros Embajadores
                      Expertos en tienda
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default Expert;
