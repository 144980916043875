import React from "react";
import Page from "../../components/Page";
import Seo from "../SEO";

const Privacy = () => {
  const title = "Políticas de Privacidad";
  const url = "/politicas-privacidad";
  return (
    <>
      <Seo
        title={title}
        description="Nuestra Política de privacidad y Protección de datos en el Club Mundo Experto"
        url={url}
      />
      <Page
        title={title}
        banner
        breadcrumbs={[
          {
            type: "link",
            path: "/",
            label: "Inicio",
          },
          {
            type: "separator",
          },
          {
            type: "current",
            label: title,
          },
        ]}
        content={
          <>
            <p>
              <strong>
                POLÍTICAS DE PRIVACIDAD Y PROTECCIÓN DE DATOS CLUB MUNDO EXPERTO
                EASY
              </strong>
            </p>
            <p>
              <strong>DECLARACIÓN ESPECIAL.</strong>
            </p>
            <p>
              Easy Retail S.A. RUT 76.568.660-1 (En adelante “Club Mundo Experto
              Easy” o “Mundo Experto” o “Easy”), domiciliada para estos efectos
              en Av. Kennedy 9001, 4° piso, Comuna de Las Condes, Región
              Metropolitana. Administradora del Programa Club Mundo Experto
              Easy, en adelante el “Programa”, ha elaborado esta Política de
              Privacidad y Uso de Información Personal con el propósito que los
              Socios de Mundo Experto, en adelante el “Socio”, tengan
              conocimiento sobre la información personal que Club Mundo Experto
              Easy reúne respecto de los mismos, así como la manera que se
              utiliza y el propósito de su tratamiento. Por lo que el socio
              acepta expresamente la presente política.
            </p>
            <p>
              Se deja constancia que Club Mundo Experto Easy se reserva el
              derecho de modificar esta política en cualquier momento, lo que se
              obliga a informar a los Socios, con a lo menos 10 días de
              anticipación a la fecha considerada, para el inicio de la vigencia
              de la modificación, por aquellos canales que garanticen la debida
              y oportuna información. Un texto actualizado de la presente
              política se mantendrá publicada en el sitio web de Club Mundo
              Experto Easy (www.mundoexperto.cl).
            </p>
            <p>
              La presente Política se entiende formar parte integrante de los
              Términos y Condiciones de Mundo Experto para todos los efectos a
              que haya lugar. Los términos que no se encuentren expresamente
              definidos en el presente documento, tendrán el significado que a
              ellos se asignan en los Términos y Condiciones de Club Mundo
              Experto Easy.
            </p>
            <p>
              <strong>I.- INTRODUCCIÓN</strong>
            </p>
            <p>
              Cuando ingrese, sin estar registrado a la página web
              www.mundoexperto.cl, queremos que tenga una experiencia única que
              cumpla con la calidad y satisfacción que está buscando. Cada vez
              que nos visite, tendremos la oportunidad de conocer sus
              necesidades y preferencias de navegación. Esa información
              estadística es de gran relevancia para nosotros ya que nos ayudará
              a mejorar constantemente nuestro servicio para entregarle una
              grata experiencia de navegación.
            </p>
            <p>
              Esta información no nos permite identificar a un cliente
              determinado, sólo tiene relación con datos sobre un determinado
              producto, grupo, página o categoría de productos y/o servicios.
            </p>
            <p>
              <strong>
                II.- DE LA INFORMACIÓN DE LOS SOCIOS DEL PROGRAMA Y SU USO O
                DISPOSICIÓN.
              </strong>
            </p>
            <p>
              Club Mundo Experto Easy declara al Socio, que el Programa otorga a
              los Socios diversos beneficios, sobre la base de la información
              que estos últimos proporcionan al Programa. Por lo tanto, es en
              base al conocimiento de la información personal de los Socios, que
              Mundo Experto puede, entre otros beneficios, enviar detalles de
              ofertas, promociones y productos a los Socios, permitiendo que se
              puedan mejorar sus servicios respecto de los Socios o procurar
              responder mejor las necesidades de estos.
            </p>
            <p>
              Por ello es importante que el Socio tenga presente que cuando
              acepta su incorporación a Club Mundo Experto Easy, declara que los
              antecedentes o datos de carácter personal que ha proporcionado a
              Easy en virtud del Programa, en adelante la “Información”, son
              sustancialmente correctos y corresponden a la realidad, debiendo
              comunicar cualquier modificación de los mismos tan pronto como
              éstas se produjeren. Para Cencosud, la anterior declaración es un
              elemento determinante para permitir la incorporación del Socio al
              Programa, siendo el Socio personalmente responsable de las
              consecuencias que pudiere ocasionar su incumplimiento.
            </p>
            <p>
              En consideración a lo anterior, el Socio expresamente consiente
              que, como efecto de su incorporación al Programa, la Información
              que se acumule, recolecte, reciba o recoja de los Socios ,podrá
              ser:
            </p>
            <p>
              1.- La Información podrá ser objeto de: (i) Almacenamiento,
              entendiendo por tal el archivo de la información en lugares
              especialmente destinados al efecto; (ii) Procesamiento,
              entendiendo por tal los mecanismos y procesos que permitan brindar
              al Socio los servicios ofrecidos; (iii) Tratamiento, entendiendo
              por tal cualquier operación o complejo de operaciones o
              procedimientos técnicos, de carácter automatizado o no, que
              permitan recolectar, almacenar, grabar, organizar, elaborar,
              agrupar, seleccionar, extraer, confrontar, interconectar, disociar
              o cancelar la información; y/o (iv) Disposición, entendiendo por
              tal comunicar, ceder, transferir, transmitir o cancelar datos de
              carácter personal o utilizarlos en cualquier forma. Lo anterior
              salvo que el Socio, respecto del tratamiento y disposición,
              expresamente instruya lo contrario mediante comunicación escrita,
              despachada mediante carta certificada al domicilio de Club Mundo
              Experto Easy.
            </p>
            <p>
              2.- Respetando las disposiciones legales que regulen la materia,
              la Información podrá almacenarse, procesarse y tratarse en
              cualquier país del mundo. Sin perjuicio de lo anterior, Easy será
              siempre responsable del debido cuidado de la Información debiendo
              tomar medidas de seguridad razonables para resguardar la
              Información contra su manipulación, pérdida, destrucción,
              divulgación y acceso no autorizado.
            </p>
            <p>
              3.- La Información almacenada podrá ser usada para los fines que a
              continuación se expresan: (i) La mantención de las relaciones
              contractuales y/o comerciales derivadas del Programa entre Easy y
              el Socio; (ii) La confección y/o mejora de los productos y/o
              servicios que Easy pudiera prestar a los Socios, así como el
              diseño de nuevos productos y/o servicios para estos; (iii) El
              despacho por cualquier vía de todo tipo de información,
              incluyendo, pero no limitada a, antecedentes técnicos, publicidad
              y promoción de productos y/o servicios, Club Mundo Experto Easy,
              Tiendas Easy o de terceros, formularios y encuestas; y por último
              (iv) La realización de estudios individuales y/o colectivos de
              cualquier tipo como, por ejemplo: estudios en materia demográfica,
              histórica, comportamiento de los Socios e investigaciones de
              mercado; cuidando siempre de la reserva y confidencialidad de los
              datos personales de la manera exigida por la ley.
            </p>
            <p>
              4.- El Socio consiente expresamente que, en el desarrollo del
              Programa, Cencosud podrá tener acceso o solicitar al Socio o a
              terceros, información que pudiere ser más específica respecto del
              Socio, en adelante la “Información Adicional”, que revele
              preferencias, gustos, hábitos, comportamientos, bienes,
              obligaciones y en general toda aquella que permita un mayor
              conocimiento del Socio, la cual podrá almacenarse, procesarse,
              tratarse y disponerse en los mismos términos indicados en los
              números precedentes.
            </p>
            <p>
              5.- Easy podrá disponer y comunicar al público todo o parte de la
              Información o de la Información Adicional, caso en el cual sus
              destinatarios deberán ser personas relacionadas a la propiedad o
              gestión de Easy Retail S.A., ser una filial o matriz, o ser una
              persona que esté bajo el control común de Cencosud S.A. o Easy
              Retail S.A. , Club Mundo Experto Easy procurará que el
              destinatario de la referida información garantice que ésta será
              tratada bajo adecuados parámetros de reserva y que, en el evento
              que el Socio se oponga al uso de la misma, será prontamente
              eliminada de los registros respectivos. Por último, tanto Easy
              como los citados destinatarios sólo podrán usar la Información o
              la Información Adicional con los propósitos indicados en el número
              3 precedente, caso este último en que las condiciones de esta
              política aplicables al Programa deberán ser cumplidas por dichos
              destinatarios.
            </p>
            <p>
              6.- Las restricciones contempladas en los numerales anteriores no
              serán aplicables si Easy, en conformidad con la legislación
              vigente, comparte información para investigar, prevenir o tomar
              acciones sobre actividades ilegales, delitos, sospechas de fraude,
              lavado de activos, terrorismo y situaciones que involucran riesgos
              potenciales a la seguridad de cualquier persona, o si es requerida
              por autoridades jurisdiccionales o administrativas en el ejercicio
              de las atribuciones que les son propias.
            </p>
            <p>
              7.- Tampoco serán aplicables las restricciones anteriores cuando
              se trate de “información estadística”, entendiendo por ésta
              aquella información de la cual se han eliminado aquellos campos o
              señas que permiten identificar a un Socio determinado y, por
              tanto, sólo dice relación con datos sobre un determinado producto,
              grupo o categoría de productos y/o servicios, o sobre un grupo de
              Socios no identificados o identificables.
            </p>
            <p>
              <strong>III.- GOOGLE ANALYTICS EN MUNDOEXPERTO.CL </strong>
            </p>
            <p>
              Google Analytics es una herramienta para el análisis web que
              mediante la utilización de cookies permite entregar información
              estadística, anónima, asociada a los visitantes de{" "}
              <a href="http://www.mundoexperto.cl/">www.mundoexperto.cl</a>.
              Adicionalmente www.mundoexperto.cl utiliza esta herramienta para
              fines publicitarios, mostrando anuncios en los sitios web
              asociados a la red de contenido de Google basándose en las visitas
              previas a nuestro sitio, lo que se conoce como Remarketing.
            </p>
            <p>
              Para evitar la recopilación de datos desde Google Analytics
              existen las siguientes alternativas:
            </p>
            <p>
              Deshabilitar el almacenamiento de cookies en el explorador de
              internet correspondiente.
            </p>
            <p>
              Descargar un complemento al explorador de internet en el siguiente
              link: https://tools.google.com/dlpage/gaoptout/.
            </p>
            <p>
              <strong>IV.- DERECHO DEL SOCIO</strong>
            </p>
            <p>
              Sin perjuicio de los beneficios que ofrece el Programa al Socio,
              Easy Retail S.A. reconoce y acepta que los Socios tienen el
              legítimo derecho a solicitar la exclusión de la Información y de
              la Información Adicional. En el marco antes indicado, Easy ofrece
              al Socio las siguientes alternativas de exclusión: (i) No recibir
              a través del Programa información sobre ofertas y promociones de
              Mundo Experto; (ii) No ser contactado con fines de estudios de
              mercado; (iii) No ser incluido como destinatario en el envío de
              ofertas especiales, publicidad y otra información y comunicaciones
              por correo electrónico y/o teléfono móvil y/o; (iv) Restringir la
              disposición de su información personal a Easy.
            </p>
            <p>
              El Socio dispondrá siempre de los derechos de información,
              rectificación y cancelación de los datos personales conforme a la
              Ley Nº 19.268 sobre protección de datos de carácter personal. El
              Socio podrá hacer efectivo el derecho antes referido enviando un
              correo a datospersonales@cencosud.cl
            </p>
            <p>
              Las anteriores opciones sólo serán aplicables para aquella
              información o datos que el Socio haya facilitado a Mundo Experto.
              Mundo Experto no puede garantizar la privacidad de la información
              personal de los Socios del Programa si estos suministran o
              difunden información en reportajes de prensa, publicaciones, zonas
              de chateo, boletines u otros similares. Si el Socio revela dicha
              información será́ bajo su exclusiva responsabilidad.
            </p>
            <p>
              <strong>Felipe Longo</strong>
            </p>
            <p>
              <strong>Gerente Easy Chile</strong>
            </p>
            <p>
              <strong>EASY RETAIL S.A.</strong>
            </p>
          </>
        }
      />
    </>
  );
};

export default Privacy;
