export const POST_LOGIN_REQUEST = 'POST_LOGIN_REQUEST';
export const POST_LOGIN_SUCCESS = 'POST_LOGIN_SUCCESS';
export const POST_LOGIN_ERROR = 'POST_LOGIN_ERROR';
export const POST_LOGIN_LOGOUT = 'POST_LOGIN_LOGOUT';

export const POST_REGISTER_REQUEST = 'POST_REGISTER_REQUEST';
export const POST_REGISTER_SUCCESS = 'POST_REGISTER_SUCCESS';
export const POST_REGISTER_ERROR = 'POST_REGISTER_ERROR';

export const POST_ACTIVE_EMAIL_REQUEST = 'POST_ACTIVE_EMAIL_REQUEST';
export const POST_ACTIVE_EMAIL_SUCCESS = 'POST_ACTIVE_EMAIL_SUCCESS';
export const POST_ACTIVE_EMAIL_ERROR = 'POST_ACTIVE_EMAIL_ERROR';

export const POST_REMEMBER_PASSWORD_REQUEST = 'POST_REMEMBER_PASSWORD_REQUEST';
export const POST_REMEMBER_PASSWORD_SUCCESS = 'POST_REMEMBER_PASSWORD_SUCCESS';
export const POST_REMEMBER_PASSWORD_ERROR = 'POST_REMEMBER_PASSWORD_ERROR';

export const POST_CHANGE_PASSWORD_REQUEST = 'POST_CHANGE_PASSWORD_REQUEST';
export const POST_CHANGE_PASSWORD_SUCCESS = 'POST_CHANGE_PASSWORD_SUCCESS';
export const POST_CHANGE_PASSWORD_ERROR = 'POST_CHANGE_PASSWORD_ERROR';

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';

export const UPDATE_AVATAR_REQUEST = 'UPDATE_AVATAR_REQUEST';
export const UPDATE_AVATAR_SUCCESS = 'UPDATE_AVATAR_SUCCESS';
export const UPDATE_AVATAR_ERROR = 'UPDATE_AVATAR_ERROR';

export const VALIDATE_EXIST_USER_REQUEST = 'VALIDATE_EXIST_USER_REQUEST';
export const VALIDATE_EXIST_USER_SUCCESS = 'VALIDATE_EXIST_USER_SUCCESS';
export const VALIDATE_EXIST_USER_ERROR = 'VALIDATE_EXIST_USER_ERROR';
