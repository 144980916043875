import axios from 'axios';
import Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';

const {
  REACT_APP_API_BASE_URL = '',
  REACT_APP_X_API_KEY_VALUE = '',
} = process.env;

export const client = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
  timeout: 0,
  withCredentials: false,
  headers: {
    'X-API-KEY' : REACT_APP_X_API_KEY_VALUE, 
  },
});

export const doAuthentication = fn => {

  const token = Cookies.get('token');
  if (!isEmpty(token)) {
    fn.headers = {
      ...client.headers,
      Authorization: `Bearer ${token}`,
    };
  }
  return client(fn);
};
